import * as dayjs from "dayjs";
import * as ObjectSupport from "dayjs/plugin/objectSupport";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
dayjs.extend(ObjectSupport);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
import { CLASS_NAMES } from "./classNames";
import {
    resetErrorMessages,
    ErrorType,
    markInvalidFields,
    validateRequiredFields,
    validateEmailFields,
    resetFieldError,
} from "./errorHandler";
import DomCrawlingHelper from "./DomCrawlingHelper";
import { getCoords } from "./common";
import { DNI_LENGTH_DICTIONARY, FULL_EMAIL_REGEX } from "./commonConstants";

const hiddenClassNames: string[] = ["hide", CLASS_NAMES.Hidden];
export const attributeNames = {
    required: "data-required",
    slimSelectRequired: "data-ss-required",
    max: "max",
};

export const CHILD_MIN_AGE = 2;
export const CHILD_MAX_AGE = 14;
export const INFANT_MAX_AGE = 2;
export const ADULT_MIN_AGE = 14;

export type FormElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
export enum PaxType {
    Adult,
    Child,
    Infant,
}
export interface BirthGroup {
    div: HTMLDivElement;
    date: dayjs.Dayjs;
    paxType: PaxType;
    startDate: dayjs.Dayjs;
    returnDate: dayjs.Dayjs;
}

export function validate(form: HTMLElement, captcha?: [{ id: number; error: HTMLDivElement }]): boolean {
    resetErrorMessages(form);
    return isFormValid(form, captcha);
}

export function revalidateRequiredFields(form: HTMLElement) {
    const fields = getAllFormFields(form);
    const hasInvalidField = fields.find((field) => !isRequiredFieldValid(field));

    if (!hasInvalidField) {
        const parentElem = DomCrawlingHelper.findParentByClass(fields[0], CLASS_NAMES.errorParent);
        if (parentElem) {
            const messages = Array.from(
                parentElem.querySelectorAll("." + CLASS_NAMES.requiredMessage),
            ) as HTMLDivElement[];
            messages.forEach((m) => m.classList.add(CLASS_NAMES.Hidden));
        }
    }
}

function isFormValid(form: HTMLElement, captcha?: [{ id: number; error: HTMLDivElement }]): boolean {
    let isValid = true;
    const fields = getAllFormFields(form);
    const errors: string[] = [];

    for (const field of fields) {
        const requiredFieldValidationResult = isRequiredFieldValid(field);
        if (!requiredFieldValidationResult) {
            const parentElem = DomCrawlingHelper.findParentByClass(field, CLASS_NAMES.errorParent);
            if (parentElem) {
                markInvalidFields(parentElem, ErrorType.Required);
            }
            isValid = false;
            errors.push("[missing required field]");
        }
        const emailFieldValidationResult = isEmailFieldValid(field);
        if (!emailFieldValidationResult) {
            const parentElem = DomCrawlingHelper.findParentByClass(field, CLASS_NAMES.errorParent);
            if (parentElem) {
                markInvalidFields(parentElem, ErrorType.EmailFormat);
            }
            isValid = false;
            errors.push("[invalid email]");
        }
        const fieldLengthValidationResult = isFieldLengthValid(field);
        if (!fieldLengthValidationResult) {
            const parentElem = DomCrawlingHelper.findParentByClass(field, CLASS_NAMES.errorParent);
            if (parentElem) {
                markInvalidFields(parentElem, ErrorType.InvalidLength);
            }
            isValid = false;
            errors.push("[invalid field length]");
        }
    }

    const birthGroups = getBirthGroups(form);
    for (const birthGroup of birthGroups) {
        const fieldValidationResult = isBirthGroupValid(birthGroup);
        if (!fieldValidationResult) {
            markInvalidFields(birthGroup.div, ErrorType.InvalidAge);
            isValid = false;
            errors.push("[invalid date]");
        }
    }

    if (captcha) {
        captcha.forEach((c) => {
            if (window.grecaptcha.getResponse(c.id).length === 0) {
                c.error.classList.remove(CLASS_NAMES.Hidden);
                isValid = false;
                errors.push("[captcha not validated]");
            } else {
                c.error.classList.add(CLASS_NAMES.Hidden);
            }
        });
    }

    if (!isValid) {
        scrollToFirstError(form);
    }

    return isValid;
}

export function scrollToFirstError(form: HTMLElement): void {
    const firstError = form.querySelector(
        "." + CLASS_NAMES.undismissed + ", ." + CLASS_NAMES.error + ":not(.hidden)",
    ) as HTMLElement;
    if (firstError) {
        const parent = DomCrawlingHelper.findParentByClass(firstError, CLASS_NAMES.errorParent);
        const topOfElement = getCoords(parent).top - 200;
        window.scroll({ top: topOfElement, behavior: "smooth" });
    }
}

export function handleFieldBlur(input: HTMLInputElement, container: HTMLElement): void {
    const requiredResult = validateRequiredFields(container);
    if (requiredResult) {
        const messages = Array.from(container.querySelectorAll("." + CLASS_NAMES.requiredMessage)) as HTMLDivElement[];
        messages.forEach((m) => m.classList.add(CLASS_NAMES.Hidden));
    }
    const emailResult = validateEmailFields(container);
    if (emailResult) {
        const message = container.querySelector("." + CLASS_NAMES.invalidEmailFormat);
        if (message) {
            message.classList.add(CLASS_NAMES.Hidden);
        }
    }
    if (isRequiredFieldValid(input) && isEmailFieldValid(input)) {
        resetFieldError(input);
    }
}

export function isBirthGroupValid(birthGroup: BirthGroup): boolean {
    updateBirthGroup(birthGroup);
    if (isAdultAgeValid(birthGroup) || isChildAgeValid(birthGroup) || isInfantAgeValid(birthGroup)) {
        return true;
    }
    return false;
}

function isInfantAgeValid(birthGroup: BirthGroup): boolean {
    return (
        birthGroup.paxType === PaxType.Infant &&
        birthGroup.date.isAfter(dayjs(birthGroup.returnDate).subtract(INFANT_MAX_AGE, "years")) &&
        birthGroup.date.isSameOrBefore(dayjs(birthGroup.startDate).subtract(1, "days"))
    );
}

function isChildAgeValid(birthGroup: BirthGroup): boolean {
    return (
        birthGroup.paxType === PaxType.Child &&
        birthGroup.date.isAfter(dayjs(birthGroup.returnDate).subtract(CHILD_MAX_AGE, "years")) &&
        birthGroup.date.isSameOrBefore(dayjs(birthGroup.startDate).subtract(CHILD_MIN_AGE, "years"))
    );
}

function isAdultAgeValid(birthGroup: BirthGroup) {
    return (
        birthGroup.paxType === PaxType.Adult &&
        birthGroup.date.isSameOrBefore(dayjs(birthGroup.startDate).subtract(ADULT_MIN_AGE, "years"))
    );
}

function isRequiredFieldValid(field: FormElement): boolean {
    if (
        field.attributes.getNamedItem(attributeNames.required) &&
        !field.value &&
        !field.classList.contains("disabled") &&
        !DomCrawlingHelper.findParentByClass(field, "disabled")
    ) {
        return false;
    }

    if (
        field.attributes.getNamedItem(attributeNames.slimSelectRequired) &&
        field.parentElement.dataset.slimselectvalue
    ) {
        return false;
    }

    return true;
}

function isFieldLengthValid(field: FormElement): boolean {
    if (
        field.attributes.getNamedItem(attributeNames.max) &&
        field.value.length > Number(field.attributes.getNamedItem(attributeNames.max).value) &&
        !field.classList.contains("disabled") &&
        !DomCrawlingHelper.findParentByClass(field, "disabled")
    ) {
        return false;
    }

    return true;
}

function isEmailFieldValid(field: FormElement): boolean {
    if (field.dataset.email && field.value) {
        return FULL_EMAIL_REGEX.test(field.value);
    }

    return true;
}

function getAllFormFields(form: HTMLElement): FormElement[] {
    return (Array.from(form.querySelectorAll("input, textarea, select")) as FormElement[]).filter((field) =>
        isElementVisible(field),
    );
}

export function isElementVisible(element: Element): boolean {
    let isVisible = true;

    while (element) {
        const isVisibleInDom = isElementVisibleInDom(element);
        isVisible = isVisible && isVisibleInDom;
        element = isVisible ? element.parentElement : undefined;
    }

    return isVisible;
}

function isElementVisibleInDom(element: Element) {
    return hiddenClassNames.reduce(
        (aggr, hiddenClassName) =>
            aggr && !element.classList.contains(hiddenClassName) && isElementVisibleInCss(element),
        true,
    );
}

function isElementVisibleInCss(element: Element): boolean {
    return (
        (element as HTMLElement).style.visibility !== CLASS_NAMES.Hidden &&
        (element as HTMLElement).style.display !== "none"
    );
}

export function getBirthGroups(form: HTMLElement, withValues = true): BirthGroup[] {
    const allBirthGroups = Array.from(form.querySelectorAll("." + CLASS_NAMES.birthGroup)) as HTMLDivElement[];
    const birthGroups = allBirthGroups.reduce((aggr, birthGroup) => {
        const year = birthGroup.querySelector("." + CLASS_NAMES.birthDateYear) as HTMLInputElement;
        const month = birthGroup.querySelector("." + CLASS_NAMES.birthDateMonth) as HTMLInputElement;
        const day = birthGroup.querySelector("." + CLASS_NAMES.birthDateDay) as HTMLInputElement;
        const type = birthGroup.dataset.paxType;
        const departure = birthGroup.dataset.departure;
        const arrival = birthGroup.dataset.arrival;

        if (
            year &&
            (year.value || !withValues) &&
            month &&
            (month.value || !withValues) &&
            day &&
            (day.value || !withValues) &&
            type &&
            arrival
        ) {
            return aggr.concat([
                {
                    div: birthGroup,
                    date: dayjs({ year: Number(year.value), month: Number(month.value) - 1, day: Number(day.value) }),
                    paxType: type === "adult" ? PaxType.Adult : type === "child" ? PaxType.Child : PaxType.Infant,
                    returnDate: dayjs(arrival, "YYYY-MM-DD"),
                    startDate: dayjs(departure, "YYYY-MM-DD"),
                },
            ]);
        } else {
            return aggr;
        }
    }, []);

    return birthGroups;
}

function updateBirthGroup(birthGroup: BirthGroup): void {
    const year = birthGroup.div.querySelector("." + CLASS_NAMES.birthDateYear) as HTMLInputElement;
    const month = birthGroup.div.querySelector("." + CLASS_NAMES.birthDateMonth) as HTMLInputElement;
    const day = birthGroup.div.querySelector("." + CLASS_NAMES.birthDateDay) as HTMLInputElement;
    birthGroup.date = dayjs({ year: Number(year.value), month: Number(month.value) - 1, day: Number(day.value) });
}

export function validateRut(rut: string): boolean {
    return !rut || (validateRutFormally(rut) && validateRutMathematically(rut));
}

// RUT check as provided by JetSmart

function validateRutFormally(value: string): boolean {
    return /^[0-9]{3,10}-[0-9kK]{1}$/.test(value);
}

function validateRutMathematically(value: string): boolean {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(value)) {
        return false;
    }

    const tmp = value.split("-");
    let digv = tmp[1];

    if (digv === "K") {
        digv = "k";
    }

    return dv(tmp[0] as any).toString() === digv.toString();
}

export const validateDni = (dni: string, culture: string): boolean => {
    const { min, max } = DNI_LENGTH_DICTIONARY.get(culture);
    const regEx = new RegExp(`^\\d{${min},${max}}$`);

    return regEx.test(dni);
};

export function validateEcuadorianRuc(ruc: string): boolean {
    return /^\d{10,13}$/.test(ruc);
}

function dv(t: number): string | number {
    let M = 0;
    let S = 1;
    for (; t; t = Math.floor(t / 10)) {
        S = (S + (t % 10) * (9 - (M++ % 6))) % 11;
    }
    return S ? S - 1 : "k";
}
