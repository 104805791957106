import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { showLoader, toBoolean } from "../../shared/common";
import classNames from "classnames";
import i18next from "i18next";
import { useBancoEstadoBar } from "./useBancoEstadoBar";
import { useBancoEstadoRibbon } from "./useBancoEstadoRibbon";
import {
    ACTIONS_WITHOUT_BREADCRUMBS,
    ACTION_NAMES,
    CONTROLLERS_WITHOUT_BREADCRUMBS,
    COOKIE_NAMES,
    REAL_CHECKIN_ACTIONS,
} from "../../shared/commonConstants";
import { setSessionCookie } from "../../shared/cookieHandling";
import { useMainMenu } from "./useMainMenu";
import { useFlowContext } from "../../managers/useFlowContext";
import { useAppContext } from "../../managers/useAppContext";
import { useBreadcrumbs } from "./useBreadcrumbs";

export const name = "ac-app-header";

export const observedAttributes: (keyof Attributes)[] = [
    "api-free-seats-in-booking",
    "dc-terms-url",
    "has-group-membership-on-booking",
    "has-standard-membership-on-booking",
    "membership-upgrade-price",
    "name",
    "number-of-pax",
    "profile-url",
    "should-reload-on-login",
    "show-banco-estado-priority-boarding-tick",
    "show-sidebar",
];

export interface Attributes {
    "api-free-seats-in-booking": string;
    "dc-terms-url": string;
    "has-group-membership-on-booking": string;
    "has-standard-membership-on-booking": string;
    "membership-upgrade-price": string;
    "name": string;
    "number-of-pax": string;
    "profile-url": string;
    "should-reload-on-login": string;
    "show-banco-estado-priority-boarding-tick": string;
    "show-sidebar": string;
}

export interface Props {
    apiFreeSeatsInBooking: number;
    dcTermsUrl: string;
    hasGroupMembershipOnBooking: boolean;
    hasStandardMembershipOnBooking: boolean;
    membershipUpgradePrice: string;
    name: string;
    numberOfPax: number;
    profileUrl: string;
    shouldReloadOnLogin: boolean;
    showBancoEstadoPriorityBoardingTick: boolean;
    showSidebar: boolean;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        apiFreeSeatsInBooking: Number(host.apiFreeSeatsInBooking),
        dcTermsUrl: host.dcTermsUrl,
        hasGroupMembershipOnBooking: toBoolean(host.hasGroupMembershipOnBooking),
        hasStandardMembershipOnBooking: toBoolean(host.hasStandardMembershipOnBooking),
        membershipUpgradePrice: host.membershipUpgradePrice,
        name: host.name,
        numberOfPax: Number(host.numberOfPax),
        profileUrl: host.profileUrl,
        shouldReloadOnLogin: toBoolean(host.shouldReloadOnLogin),
        showBancoEstadoPriorityBoardingTick: toBoolean(host.showBancoEstadoPriorityBoardingTick),
        showSidebar: toBoolean(host.showSidebar),
    };

    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const bancoEstadoBar = useBancoEstadoBar(props);
    const bancoEstadoRibbon = useBancoEstadoRibbon({ showSidebar: props.showSidebar });
    const menu = useMainMenu(props);

    const breadcrumbs = useBreadcrumbs();

    const handleLogoClick = () => {
        setSessionCookie(COOKIE_NAMES.DiscountedSeatsUsedInBooking, undefined);
        showLoader({});
        const url = window.location.href.includes(":")
            ? "/"
            : `${appContext.CorporateBaseUrl.toLowerCase()}/${appContext.Country.toLowerCase()}/${appContext.Language.toLowerCase()}/`;
        window.location.href = url;
    };

    const showAnyBreadcrumbs = () =>
        (flowContext.isCheckinFlow && !REAL_CHECKIN_ACTIONS.includes(flowContext.action)) ||
        (!flowContext.isCheckinFlow &&
            !CONTROLLERS_WITHOUT_BREADCRUMBS.includes(flowContext.controller) &&
            !ACTIONS_WITHOUT_BREADCRUMBS.includes(flowContext.action));

    const showCheckinRibbon = () => flowContext.isCheckinFlow && flowContext.action !== ACTION_NAMES.PROFILE;

    const checkinRibbonTemplate = () =>
        showCheckinRibbon()
            ? html`
                  <div class="checkin-ribbon" data-test-id="checkin-ribbon">${i18next.t("V2-CheckinRibbonText")}</div>
              `
            : "";

    return html`
        <header class=${classNames("main-header", { condensed: !showAnyBreadcrumbs() })}>
            <div class="nav-bg">
                <section class=${classNames("nav-container", { "full-width": !props.showSidebar })}>
                    <a class="jetsmart-logo" @click=${handleLogoClick}>
                        <img src="/Images/Header/jetsmart-logo-colored.svg" />
                    </a>

                    ${menu.htmlTemplate()}
                </section>
            </div>
            ${bancoEstadoRibbon.htmlTemplate()} ${bancoEstadoBar.htmlTemplate()} ${checkinRibbonTemplate()}
            ${breadcrumbs.htmlTemplate()}
        </header>
    `;
};
