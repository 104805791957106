import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { ApiCheckinPassengerViewModel } from "../../../component-models/checkin/ApiCheckinPassengerViewModel";
import { ApiCheckingInJourney } from "../../../component-models/checkin/ApiCheckingInJourney";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { CheckinPaxCheckboxChangeEvent } from "../../../component-models/checkin/CheckinEvents";
import { getAntiforgerySegment, getParsedProperty, showLoader, toBoolean } from "../../../shared/common";
import { ref } from "../../../directives/ref";
import i18next from "i18next";
import { useRef } from "haunted";
import { ItineraryJourney } from "../../../component-models/itinerary/ApiItineraryViewModelV2";
import { ApiBoardingPassesViewModel } from "../../../component-models/boarding-pass/ApiBoardingPassesViewModel";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../../shared/apiRoutes";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import { useCheckinTealiumManager } from "../../../managers/Tealium/useCheckinTealiumManager";
import { useBookingDataManager } from "../../../managers/useBookingDataManager";
import { useFlightItineraryInfo } from "../../shared/flight-itinerary-info/useFlightItineraryInfo";
import { useBasicCheckbox } from "../../ui/basic-checkbox/useBasicCheckbox";
import { useBoardingPassesDownloadLink } from "./useBoardingPassesDownloadLink";
import { useAppContext } from "../../../managers/useAppContext";
import { INVALID_PASSENGER_CONTACT_PHONE_NUMBERS } from "../../../shared/commonConstants";
dayjs.extend(CustomParseFormat);

export const name = "ac-checkin-passenger-container";

export const observedAttributes: (keyof Attributes)[] = [
    "arrival-date",
    "anti-forgery-token",
    "arrival-station-name",
    "arrival-time",
    "boarding-passes-dto",
    "can-continue",
    "departure-date",
    "departure-station-name",
    "departure-time",
    "estimated-arrival-time",
    "estimated-departure-time",
    "flight-number",
    "hours",
    "is-departed",
    "journey",
    "minutes",
    "passenger-journeys",
    "passengers",
    "invalid-passenger-numbers",
    "show-boarding-pass-modal",
    "travel-date",
];

export interface Attributes {
    "arrival-date": string;
    "anti-forgery-token": string;
    "arrival-station-name": string;
    "arrival-time": string;
    "boarding-passes-dto": string;
    "can-continue": string;
    "departure-date": string;
    "departure-station-name": string;
    "departure-time": string;
    "estimated-arrival-time": string;
    "estimated-departure-time": string;
    "flight-number": string;
    "hours": string;
    "is-departed": string;
    "journey": string;
    "minutes": string;
    "passenger-journeys": string;
    "passengers": string;
    "invalid-passenger-numbers": string;
    "show-boarding-pass-modal": string;
    "travel-date": string;
}

export interface Props {
    arrivalDate: dayjs.Dayjs;
    antiForgeryToken: string;
    arrivalStationName: string;
    arrivalTime: string;
    boardingPassesDto: ApiBoardingPassesViewModel;
    canContinue: boolean;
    departureDate: dayjs.Dayjs;
    departureStationName: string;
    departureTime: string;
    estimatedArrivalTime: string;
    estimatedDepartureTime: string;
    flightNumber: string;
    hours: number;
    isDeparted: boolean;
    journey: ItineraryJourney;
    minutes: number;
    passengerJourneys: ApiCheckingInJourney[];
    passengers: ApiCheckinPassengerViewModel[];
    invalidPassengerNumbers: number[];
    showBoardingPassModal: boolean;
    travelDate: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        arrivalDate: dayjs(host.arrivalDate, "YYYY-MM-DD"),
        antiForgeryToken: host.antiForgeryToken,
        arrivalStationName: host.arrivalStationName,
        arrivalTime: host.arrivalTime,
        boardingPassesDto:
            typeof host.boardingPassesDto === "string"
                ? getParsedProperty<ApiBoardingPassesViewModel>(host.boardingPassesDto)
                : undefined,
        canContinue: toBoolean(host.canContinue),
        departureDate: dayjs(host.departureDate, "YYYY-MM-DD"),
        departureStationName: host.departureStationName,
        departureTime: host.departureTime,
        estimatedArrivalTime: typeof host.estimatedArrivalTime === "string" ? host.estimatedArrivalTime : undefined,
        estimatedDepartureTime:
            typeof host.estimatedDepartureTime === "string" ? host.estimatedDepartureTime : undefined,
        flightNumber: host.flightNumber,
        hours: Number(host.hours),
        isDeparted: toBoolean(host.isDeparted),
        journey: typeof host.journey === "string" ? getParsedProperty<ItineraryJourney>(host.journey) : undefined,
        minutes: Number(host.minutes),
        passengerJourneys:
            typeof host.passengerJourneys === "string"
                ? getParsedProperty<ApiCheckingInJourney[]>(host.passengerJourneys)
                : undefined,
        passengers:
            typeof host.passengers === "string"
                ? getParsedProperty<ApiCheckinPassengerViewModel[]>(host.passengers)
                : [],
        invalidPassengerNumbers:
            typeof host.invalidPassengerNumbers === "string"
                ? getParsedProperty<number[]>(host.invalidPassengerNumbers)
                : [],
        showBoardingPassModal: toBoolean(host.showBoardingPassModal),
        travelDate: host.travelDate,
    };

    const appContext = useAppContext();

    const anyRestrictedSsrs = () => props.passengerJourneys.some((pj) => pj.HasRetrictedSSRs);

    const showBoardingPasses = () => !anyRestrictedSsrs() && props.boardingPassesDto?.BoardingPasses?.length > 0;

    const getPostBody = () =>
        props.passengers.reduce((aggr: any, curr) => {
            const paxNumberInputFieldName = `PassengerJourneyBags[${curr.PassengerNumber.toString()}].PassengerNumber`;
            const sellKeyInputFieldName = `PassengerJourneyBags[${curr.PassengerNumber.toString()}].JourneyBags[0].SellKey`;

            aggr[paxNumberInputFieldName] = curr.PassengerNumber;

            if (selectedPassengers.includes(curr.PassengerNumber)) {
                aggr[sellKeyInputFieldName] = props.passengerJourneys[curr.PassengerNumber].Journey.SellKey;
            }

            return aggr;
        }, {});

    const showErrorMessage = () => isValidated && selectedPassengers.length === 0;

    const handleCheckboxChange = (e: CheckinPaxCheckboxChangeEvent) => {
        setIsSelectAllPassengersChecked(false);
        const paxNumber = e.detail.paxNumber;
        setSelectedPassengers((prevSelectedPassengers) =>
            prevSelectedPassengers.includes(paxNumber)
                ? prevSelectedPassengers.filter((p) => p !== paxNumber)
                : [...prevSelectedPassengers, paxNumber],
        );
    };

    const handleSelectAll = () => {
        if (isSelectAllPassengersChecked) {
            setIsSelectAllPassengersChecked(false);
            setSelectedPassengers([]);
        } else {
            setIsSelectAllPassengersChecked(true);
            const selectedPassengers = props.passengers
                .filter((p) => !props.invalidPassengerNumbers.includes(p.PassengerNumber))
                .map((p) => p.PassengerNumber);
            setSelectedPassengers(selectedPassengers);
        }
    };

    const submitPassengers = async () => {
        setIsValidated(true);

        if (selectedPassengers.length === 0) {
            return;
        }

        showLoader({});

        tealiumManager.logCheckinPassengerSelectContinue(
            Array.from(selectedPassengers),
            props.passengers.reduce(
                (aggr, curr) =>
                    selectedPassengers.includes(curr.PassengerNumber) ? aggr.concat([curr.TypeInfo.PaxType]) : aggr,
                [],
            ),
        );

        await ajaxRequest({
            body: getPostBody(),
            form: form.current,
            method: "POST",
            url: ROUTES.PassengersCheckin,
        });

        window.location.href = ROUTES.CheckinAdditionalInfo;
    };

    const tealiumManager = useCheckinTealiumManager();
    const bookingDataManager = useBookingDataManager();

    const { ajaxRequest, ajaxJsonRequest } = useAjax();

    const form = useRef<HTMLFormElement>(undefined);

    const [isSelectAllPassengersChecked, setIsSelectAllPassengersChecked] = useState<boolean>(false);
    const [selectedPassengers, setSelectedPassengers] = useState<number[]>([]);
    const [isValidated, setIsValidated] = useState<boolean>(false);

    const selectAllLabelTemplate = (id: string) => html`
        <label for=${id} class="select-all-passengers-label">
            <span class="select-all-pax-text">${i18next.t("V2-Checkin-SelectAll")}</span>
        </label>
    `;

    const selectAllCheckbox = useBasicCheckbox({
        isChecked: isSelectAllPassengersChecked,
        customWrapperClass: "select-all-pax-checkbox-wrapper",
        labelTemplate: selectAllLabelTemplate,
        onClick: handleSelectAll,
    });

    const flightItineraryInfo = useFlightItineraryInfo({
        itineraryData: {
            ArrivalDate: props.arrivalDate,
            ArrivalTime: props.arrivalTime,
            DepartureDate: props.departureDate,
            DepartureTime: props.departureTime,
            DestinationAirport: props.arrivalStationName,
            DestinationCity: props.arrivalStationName,
            DestinationIata: props.arrivalStationName,
            EstimatedArrivalTime: props.estimatedArrivalTime,
            EstimatedDepartureTime: props.estimatedDepartureTime,
            FlightIndex: 0,
            FlightNumber: props.flightNumber,
            Hours: props.hours,
            JourneyIndex: props.journey.JourneyIndex,
            Minutes: props.minutes,
            OriginAirport: props.departureStationName,
            OriginCity: props.departureStationName,
            OriginIata: props.departureStationName,
            Segments: props.journey.Segments,
            ShowANACTooltip: false,
        },
        isBundleSelected: false,
        isForCheckin: true,
        isInFlightMove: false,
    });

    const downloadLink = useBoardingPassesDownloadLink({
        model: props.boardingPassesDto,
        pushDown: props.journey.Segments[0].Legs.length > 1,
        showModal: props.showBoardingPassModal,
    });

    useEffect(() => bookingDataManager.fetchAndUpdate(ajaxJsonRequest), []);

    const headerTemplate = () => html`
        <header>
            <span class="js-circle-user js-icon title-icon"></span>
            <div class="title">
                <h2 class="main-title" data-test-id="checkin-passengers-title">
                    ${i18next.t("V2-SelectPassengersTitle")}
                </h2>
                <div class="subtitle" data-test-id="checkin-passengers-subtitle">
                    ${i18next.t("V2-SelectPassengersInfo")}:
                </div>
            </div>
        </header>
    `;

    const timeChangeBannerTemplate = () =>
        props.estimatedArrivalTime || props.estimatedDepartureTime
            ? html`
                  <div class="etd-banner">
                      <i class="js-icon-covid js-cv-timer"></i>
                      <div>
                          ${unsafeHTML(
                              i18next.t(
                                  "Etd-Banner {{-span1Start}}{{-span1End}}{{-depStation}}{{-arrStation}}{{-span2Start}}{{-span2End}}",
                                  {
                                      span1Start: "<span>",
                                      span1End: "</span>",
                                      span2Start: "<span>",
                                      span2End: "</span>",
                                      depStation: props.departureStationName,
                                      arrStation: props.arrivalStationName,
                                  },
                              ),
                          )}
                      </div>
                  </div>
              `
            : "";

    const ssrWarningTemplate = () =>
        anyRestrictedSsrs()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="full-width-information">
                              <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                              <span> ${i18next.t("WheelchairLabel2")} </span>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const boardingPassesTemplate = () => (showBoardingPasses() ? downloadLink.htmlTemplate() : "");

    const flightItineraryTemplate = () => html`
        <div class="mb-6 mt-10 flex sm:mt-4">${flightItineraryInfo.htmlTemplate()}</div>
    `;

    const selectAllTemplate = () =>
        props.canContinue
            ? html`
                  <div class="row">
                      <div class="col-xs-1">${selectAllCheckbox.htmlTemplate()}</div>
                  </div>
              `
            : "";

    const passengerTemplate = (passenger: ApiCheckinPassengerViewModel, index: number) => html`
        <div class="col-xs-1 col-sm-1-2 col-md-1-3">
            <ac-checkin-passenger
                .isDeparted=${props.isDeparted}
                .isSelected=${selectedPassengers.includes(passenger.PassengerNumber)}
                .hasInvalidDocument=${props.invalidPassengerNumbers.includes(passenger.PassengerNumber)}
                .passenger=${passenger}
                .passengerJourney=${props.passengerJourneys[index]}
                @paxCheckboxChange=${handleCheckboxChange}
            ></ac-checkin-passenger>
        </div>
    `;

    const passengersTemplate = () => html`<div class="row">${props.passengers.map(passengerTemplate)}</div>`;

    const selectionErrorTemplate = () =>
        showErrorMessage()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("Passengers-Validation")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const continueButtonTemplate = () =>
        props.canContinue
            ? html`
                  <button
                      class="rounded-primary-btn booking"
                      data-test-id="checkin-passengers-submit-button"
                      @click=${submitPassengers}
                  >
                      ${i18next.t("V2-Continue")}
                  </button>
              `
            : "";

    const returnButtonTemplate = () =>
        !props.canContinue
            ? html`<a href=${ROUTES.PageItinerary} class="rounded-secondary-btn booking single-btn">
                  ${i18next.t("V2-ReturnLabel")}
              </a>`
            : "";

    const restrictedDocumentTemplate = () =>
        props.invalidPassengerNumbers.length > 0
            ? html`
                  <div class="error-wrapper condensed">
                      <h4 class="alert-heading">${i18next.t("ErrorOccured")}</h4>
                      ${i18next.t("V2-RestrictedPassengers", {
                          phoneNumber: INVALID_PASSENGER_CONTACT_PHONE_NUMBERS.get(appContext.Culture),
                      })}
                  </div>
              `
            : "";

    return html`
        <div id="mainContentWrapper" class="checkin-passengers" data-test-id="checkin-passengers">
            <section class="booking-wrapper push-down ts-error-container">
                ${headerTemplate()} ${restrictedDocumentTemplate()} ${timeChangeBannerTemplate()}
                <div class="inner-box ts-passenger-details" data-test-id="checkin-flow-passenger-container">
                    <div class="inner-deep-box padded checkin-pax-select ts-error-parent">
                        <div class="travel-time" data-test-id="checkin-flow-passenger-travel-date">
                            ${props.travelDate}
                        </div>
                        ${flightItineraryTemplate()} ${ssrWarningTemplate()} ${boardingPassesTemplate()}
                        <form ref=${ref(form)}>
                            ${getAntiforgerySegment(props.antiForgeryToken)} ${selectAllTemplate()}
                            ${passengersTemplate()}
                        </form>
                        ${selectionErrorTemplate()}
                    </div>
                </div>
                <div class="checkin-btn-container">${continueButtonTemplate()} ${returnButtonTemplate()}</div>
            </section>
        </div>
    `;
};
