import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { ACTION_NAMES } from "../../shared/commonConstants";
import i18next from "i18next";
import classNames from "classnames";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-breadcrumbs";

export const Component: HauntedFunc<{}> = () => {
    const flowContext = useFlowContext();

    const [isBancoEstadoBarOpened] = useReduxState("isBancoEstadoBarOpened");

    // Helpers

    const isGiftcardSelectActive = () => flowContext.action === ACTION_NAMES.GIFTCARD_SELECT;

    const isGiftcardPaymentActive = () => flowContext.action === ACTION_NAMES.GIFTCARD_PAYMENT;

    const isGiftcardItineraryActive = () =>
        flowContext.action === ACTION_NAMES.GIFTCARD_ITINERARY ||
        flowContext.action === ACTION_NAMES.GIFTCARD_POSTCOMMIT;

    const isPaymentActive = () => flowContext.action === ACTION_NAMES.PAYMENT;



    // Templates

    const giftcardPurchaseTemplate = () => {
        const selectClassMap = classMap({
            "booking-step": true,
            "step-one": true,
            "active": isGiftcardSelectActive(),
        });

        const paymentClassMap = classMap({
            "booking-step": true,
            "step-two": true,
            "active": isGiftcardPaymentActive(),
        });

        const postcommitClassMap = classMap({
            "booking-step": true,
            "step-three": true,
            "active": isGiftcardItineraryActive(),
        });

        return flowContext.isGiftcardPurchaseFlow
            ? html`
                  <div class="row">
                      <div class="col-xs-1-3">
                          <div class="breadcrumb-section">
                              <div class=${selectClassMap}>1</div>
                          </div>
                      </div>
                      <div class="col-xs-1-3 text-center">
                          <div class="breadcrumb-section text-center">
                              <div class=${paymentClassMap}>2</div>
                          </div>
                      </div>
                      <div class="col-xs-1-3 text-right">
                          <div class="breadcrumb-section text-right">
                              <div class=${postcommitClassMap}>3</div>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const standAloneDcTemplate = () => {
        const dataClassMap = classMap({
            "booking-step": true,
            "step-one": true,
            "active": !isPaymentActive(),
        });

        const paymentClassMap = classMap({
            "booking-step": true,
            "step-two": true,
            "active": isPaymentActive(),
        });

        return flowContext.isDcStandaloneFlow
            ? html`
                  <div class="row">
                      <div class="col-xs-1-2">
                          <div class="breadcrumb-section">
                              <div class=${dataClassMap}>1</div>
                              <span class="visible-md-up step-one">${i18next.t("V2-EnterDataForDc")}</span>
                          </div>
                      </div>
                      <div class="col-xs-1-2 text-right">
                          <div class="breadcrumb-section text-right">
                              <div class=${paymentClassMap}>2</div>
                              <span class="visible-md-up">${i18next.t("V2-Payment")}</span>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const wrapperClassMap = classNames(
        "relative z-0 mx-auto h-[60px] max-w-[calc(100%-130px)] bg-[#f7f7f7] common-transition",
        "md:mx-0 md:h-[80px] md:max-w-full",
        "sm:z-40",
        {
            "top-[75px]": isBancoEstadoBarOpened,
            "hidden-md-up": flowContext.isDcStandaloneFlow || flowContext.isGiftcardPurchaseFlow,
        },
    );

    return flowContext.isDcStandaloneFlow || flowContext.isGiftcardPurchaseFlow
        ? html`
              <div class=${wrapperClassMap}>
                  <div class="breadcrumbs-bg">
                      <div class="breadcrumbs"></div>
                      ${standAloneDcTemplate()} ${giftcardPurchaseTemplate()}
                  </div>
              </div>
          `
        : html``;
};
