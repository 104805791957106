const UNUSED_CHARS = ["I", "O", "Q", "Z"];
const ONLY_FIRST_POSITION_CHARS = ["G", "S"];
const EBCDIC_TABLE = new Map<string, number>([
    ["0", 240],
    ["1", 241],
    ["2", 242],
    ["3", 243],
    ["4", 244],
    ["5", 245],
    ["6", 246],
    ["7", 247],
    ["8", 248],
    ["9", 249],
    ["A", 193],
    ["B", 194],
    ["C", 195],
    ["D", 196],
    ["E", 197],
    ["F", 198],
    ["G", 199],
    ["H", 200],
    ["I", 201],
    ["J", 209],
    ["K", 210],
    ["L", 211],
    ["M", 212],
    ["N", 213],
    ["O", 214],
    ["P", 215],
    ["Q", 216],
    ["R", 217],
    ["S", 226],
    ["T", 227],
    ["U", 228],
    ["V", 229],
    ["W", 230],
    ["X", 231],
    ["Y", 232],
    ["Z", 233],
]);

export const frequentFlyerNumberValidator = () => {
    // HELPERS

    const getCharactersToCheck = (frequentFlyerNumber: string) => {
        const chars = frequentFlyerNumber.split("");
        return [chars[0], chars[2], chars[2], chars[4], chars[3], chars[5]];
    };

    const getEbcdicValue = (frequentFlyerNumber: string) => {
        const charsToCheck = getCharactersToCheck(frequentFlyerNumber);
        return (charsToCheck.reduce((aggr, char) => aggr + EBCDIC_TABLE.get(char), 0) / 5).toString();
    };

    const getFirstCharacterOfRemainder = (ebcdicValue: string) =>
        ebcdicValue.includes(".") ? ebcdicValue.split(".")[1].substring(0, 1) : "0";

    // RULES

    const validateCharsAndLength = (frequentFlyerNumber: string) => /^[A-Z0-9]{7}$/.test(frequentFlyerNumber);

    const validateEbcdic = (frequentFlyerNumber: string) => {
        const ebcdicValue = getEbcdicValue(frequentFlyerNumber);
        const validatorChar = getFirstCharacterOfRemainder(ebcdicValue);
        const lastChar = frequentFlyerNumber.substring(6, 7);

        return lastChar === validatorChar;
    };

    const validateOnlyFirstPositionChars = (frequentFlyerNumber: string) => {
        for (const char of frequentFlyerNumber.substring(1)) {
            if (ONLY_FIRST_POSITION_CHARS.includes(char)) return false;
        }

        return true;
    };

    const validateLastPositionChar = (frequentFlyerNumber: string) => {
        const lastChar = frequentFlyerNumber.substring(6, 7);

        if (!lastChar) return false;

        return !isNaN(Number(lastChar)) && Number(lastChar) % 2 === 0;
    };

    const validateUnusedChars = (frequentFlyerNumber: string) => {
        for (const char of frequentFlyerNumber) {
            if (UNUSED_CHARS.includes(char)) return false;
        }

        return true;
    };

    // EXPORTS

    const validateFrequentFlyerNumber = (frequentFlyerNumber: string) => {
        if (!frequentFlyerNumber) return false;

        try {
            const rules = [
                validateCharsAndLength,
                validateUnusedChars,
                validateOnlyFirstPositionChars,
                validateLastPositionChar,
                validateEbcdic,
            ];

            for (const rule of rules) if (!rule(frequentFlyerNumber.trim().toUpperCase())) return false;

            return true;
        } catch (error) {
            return false;
        }
    };

    return { validateFrequentFlyerNumber };
};
