import { ExtendedLoginResult } from "../component-models/LoginResult";
import { PromoCodeResult } from "../component-models/PromoCodeResult";
import { LoadSidebarEventData } from "../component-models/sidebar/LoadSidebarEventData";
import { OrgType } from "../shared/commonConstants";
import { PubSub } from "./PubSub";

export interface OpenLoginModal {
    isForced?: boolean;
    callback?: (result: ExtendedLoginResult) => Promise<void>;
}

export interface CultureChangeInBooking {
    cultureCode: string;
    callback: () => void;
}

const PUB_SUBS = {
    bancoEstado: {
        closeUpgradeBubble: new PubSub<{}>(),
        openUpgradeBubble: new PubSub<{}>(),
    },
    baggage: {
        reset: new PubSub<{}>(),
    },
    cug: {
        openModal: new PubSub<{}>(),
    },
    extras: {
        pageLoaded: new PubSub<{}>(),
    },
    flight: {
        cultureChanged: new PubSub<string>(),
        currencyChanged: new PubSub<string>(),
        flightPageCloseLoginInMenubar: new PubSub<{}>(),
        flightPageOpenLoginInMenubar: new PubSub<{}>(),
        showInvalidPromoCodeModal: new PubSub<PromoCodeResult>(),
        upgradeBancoEstadoDc: new PubSub<{}>(),
    },
    itinerary: {
        closeRefundModal: new PubSub<boolean>(),
        openLoginModalForRefund: new PubSub<OrgType>(),
    },
    login: {
        openLoginModal: new PubSub<OpenLoginModal>(),
        reloadPageOnLogin: new PubSub<{}>(),
        setFixedCurrency: new PubSub<string>(),
    },
    passengers: {
        employeeDiscountSelected: new PubSub<string>(),
        resetSpecialAssistance: new PubSub<{}>(),
    },
    payment: {
        confirmationModalContinue: new PubSub<{}>(),
        removeTermsAcceptance: new PubSub<{}>(),
    },
    peruCompra: {
        openLoginInfoForPCRA: new PubSub<{}>(),
    },
    seatmap: {
        closeMobileReview: new PubSub<{}>(),
        updateDiscountedSeatsUsed: new PubSub<number>(),
        updateFreeSeatsUsed: new PubSub<number>(),
    },
    sidebar: {
        bookingChanged: new PubSub<{}>(),
        bookingChangedWithForceReload: new PubSub<{}>(),
        flightSelectionChanged: new PubSub<LoadSidebarEventData>(),
        promoCodeEntered: new PubSub<string>(),
        promoCodeAccepted: new PubSub<{}>(),
    },
    shared: {
        currencyChanged: new PubSub<string>(),
        pageLoad: new PubSub<{}>(),
        resetSessionTimer: new PubSub<{}>(),
        windowWasScrolled: new PubSub<{}>(),
        cultureChangedInBooking: new PubSub<CultureChangeInBooking>(),
    },
    spa: {
        navigateToSection: new PubSub<string>(),
    },
};

export const usePubSub = () => {
    return { triggers: PUB_SUBS };
};
