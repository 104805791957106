import { Group } from "./../component-models/CUG2b/Group";
import { ApiTravelPartnerInfo } from "./../component-models/CUG2b/ApiTravelPartnerInfo";
import { PaymentMethodType } from "../component-models/PaymentMethodDescriptors";
import { TravelDocumentType } from "../component-models/checkin/TravelDocument";

export const ACTION_NAMES = {
    ADDITIONAL_INFO: "additionalinfo",
    BAGGAGE: "baggage",
    CHECKIN_SEATMAP: "checkinseatmap",
    CODE: "code",
    CONTACT: "contact",
    CUGPROFILE: "agencyprofile",
    DC_ITINERARY: "standalonedcitinerary",
    DC_REGISTER: "registerdc",
    EDITCUGPROFILE: "editagency",
    EMAIL: "email",
    EXTRAS: "extras",
    FFLYERS_ADD: "addtravelpartner",
    FFLYERS_ADMIN_GROUP: "admintravelgroups",
    FFLYERS_CREATE_GROUP: "addtravelgroup",
    FFLYERS_EDIT_GROUP: "edittravelgroup",
    FFLYERS_GROUPS: "travelgroups",
    FFLYERS: "travelpartners",
    FLIGHT_MOVE_DATE: "selectdate",
    FLIGHT_MOVE_FLIGHT: "selectnewitinerary",
    FLIGHT_MOVE_LEGS: "selectlegs",
    FLIGHT: "flight",
    FORGOT_PASSWORD: "forgotpassword",
    GIFTCARD_ACTIVATE: "activate",
    GIFTCARD_ITINERARY: "itinerary",
    GIFTCARD_PAYMENT: "payment",
    GIFTCARD_POSTCOMMIT: "postcommit",
    GIFTCARD_SELECT: "select",
    INDEX: "index",
    ITINERARY: "itinerary",
    LOGIN: "login",
    MOBILE_MENU: "mobilemenu",
    PASSENGERS: "passengers",
    PASSWORD_RESET_SUCCESS: "passwordresetsuccess",
    PASSWORD: "password",
    PAYMENT: "payment",
    POST_REGISTER: "postregister",
    PRINT: "print",
    PROFILE: "profile",
    REGISTER: "register",
    REGISTERBANCOESTADO: "registerbancoestado",
    RESERVATIONS: "reservations",
    REVIEW: "review",
    RUT: "rut",
    SEATMAP: "map",
    SELECT_DATE: "selectdate",
    STAFF_BENEFIT: "staffbenefit",
    STAFF_FAMILY_MEMBERS: "stafffamilymembers",
    STANDALONE_DC_ITINERARY: "standalonedcitinerary",
    SUMMARY: "summary",
    TRANSACTION: "transaction",
    TUTORIAL: "tutorial",
    USERS: "users",
};

export const CONTROLLER_NAMES = {
    GIFTCARD: "giftcard",
    FLIGHT_MOVE: "flightmove",
    CUG: "v2agency",
    CHECKIN: "v2checkin",
};

export const OUTBOUND = 0;
export const INBOUND = 1;

export const TRANSACTION_LOGGER_TABLE_COLUMN = {
    PAYMENT_INFORMATION: "paymentInformation",
    ORDER_INFORMATION: "orderInformation",
};

export const GIFTCARD_ACTIVATION_STEPS = {
    CODE: "activationCode",
    EMAIL: "email",
    PASSWORD: "password",
    REGISTRATION: "registration",
};

export const DC_GROUP_MEMBERSHIP_QUERY_SUBSTRING = "group=1";

export const DEFAULT_TAX_NUMBER_CODE = "VAT / NIF";

export const AGENCY_PAYMENT_FOP_CODE = "AG";
export const CREDIT_SHELL_PAYMENT_FOP_CODE = "CF";
export const ELAVON_PAYMENT_FOP_CODE = "EV";
export const PERU_TRANSFER_FOP_CODE = "PT";
export const SAFETYPAY_PAYMENT_FOP_CODE = "ST";
export const TRANSBANK_PAYMENT_FOP_CODE = "FT";
export const WEBPAY_PAYMENT_FOP_CODE = "TC";

export const ARGENTINIAN_CULTURE_CODE = "es-ar";
export const BOLIVIAN_CULTURE_CODE = "es-bo";
export const BRASILIAN_CULTURE_CODE = "pt-br";
export const CHILEAN_CULTURE_CODE = "es-cl";
export const COLOMBIAN_CULTURE_CODE = "es-co";
export const COSTA_RICAN_CULTURE_CODE = "es-cr";
export const ECUADORIAN_CULTURE_CODE = "es-ec";
export const GUATEMALAN_CULTURE_CODE = "es-gt";
export const HONDURAN_CULTURE_CODE = "es-hn";
export const MEXICAN_CULTURE_CODE = "es-mx";
export const NICARAGUAN_CULTURE_CODE = "es-ni";
export const PANAMANIAN_CULTURE_CODE = "es-pa";
export const PARAGUAYAN_CULTURE_CODE = "es-py";
export const PERUVIAN_CULTURE_CODE = "es-pe";
export const SALVADORAN_CULTURE_CODE = "es-sv";
export const URUGUAYAN_CULTURE_CODE = "es-uy";
export const USA_CULTURE_CODE = "en-us";
export const VENEZUELAN_CULTURE_CODE = "es-ve";

export const ARGENTINA_COUNTRY_CODE = "AR";
export const BRASIL_COUNTRY_CODE = "BR";
export const CHILE_COUNTRY_CODE = "CL";
export const COLOMBIA_COUNTRY_CODE = "CO";
export const ECUADOR_COUNTRY_CODE = "EC";
export const PARAGUAY_COUNTRY_CODE = "PY";
export const PERU_COUNTRY_CODE = "PE";
export const URUGUAY_COUNTRY_CODE = "UY";
export const USA_COUNTRY_CODE = "US";

export const ENGLISH_LANGUAGE_CODE = "en";
export const PORTUGESE_LANGUAGE_CODE = "pt";
export const SPANISH_LANGUAGE_CODE = "es";

export const ADULT_PAX_CODE = "ADT";

export const NAME_INPUT_MAX_LENGTH = 32;
// tslint:disable-next-line:max-line-length
export const ACCEPTED_CHARACTERS_FOR_TEXT_INPUT_REGEX = /^[a-zA-ZÑñÁáÂâÃãÀàÇçÉéÊêÍíÓóÔôÕõÚúÜüªº.'-\s]+$/;
export const ADDRESS_CHARS_REGEX = /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÂâÃãÀàÇçÊêÔôÕõŐőªº\-.,'\"\s]+$/i;
export const ALPHANUMERIC_REGEX = /^[a-zA-ZÑñÁáÂâÃãÀàÇçÉéÊêÍíÓóÔôÕõÚúÜüªº.'-\s\d]*$/;
export const DOCUMENT_NUMBER_REGEX = /^[a-zA-Z0-9-\s\d]*$/;
export const BANK_ACCOUNT_NUMBER_REGEX = /^([a-zA-Z0-9-])*$/;
export const CITY_NAME_REGEX = /^[a-zA-ZÑñÁáÉéÍíÓóÚúÂâÃãÀàÇçÊêÔôÕõŐő'-\s]+$/;
export const CURRENCY_REGEX = /^[A-Z]+$/;
export const EMAIL_REGEX = /^[a-zA-ZÑñÁáÂâÃãÀàÇçÉéÊêÍíÓóÔôÕõÚúÜü._@\d-]+$/;
export const FULL_EMAIL_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const GIFTCARD_CHARS_REGEX = /^[a-z0-9áéíñóúü¿¡ãàâçêíõô!:…․‥\-.;,?'\"\s]+$/i;
export const NO_SPECIAL_CHAR_REGEX = /^[^0-9*_$()=@\/\-%!+"'§`÷.:;,ˆˇ˘°×\?<>~\|{}&Ł€£ß#¿¡\\[\]]*$/;
export const NUMERIC_REGEX = /^[0-9]*$/;
export const PASSPORT_REGEX = /^[a-zA-Z0-9\u00C0-\u017F]$/;
export const PHONENUMBER_REGEX = /^[\d\s\+]$/;
export const POSTAL_CODE_REGEX = /^[a-zA-ZÑñÁáÉéÍíÓóÚúÂâÃãÀàÇçÊêÔôÕõŐőªº-\s\d]+$/;
export const PROMO_CODE_REGEX = /^([a-zA-Z0-9\u00C0-\u017F]){1,8}$/;
export const RUT_REGEX = /^[0-9-]+$/;
export const TRAVEL_DOCUMENT_ID_REGEX = /^[a-zA-Z0-9\-.]+$/i;
export const USER_NAME_REGEX = /^[a-zA-ZÑñÁáÂâÃãÀàÇçÉéÊêÍíÓóÔôÕõŐőÚúÜü.'@\d:-]+$/;
export const VOUCHER_REGEX = /^[0-9]{17}$/;
export const FREQUENT_FLYER_NUMBER_REGEX = /^[a-zA-Z0-9]$/;

export type InputType =
    | "accepted-text-chars"
    | "address"
    | "alphanumeric"
    | "bank-account-number"
    | "city"
    | "currency"
    | "document-number"
    | "e-mail"
    | "giftcard"
    | "no-special-character"
    | "numeric"
    | "passport"
    | "possible-phonenumber-chars"
    | "postal-code"
    | "promo-code"
    | "rut"
    | "travel-document-id"
    | "user-name"
    | "voucher"
    | "frequentFlyerNumber";

export type InputMode = "read" | "write";

export const RegExpForInputTypes = new Map<InputType, RegExp>([
    ["accepted-text-chars", ACCEPTED_CHARACTERS_FOR_TEXT_INPUT_REGEX],
    ["address", ADDRESS_CHARS_REGEX],
    ["alphanumeric", ALPHANUMERIC_REGEX],
    ["bank-account-number", BANK_ACCOUNT_NUMBER_REGEX],
    ["city", CITY_NAME_REGEX],
    ["currency", CURRENCY_REGEX],
    ["document-number", DOCUMENT_NUMBER_REGEX],
    ["e-mail", EMAIL_REGEX],
    ["giftcard", GIFTCARD_CHARS_REGEX],
    ["no-special-character", NO_SPECIAL_CHAR_REGEX],
    ["numeric", NUMERIC_REGEX],
    ["passport", PASSPORT_REGEX],
    ["possible-phonenumber-chars", PHONENUMBER_REGEX],
    ["postal-code", POSTAL_CODE_REGEX],
    ["promo-code", PROMO_CODE_REGEX],
    ["rut", RUT_REGEX],
    ["travel-document-id", TRAVEL_DOCUMENT_ID_REGEX],
    ["user-name", USER_NAME_REGEX],
    ["voucher", VOUCHER_REGEX],
    ["frequentFlyerNumber", FREQUENT_FLYER_NUMBER_REGEX],
]);

export const countryTaxnumberDictionary = {
    "CIF": ["ES"],
    "CNPJ": ["BR"],
    "CUIT/CUIL": ["AR"],
    "NIT": ["BO", "CO", "PA", "SV"],
    "NITE": ["CR"],
    "RFC": ["MX"],
    "RIF": ["VE"],
    "RNC": ["DO"],
    "RTN": ["HN"],
    "RTU": ["GT"],
    "RUC": ["PY", "PE", "NI", "EC"],
    "RUT": ["CL", "UY"],
    "SIN": ["CA"],
    "TIN": ["US"],
};

export const countryIndividualTaxnumberDictionary = {
    "CIF": ["ES"],
    "CPF": ["BR"],
    "NIT": ["BO", "PA", "SV"],
    "NITE": ["CR"],
    "RFC": ["MX"],
    "RIF": ["VE"],
    "RNC": ["DO"],
    "RTN": ["HN"],
    "RTU": ["GT"],
    "RUC": ["PE", "NI"],
    "RUT": ["CL"],
    "SIN": ["CA"],
    "TIN": ["US"],
    "DNI": ["PE", "AR"],
    "CI": ["PY", "EC"],
    "DI": ["CO"],
    "Cédula de identidad": ["UY"],
};

export const CURRENT_DISPLAYED_PAYMENT_FORM_ID = "currentDisplayedPaymentForm";

export const PAYMENT_CODES = {
    AmEx: "XP",
    Cabal: "CP",
    Diners: "NP",
    Discover: "DP",
    ForcedAmEx: "AF",
    Maestro: "MAESTRO",
    Magna: "MAGNA",
    MasterCard: "MP",
    MasterCardCodeForPeru: "PM",
    Naranja: "TN",
    Visa: "VP",
    VisaCodeForPeru: "PV",
    Hipercard: "HI",
    Elo: "EL",
};

export const PREFIX_CODES = new Map<string, string>([
    [ARGENTINIAN_CULTURE_CODE, "54"],
    [BRASILIAN_CULTURE_CODE, "55"],
    [CHILEAN_CULTURE_CODE, "56"],
    [COLOMBIAN_CULTURE_CODE, "57"],
    [ECUADORIAN_CULTURE_CODE, "593"],
    [PARAGUAYAN_CULTURE_CODE, "595"],
    [PERUVIAN_CULTURE_CODE, "51"],
    [URUGUAYAN_CULTURE_CODE, "598"],
    [USA_CULTURE_CODE, "1"],
]);

export const URL_VARS = {
    AMOUNT: "amount",
    BUNDLE_CODES: "BundleCodes",
    CULTURE: "culture",
    CURRENCY_CHANGE_USER: "usi",
    CURRENCY: "cur",
    FFLYER_GROUP_ID: "ffgid",
    FFLYER_GROUP_NAME: "ffgn",
    FFLYER_IDS: "ffids",
    FLIGHT_MOVE_RESULT: "change",
    GIFTCARD: "giftCard",
    PASSWORD_RESET_BANCO_ESTADO: "bancoEstadoLoginType",
    PASSWORD_RESET_CUG: "cug2LoginType",
    POST_BOOKING_INSURANCE: "pbi",
    PRICE_TYPE: "pt",
    QUANTITY: "quantity",
    SELECTED_MEMBERSHIP: "smt",
    SELL_KEYS: "SellKeys",
    SPA_HISTORY: "sh",
};

export const VOUCHER_AVAILABLE = 0;
export const VOUCHER_USED = 1;
export const VOUCHER_INVALID = 2;
export const VOUCHER_EXPIRED = 3;

export const SMART_BUNDLE_0_CODE = "BND0";
export const SMART_BUNDLE_1_CODE = "BND1";
export const SMART_BUNDLE_2_CODE = "BND2";
export const DC_BUNDLE_0_CODE = "BNC0";
export const DC_BUNDLE_1_CODE = "BNC1";
export const DC_BUNDLE_2_CODE = "BNC2";
export const BE_BUNDLE_0_CODE = "BNB0";
export const BE_BUNDLE_1_CODE = "BNB1";
export const BE_BUNDLE_2_CODE = "BNB2";
export const BE_DC_BUNDLE_0_CODE = "BEC0";
export const BE_DC_BUNDLE_1_CODE = "BEC1";
export const BE_DC_BUNDLE_2_CODE = "BEC2";
export const CUG2_BUNDLE_1_CODE = "BNDC";

export const PERUCOMPRA_BUNDLE_0_CODE = "BPC0";
export const PERUCOMPRA_BUNDLE_1_CODE = "BPC1";
export const PERUCOMPRA_BUNDLE_2_CODE = "BPC2";

export const CHILECOMPRA_BUNDLE_0_CODE = "BCC0";
export const CHILECOMPRA_BUNDLE_1_CODE = "BCC1";
export const CHILECOMPRA_BUNDLE_2_CODE = "BCC2";

export const BANCOESTADO_LOGIN_TYPE = "BE";

export const VOUCHER_PAYMENT_METHOD_CODE = "VO";
export const VOUCHER_PAYMENT_METHOD_TYPE = "Voucher";

export const MINIMUM_TODOSUMA_POINTS = 3000;

export const COOKIE_NAMES = {
    Amplitude: "JS_ABTestDeviceId",
    AntifraudFailed: "JS_AntifraudFailed",
    DiscountedSeatsUsedInBooking: "js_discseats",
    FreeSeatsUsedInBooking: "js_freeseats",
    IsBancoEstadoBarOpen: "js_bebar",
    IsNotificationBoxOpenedOnce: "js_cug_notification_opened",
    PeruCompraLogin: "JS_PcraLogin",
    PromoCodeFlightSelection: "JS_PC_FS",
    Tealium: "JS_Tealium",
};

export const ALWAYS_AVAILABLE_SSR_CODE = "none";
export const CABIN_BAG_CODES = ["LBGC", "LBGD", "LBGC", "LBAG", "LBG1", "LBG2"];
export const CHECKED_BAG_CODES = ["BAGC", "BAGD", "BAG1", "BAG2"];
export const OVERSIZED_BAG_CODES = ["SPQA", "SPQD", "SPQP"];
export const PRIORITY_BOARDING_CODES = ["PBOA", "PBA", "PBD", "PBP"];
export const AIRPORT_CHECKIN_CODES = ["APCH", "APCA", "APCD", "APCP", "APCF"];
export const FLEXI_FEE_CODES = ["FLXB"];
export const INSURANCE_FEE_CODES = ["TIF", "BIF", "SIF"];
export const PET_IN_CABIN_CODES = ["PTCA", "PTCD", "PTCP"];
export const SEAT_CODES = ["STB1", "STB2", "STBK", "STEX", "STFW", "STSM"];
export const SEAT_FEE_CODES = ["STF", "ST1", "ST2"];
export const AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES = ["ACAA"];

export const AllSeatsInBundlePlaceholder = "ALLSEATS";
export const StandardSeatsInBundlePlaceholder = "SOMESEATS";
export const ForcedFlexiFeePlaceholderCode = "FORCED_FLEXIFEE";

export const FAKE_NAVITAIRE_DATE_CHUNK = "9999";

export const FAKE_DATE_OF_BIRTH = "1920-12-31";
export const FAKE_DOCUMENT_EXPIRATION_DATE = "2039-12-31";

export const LINKS = {
    DC: "https://jetsmart.com/cl/es/club_de_descuentos",
    Home: "/",
    SmartPlus:
        "https://www.bancoestado.cl/imagenes/nuevo_form/11206_formulario_tarjeta_credito_jetsmart/solicitud_tarjeta_credito.asp",
    TodoSuma: "https://jetsmart.com/cl/es/minisitios/clientesbancoestado#puntostodosuma",
};

export const MAX_PAX_IN_STANDARD_DC_MEMBERSHIP = 2;
export const MAX_PAX_IN_GROUP_DC_MEMBERSHIP = 6;

export const REQUIRED_FORM_FIELD_ERROR_ID = "requiredFieldError";

export const BAGGAGE_PAGE_NAME = "baggage";

export const FORCE_CUG2_LOGIN_URL_PARTIAL = "lgi";

export const AB_ORIGINAL_SUFFIX = "Original";
export const AB_EXPERIMENT_OFF = "0";
export const AB_EXPERIMENT_ON = "1";
export type AbExperimentVariant = "0" | "1";

export const DC_MEMBERSHIP_STANDARD = "DCS";
export const DC_MEMBERSHIP_GROUP = "DCG";
export const DC_MEMBERSHIP_UPGRADE = "DCU";

export const ARGENTINIAN_PESO_CODE = "ARS";
export const BRASIL_REAL_CODE = "BRL";
export const BRITISH_POUND_CODE = "GBP";
export const CANADIAN_DOLLAR_CODE = "CAD";
export const CHILEAN_PESO_CODE = "CLP";
export const COLOMBIAN_PESO_CODE = "COP";
export const EURO_CODE = "EUR";
export const MEXICAN_PESO_CODE = "MXN";
export const PARAGUAYAN_GUARANI_CODE = "PYG";
export const PERUVIAN_SOL_CODE = "PEN";
export const PERUVIAN_SOL_CODE_ALTERNATIVE = "SOL";
export const URUGUAYAN_PESO_CODE = "UYU";
export const USA_DOLLAR_CODE = "USD";

export const GENERAL_CURRENCY_SIGN = "$";
export const BRASIL_REAL_CURRENCY_SIGN = "R$";
export const PERUVIAN_SOL_CURRENCY_SIGN = "S/";

export const ALLOWED_SPECIAL_KEYS = [
    "End",
    "Home",
    "Shift",
    "Control",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
    "Backspace",
    "Delete",
    "Enter",
];

export const REFUND_BANK_NAMES: string[] = [
    "Banco Paris",
    "Banco Ripley",
    "Banco Security",
    "Banco Del Estado De Chile",
    "Banco Consorcio",
    "HSBC Bank",
    "BCI (Banco De Crédito E Inversiones)",
    "Banco Santander",
    "Banco Do Brasil S.A.",
    "Coopeuch",
    "Itaú-Corpbanca",
    "Scotiabank",
    "Banco Internacional",
    "Banco Bice",
    "Banco De Chile-Edwards",
    "Banco Falabella",
    "Itau Chile",
    "BBVA (Banco Bilbao Vizcaya Arg)",
];

export const ECUADOR_DNI_MAX_LENGTH = 10;
export const ECUADOR_RUC_MAX_LENGTH = 13;

export const COLOMBIAN_DNI_MAX_LENGTH = 11;

export const PASSPORT_MAX_LENGTH = 35;

export const REFUND_ACCOUNT_TYPES: string[] = ["Cuenta de ahorro", "Cuenta vista", "Cuenta corriente"];

export const BANCO_ESTADO_ADDRESS_PREFIX = "BE_";

export const BANCO_ESTADO_SEAT_ZONE_START_ROW = 9;

export const NON_GROUP_BOOKING_MAX_PAX = 9;

export const NARANJA_CARD_BIN = "589562";

export const JS_ADDRESS = "Avenida del Valle 650, Huechuraba";

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export const ARGENTINA_PROVINCES: string[] = [
    "Buenos Aires",
    "Capital Federal",
    "Catamarca",
    "Chaco",
    "Chubut",
    "Córdoba",
    "Corrientes",
    "Entre Ríos",
    "Formosa",
    "Jujuy",
    "La Pampa",
    "La Rioja",
    "Mendoza",
    "Misiones",
    "Neuquén",
    "Río Negro",
    "Salta",
    "San Juan",
    "San Luis",
    "Santa Cruz",
    "Santa Fe",
    "Santiago del Estero",
    "Tierra del Fuego",
    "Tucumán",
];

export const URUGUAY_CITIES = [
    "Montevideo",
    "Salto",
    "Paysandú",
    "Maldonado",
    "Rivera",
    "Tacuarembó",
    "Melo",
    "Artigas",
    "Mercedes",
    "Minas",
    "San José de Mayo",
    "Durazno",
    "Florida",
    "Treinta y Tres",
    "Colonia del Sacramento",
    "Rocha",
    "Fray Bentos",
    "Trinidad",
    "Canelones",
];

export const CODES_WITH_MERCADO_PAGO_WARNING = ["MM"];

export const PAYMENT_METHOD_TYPE_ORDER = ["Debit", "Credit", "Other"] as PaymentMethodType[];

export const ABORT_CONTROLLER_ABORT_ERROR_CODE = 20;

export const CHANGING_CURRENCY_URL_ELEMENT = "pv=ja";
export const SUCCESSFUL_FLIGHT_MOVE = "change=successful";

export const WEBANONYMOUS = "WebAnonymous";

export const PAX_DOC_TYPE: { [type: string]: TravelDocumentType } = {
    DNI: "DNI",
    RUT: "N",
    Passport: "P",
};

export const CHECKIN_DEFAULT_TRAVEL_DOC_TYPES = new Map<string, TravelDocumentType>([
    [CHILEAN_CULTURE_CODE, PAX_DOC_TYPE.RUT],
    [USA_CULTURE_CODE, PAX_DOC_TYPE.RUT],
    [ARGENTINIAN_CULTURE_CODE, PAX_DOC_TYPE.DNI],
    [PERUVIAN_CULTURE_CODE, PAX_DOC_TYPE.DNI],
    [COLOMBIAN_CULTURE_CODE, PAX_DOC_TYPE.DNI],
    [BRASILIAN_CULTURE_CODE, PAX_DOC_TYPE.DNI],
    [URUGUAYAN_CULTURE_CODE, PAX_DOC_TYPE.DNI],
    [PARAGUAYAN_CULTURE_CODE, PAX_DOC_TYPE.DNI],
    [ECUADORIAN_CULTURE_CODE, PAX_DOC_TYPE.DNI],
]);

export const URI_DIVIDER_CHARACTER = "|";

export const FAKE_GROUP_ID_FOR_UNGROUPED_TRAVEL_PARTNERS = -1;

export const FAKE_GROUP_FOR_UNGROUPED_TRAVEL_PARTNERS: Group = {
    Id: FAKE_GROUP_ID_FOR_UNGROUPED_TRAVEL_PARTNERS,
    Name: "[partners not in groups]",
};

export const DEFAULT_TRAVEL_PARTNER_INFO: ApiTravelPartnerInfo = {
    Groups: [FAKE_GROUP_FOR_UNGROUPED_TRAVEL_PARTNERS],
    LastModified: {
        ModifiedAt: null,
        ModifiedBy: null,
    },
    Partners: [],
};

export const FARE_LOCK_DUMMY_PAX_FIRST_NAME = "FareLockPax";

export const DEFAULT_NUMBER_OF_PASSENGERS = 1;
export const MIN_NUMBER_FOR_GROUP_BOOKINGS = 10;

export const MERCADO_PAGO_BASE_PREFIX = "Base";
export const MERCADO_PAGO_DOMESTIC_POSTFIX = "dom";
export const MERCADO_PAGO_INTERNATIONAL_POSTFIX = "inter";
export const MERCADO_PAGO_DIVIDER = "_";

export const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";

export const DEFAULT_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss.SSS";

export const DUMMY_PAYMENT_CODE_FOR_HOLD = "DUMMY_PAYMENT_CODE_FOR_HOLD";

export const AGENCY_TYPE = "AGENCY";

export const CUG2_AGENCY_LOGOS = [
    {
        culture: USA_CULTURE_CODE,
        isAgency: true,
        isMobile: false,
        url: "/Images/Cug22/cug-logo-agency-white-en.svg",
    },

    {
        culture: USA_CULTURE_CODE,
        isAgency: true,
        isMobile: true,
        url: "/Images/Cug22/cug-logo-agency-en.svg",
    },

    {
        culture: USA_CULTURE_CODE,
        isAgency: false,
        isMobile: false,
        url: "/Images/Cug22/cug-logo-company-white-en.svg",
    },

    {
        culture: USA_CULTURE_CODE,
        isAgency: false,
        isMobile: true,
        url: "/Images/Cug22/cug-logo-company-en.svg",
    },

    {
        culture: undefined,
        isAgency: true,
        isMobile: false,
        url: "/Images/Cug22/cug-logo-agency-white-es.svg",
    },
    { culture: undefined, isAgency: true, isMobile: true, url: "/Images/Cug22/cug-logo-agency-es.svg" },
    {
        culture: undefined,
        isAgency: false,
        isMobile: false,
        url: "/Images/Cug22/cug-logo-company-white-es.svg",
    },
    { culture: undefined, isAgency: false, isMobile: true, url: "/Images/Cug22/cug-logo-company-es.svg" },
    {
        culture: BRASILIAN_CULTURE_CODE,
        isAgency: false,
        isMobile: true,
        url: "/Images/Cug22/cug-logo-company-pt.svg",
    },

    {
        culture: BRASILIAN_CULTURE_CODE,
        isAgency: true,
        isMobile: true,
        url: "/Images/Cug22/cug-logo-agency-pt.svg",
    },

    {
        culture: BRASILIAN_CULTURE_CODE,
        isAgency: true,
        isMobile: false,
        url: "/Images/Cug22/cug-logo-agency-white-pt.svg",
    },

    {
        culture: BRASILIAN_CULTURE_CODE,
        isAgency: false,
        isMobile: false,
        url: "/Images/Cug22/cug-logo-company-white-pt.svg",
    },
];

export const AGENCY_URL_PARTIAL_STRING = "agency";
export const COMPANY_URL_PARTIAL_STRING = "company";
export const PERU_COMPRA_PARTIAL_STRING = "pcompra";

export const LOGIN_TYPE = {
    JA: "JA",
    AGENCY: "AGENCY",
    COMPANY: "COMPANY",
    BE: "BE",
    PC: "PC",
};

export const STAFF_DISCOUNT_80 = "EM80";

export const FAKE_CUG2_GROUP_NAME = "Grupo Nuevo";

export const CULTURES_WITH_SUNDAY_AS_START_OF_WEEK = ["en-us", "pt-br"];

export const INSTALLMENT_PLACEHOLDER = "{{amount}}";

// TODO PCRA Real currency needed
export const PERU_COMPRA_CURRENCY = "USD";

export type PaxType = "ADT" | "CHD" | "INF";
export type ContactType = typeof EMERGENCY_CONTACT_TYPE | typeof CUSTOMER_CONTACT_TYPE;

export const EMERGENCY_CONTACT_TYPE = "E";
export const CUSTOMER_CONTACT_TYPE = "C";
export const APIS_INCOUNTRY_CONTACT_TYPE = "A";

export const FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS = 1;

export const USER_ROLE_CODE = {
    PCRA_GESTOR: "PECG",
    PCRA_DELEGADO: "PECD",
};

export const WORLDPAY_PAYMENT_METHOD_CODES = ["WW", "WV", "WD", "WM", "WorldPay"];

export const WOMPI_PAYMENT_METHOD_CODES = ["BA", "BN", "BR", "BP", "BX"];
export const WOMPI_PSE_PAYMENT_METHOD_CODE = "BP";

export type CugUserPhoneType = "general" | "office" | "mobile";

export const DEFAULT_ORG_CODE = "JA";

export type OrgType = typeof DEFAULT_ORG_CODE | "AGENCY" | "COMPANY" | "BE" | "PC";

export type LoginType = "JA" | "AGENCY" | "COMPANY" | "BE" | "PC";

export const INVOICE = "Invoice";
export const RECEIPT = "Receipt";

export const CULTURE = "culture";

export const COLOMBIAN_BOARDING_FEE_CODE = "COTX";
export const COLOMBIAN_STAMP_TAX_CODE = "DG";
export const COLOMBIAN_INTERNATIONAL_TOURISM_ARRIVAL_TAX_CODE = "JS";
export const COLOMBIAN_SALES_TAX_CODE = "YS";

export const COLOMBIAN_TAX_CODES = [
    COLOMBIAN_BOARDING_FEE_CODE,
    COLOMBIAN_STAMP_TAX_CODE,
    COLOMBIAN_INTERNATIONAL_TOURISM_ARRIVAL_TAX_CODE,
    COLOMBIAN_SALES_TAX_CODE,
];

export const PERU_COMPRAS_ONE_WAY = "OW";
export const PERU_COMPRAS_ROUND_TRIP = "RT";

// TODO Refactor, make a dictionary-like object with all the ACTION_NAMES below

export const NEW_SEARCH_ON_LOGIN_ACTIONS = [
    ACTION_NAMES.PASSENGERS,
    ACTION_NAMES.BAGGAGE,
    ACTION_NAMES.SEATMAP,
    ACTION_NAMES.EXTRAS,
    ACTION_NAMES.PAYMENT,
    ACTION_NAMES.REVIEW,
];

export const ACTIONS_WITH_BANCO_ESTADO_BAR = [
    ACTION_NAMES.BAGGAGE,
    ACTION_NAMES.EXTRAS,
    ACTION_NAMES.FLIGHT,
    ACTION_NAMES.PASSENGERS,
    ACTION_NAMES.PAYMENT,
    ACTION_NAMES.REVIEW,
    ACTION_NAMES.SEATMAP,
];

export const ACTIONS_WITHOUT_BREADCRUMBS = [
    ACTION_NAMES.CHECKIN_SEATMAP,
    ACTION_NAMES.FORGOT_PASSWORD,
    ACTION_NAMES.INDEX,
    ACTION_NAMES.ITINERARY,
    ACTION_NAMES.LOGIN,
    ACTION_NAMES.MOBILE_MENU,
    ACTION_NAMES.PASSWORD_RESET_SUCCESS,
    ACTION_NAMES.POST_REGISTER,
    ACTION_NAMES.PROFILE,
    ACTION_NAMES.REGISTER,
    ACTION_NAMES.REGISTERBANCOESTADO,
    ACTION_NAMES.REVIEW,
    ACTION_NAMES.SELECT_DATE,
    ACTION_NAMES.STAFF_BENEFIT,
    ACTION_NAMES.STAFF_FAMILY_MEMBERS,
    ACTION_NAMES.STANDALONE_DC_ITINERARY,
    ACTION_NAMES.TRANSACTION,
    ACTION_NAMES.TUTORIAL,
];

export const REAL_CHECKIN_ACTIONS = [ACTION_NAMES.PASSENGERS, ACTION_NAMES.ADDITIONAL_INFO, ACTION_NAMES.PRINT];

export const ACTIONS_WITHOUT_LOGIN = [
    ACTION_NAMES.CODE,
    ACTION_NAMES.EMAIL,
    ACTION_NAMES.GIFTCARD_ACTIVATE,
    ACTION_NAMES.LOGIN,
    ACTION_NAMES.PASSWORD,
    ACTION_NAMES.REGISTER,
    ACTION_NAMES.REGISTERBANCOESTADO,
    ACTION_NAMES.RUT,
    ACTION_NAMES.TRANSACTION,
];

export const INSURANCE_REMOVAL_NEEDED_ACTIONS = [
    ACTION_NAMES.PASSENGERS,
    ACTION_NAMES.BAGGAGE,
    ACTION_NAMES.SEATMAP,
    ACTION_NAMES.EXTRAS,
    ACTION_NAMES.PAYMENT,
];

export const ACTIONS_TO_DISPLAY_BANCO_ESTADO_BAR_SEAT_CHECK = [
    ACTION_NAMES.FLIGHT,
    ACTION_NAMES.PASSENGERS,
    ACTION_NAMES.BAGGAGE,
    ACTION_NAMES.SEATMAP,
    ACTION_NAMES.EXTRAS,
    ACTION_NAMES.REVIEW,
    ACTION_NAMES.PAYMENT,
];

export const ACTIONS_FOR_FLIGHT_MOVE = [
    ACTION_NAMES.FLIGHT_MOVE_DATE,
    ACTION_NAMES.FLIGHT_MOVE_FLIGHT,
    ACTION_NAMES.FLIGHT_MOVE_LEGS,
];

export const CONTROLLERS_WITHOUT_BREADCRUMBS = [
    CONTROLLER_NAMES.FLIGHT_MOVE,
    CONTROLLER_NAMES.GIFTCARD,
    CONTROLLER_NAMES.CUG,
];

export const DEBOUNCE_TIME = 250;

export type DatepickerType = "date-of-birth" | "expiration";

export const INVALID_PASSENGER_CONTACT_PHONE_NUMBERS = new Map<string, string>([
    [CHILEAN_CULTURE_CODE, "600 600 1311"],
    [PERUVIAN_CULTURE_CODE, "01 311 0005"],
    [ARGENTINIAN_CULTURE_CODE, "11 2206 7799"],
    [COLOMBIAN_CULTURE_CODE, "(601) 348 9581"],
    [BRASILIAN_CULTURE_CODE, "(+55 11) 3042 1183"],
    [URUGUAYAN_CULTURE_CODE, "598 41 3598 5582"],
    [PARAGUAYAN_CULTURE_CODE, "595 21 728 9039"],
    [ECUADORIAN_CULTURE_CODE, "963871997"],
    [USA_CULTURE_CODE, "600 600 1311"],
]);

export const DNI_LENGTH_DICTIONARY: Map<string, { min: number; max: number }> = new Map([
    [ARGENTINIAN_CULTURE_CODE, { min: 4, max: 30 }],
    [BRASILIAN_CULTURE_CODE, { min: 4, max: 30 }],
    [CHILEAN_CULTURE_CODE, { min: 4, max: 30 }],
    [COLOMBIAN_CULTURE_CODE, { min: 4, max: 11 }],
    [ECUADORIAN_CULTURE_CODE, { min: 8, max: 10 }],
    [PARAGUAYAN_CULTURE_CODE, { min: 4, max: 30 }],
    [PERUVIAN_CULTURE_CODE, { min: 8, max: 8 }],
    [URUGUAYAN_CULTURE_CODE, { min: 4, max: 30 }],
    [USA_CULTURE_CODE, { min: 4, max: 30 }],
]);
