export const CLASS_NAMES = {
    active: "active",
    ageErrorMessage: "ts-age-error-msg",
    Alert: "alert",
    AlertHeading: "alert-heading",
    BackendErrorsContanier: "ts-backend-errors",
    baggageCompulsory: "ts-baggage-compulsory",
    baggageLoader: "ts-baggage-loader",
    bancoEstadoBarOpen: "banco-estado-bar-open",
    birthDateDay: "ts-birth-day",
    birthDateMonth: "ts-birth-month",
    birthDateYear: "ts-birth-year",
    birthFullDate: "ts-birth-date",
    birthGroup: "ts-birth-group",
    checkinCheckboxLoader: "checkbox-loader",
    CheckinSidebarAccordion: "openable",
    CheckinSidebarAccordionContent: "accordion",
    CheckinSidebarAccordionOpen: "open",
    Condensed: "condensed",
    contentIntro: "content-intro",
    cugNoScroll: "no-scroll2",
    cugNoScrollOnlyMobile: "no-scroll3",
    cugNotificationBoxScroller: "notifications-box-scroller",
    disabled: "disabled",
    dowloadItemValue: "ts-download-item-content",
    downloadItemKey: "ts-download-item-title",
    downloadTitle: "ts-download-title",
    error: "form-error-message",
    errorContainer: "ts-error-container",
    errorIcon: "fa-exclamation-circle",
    errorParent: "ts-error-parent",
    ErrorWrapper: "error-wrapper",
    ErrorMessage: "error-msg",
    ErrorMessageContainer: "error-message-container",
    extrasSubmit: "ts-submit-extras",
    extrasFreeOptionWarning: "ts-extras-warning-1",
    extrasPayingOptionWarning: "ts-extras-warning-2",
    faded: "faded",
    fieldLengthMessage: "ts-field-length-message",
    flexiFee: "ts-flexi-fee",
    flightFeeSelector: "ts-fee-selector",
    flightSelectError: "ts-flight-select-error",
    flightSelectedFlightContainer: "selected-flight-container",
    flightWeekSelector: "week-selector",
    giftcardDesignSelector: "giftcard-design-selector",
    giftcardDesignOption: "giftcard-design-option",
    giftcardError: "giftcard-error",
    giftcardScrolling: {
        firstImage: "",
        secondImage: "second",
        thirdImage: "third",
        fourthImage: "fourth",
    },
    Hidden: "hidden",
    insurance: "ts-insurance",
    insuranceButton: "ts-insurance-button",
    insuranceForm: "ts-insurance-passengers",
    extrasInsuranceHeader: "extras-insurance-header",
    invalid: "invalid",
    invalidEmailFormat: "ts-invalid-email",
    loginCustomError: "ts-login-custom-error",
    loginPasswordField: "ts-login-pw",
    loginStatusPartial: "ts-login-status",
    mainHeader: "main-header",
    mainHeaderCollapsed: "collapsed",
    mobileNavigation: "mobile-navigation",
    mobileFlightModalList: "mobile-flight-modal-list",
    mobileFlightModalListDot: "flight-modal-list-dot",
    noScroll: "no-scroll",
    noChange: "no-change",
    passedContent: "passed-content",
    passenger: "ts-sidebar-passenger",
    passengerForm: "ts-passenger-details",
    PaymentGiftcardRow: "gpl-row",
    PayWithGiftcardButton: "utilize",
    petInCabin: "ts-pet-in-cabin",
    placeholder: "placeholder",
    priorityBoarding: "ts-priority-boarding",
    reducedBundle: "reduced",
    requiredMessage: "ts-required-error-message",
    selected: "selected",
    sidebar: "ts-sidebar",
    sidebarContainer: "ts-sidebar-container",
    sidebarLoader: "sidebar-container",
    sidebarName: "ts-sidebar-name",
    sidebarOpener: "ts-sidebar-opener",
    sidebarWrapper: "ts-sidebar-wrapper",
    sportsLoader: "ts-sports-loader",
    stickyInvalid: "sticky-invalid",
    title: "main-title",
    transactionTable: "payment-confirm-table",
    undismissed: "ts-undismissed",
    itineraryBannersContainer: "itinerary-banners-container",
};
