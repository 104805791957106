import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useAuthenticationTealiumManager } from "../../managers/Tealium/useAuthenticationTealiumManager";
import { CHILEAN_CULTURE_CODE, COOKIE_NAMES, URL_VARS } from "../../shared/commonConstants";
import { useEffect } from "../../shared/haunted/CustomHooks";
import {
    getAntiForgeryTokenFromHtml,
    getUrlVarsAsQueryString,
    hideLoader,
    makeNewUrl,
    showLoader,
} from "../../shared/common";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { CLASS_NAMES } from "../../shared/classNames";
import { getCookie } from "../../shared/cookieHandling";
import { FLIGHT_CLASS_NAMES } from "../../V2/booking/flight/FlightClassNames";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { ROUTES } from "../../shared/apiRoutes";
import { useUserContextBuilder } from "../../managers/useUserContextBuilder";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useEffect as hauntedUseEffect } from "haunted";

export const name = "ac-app-init";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];

export interface Attributes {
    "anti-forgery-token": string;
}

export interface Props {
    antiForgeryToken: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
    };

    const appContext = useAppContext();
    const userContextManager = useUserContextBuilder();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();
    const tealiumManager = useAuthenticationTealiumManager();

    const { triggers } = usePubSub();
    const { ajaxRequest, checkPromoCodeAvailable } = useAjax();

    const [userContext] = useReduxState("userContext");
    const [antiForgeryToken, setAntiForgeryToken] = useReduxState("antiForgeryToken");
    const [_, setIsBancoEstadoBarOpened] = useReduxState("isBancoEstadoBarOpened");

    const init = () => {
        userContextManager.init();

        if (
            bookingContext.promoCode &&
            window.location.href.toLowerCase().indexOf(URL_VARS.CULTURE.toLowerCase()) > -1
        ) {
            checkPromoCodeAvailable("");
            removeCultureFromUrl();
        }

        const handler = triggers.flight.currencyChanged.subscribe(handleCurrencyChange);

        if (userContext.bancoEstado.category > 0 && appContext.Culture !== CHILEAN_CULTURE_CODE) {
            const newUrl = makeNewUrl(CHILEAN_CULTURE_CODE);
            window.location.href = newUrl;
            return () => handler.unsubscribe();
        }

        if (!antiForgeryToken && props.antiForgeryToken) {
            setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        }

        tealiumManager.logUserLoginRegister();

        document.body.style.removeProperty("opacity");

        return () => handler.unsubscribe();
    };

    const initBancoEstadoBar = () => {
        const currentBancoEstadoBarCookie = getCookie(COOKIE_NAMES.IsBancoEstadoBarOpen);

        if (
            currentBancoEstadoBarCookie === "0" ||
            ![5, 6].includes(userContext.bancoEstado.category) ||
            flowContext.isCheckinFlow
        ) {
            setIsBancoEstadoBarOpened(false);
            document.body.classList.remove(CLASS_NAMES.bancoEstadoBarOpen);
        } else {
            setIsBancoEstadoBarOpened(true);
            document.body.classList.add(CLASS_NAMES.bancoEstadoBarOpen);
        }
    };

    const handleCurrencyChange = async (currencyCode: string) =>
        ajaxRequest({
            body: { selectedCurrencyCode: currencyCode },
            container: DomCrawlingHelper.getElemByClass(document.body, FLIGHT_CLASS_NAMES.ErrorParent),
            onResponseCode: {
                204: () => {
                    reloadPartials();
                },
            },
            url: ROUTES.FlightStoreState,
        });

    const reloadPartials = async () => {
        if (flowContext.isDcStandaloneFlow) return;

        const loader = showLoader({ name: CLASS_NAMES.sidebarLoader, noPlane: true });
        const loader2 = showLoader({});

        const baseUrl = window.location.href.split("?")[0];
        history.replaceState({}, null, baseUrl + "?" + getUrlVarsAsQueryString([]));

        await ajaxRequest({
            loader,
            container: DomCrawlingHelper.getElemByClass(document.body, FLIGHT_CLASS_NAMES.ErrorParent),
            method: "GET",
            url: ROUTES.AjaxPageFlight,
            onResponseCode: {
                200: async (data: string) => {
                    const container = DomCrawlingHelper.getElemByClass(document.body, FLIGHT_CLASS_NAMES.FlightPage);
                    container.innerHTML = data;
                },
            },
        });

        hideLoader(loader2);
    };

    const removeCultureFromUrl = () => {
        const [baseUrl, queryString] = window.location.href.split("?");

        const newQuery = queryString
            .split("&")
            .filter((elem) => elem.toLowerCase().indexOf(URL_VARS.CULTURE.toLowerCase()) === -1)
            .join("&");

        const newUrl = newQuery ? `${baseUrl}?${newQuery}` : baseUrl;

        history.replaceState({}, null, newUrl);
    };

    useEffect(initBancoEstadoBar, [userContext?.bancoEstado.category]);

    hauntedUseEffect(init, []);

    return html``;
};
