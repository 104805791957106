import {
    AGENCY_PAYMENT_FOP_CODE,
    BRASILIAN_CULTURE_CODE,
    CODES_WITH_MERCADO_PAGO_WARNING,
    COOKIE_NAMES,
    MINIMUM_TODOSUMA_POINTS,
    PAYMENT_METHOD_TYPE_ORDER,
    USA_CULTURE_CODE,
} from "../../shared/commonConstants";
import { useRef } from "haunted";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { TemplateResult, html } from "lit-html";
import { getLocalizedStringValue } from "../../shared/common";
import { PaymentMethod } from "../../component-models/PaymentMethodDescriptors";
import { ref } from "../../directives/ref";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { useInputIssuerCountry } from "./useInputIssuerCountry";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { useAgencyForm } from "./useAgencyForm";
import { useXmlPaymentMethodForm } from "./useXmlPaymentMethodForm";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { MercadoPagoApiNodeType } from "../../component-models/ApiPaymentMethodDescriptors";
import { useAppContext } from "../../managers/useAppContext";
import { ApiInitAutoTopUpCreditModel } from "../../component-models/payment/ApiInitAutoTopUpCredit";
import { ROUTES } from "../../shared/apiRoutes";
import { getCookie, setFifteenMinuteCookie } from "../../shared/cookieHandling";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useAntifraudResultModal } from "./useAntifraudResultModal";
import { useTodosumaModal56 } from "./useTodosumaModal56";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    isValidated: boolean;
    isAntifraudRestrictionOn: boolean;
    model: PaymentPageViewModel;
}

export interface MethodsContainer {
    selectedAgencyPaymentAmount: string;
    htmlTemplate: () => TemplateResult | string;
    validate: () => Promise<boolean>;
}

const PAYMENT_TAB_ICON_NAME = "pct";

export const usePaymentMethodsContainer = (props: Props): MethodsContainer => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { triggers } = usePubSub();
    const { ajaxJsonRequest } = useAjax();
    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");
    const [selectedMethod, setSelectedMethod] = useReduxState("payment.paymentMethod");
    const [payerData, setPayerData] = useReduxState("payment.payer");

    const root = useRef<HTMLDivElement>(undefined);
    const cardDataElem = useRef<HTMLDivElement>(undefined);

    const [showMethodSelectError, setShowMethodSelectError] = useState<boolean>(false);
    const [autoTopUpCredit, setAutoTopUpCredit] = useState<ApiInitAutoTopUpCreditModel>(undefined);

    const antifraudModal = useAntifraudResultModal();

    const inputCardIssuer = useInputIssuerCountry();

    const agencyForm = useAgencyForm({ isValidated: props.isValidated, model: props.model });

    const xmlPayment = useXmlPaymentMethodForm({
        isValidated: props.isValidated,
        model: props.model,
    });

    const todosuma56Modal = useTodosumaModal56({
        model: props.model,
        onCancel: () => setPayerData({ ...payerData, IsTodosumaConsentAccepted: false }),
        onAccept: () => setPayerData({ ...payerData, IsTodosumaConsentAccepted: true }),
    });

    // HELPERS

    const init = async () => {
        setSelectedMethod(
            userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking
                ? agencyPaymentMethod()
                : undefined,
        );

        if (
            props.model?.AgencyViewModel.ShowAgencyPayment &&
            !userContext.peruCompra.isAdmin &&
            !userContext.peruCompra.isMember
        ) {
            await loadTopUpData();
        }
    };

    const loadTopUpData = async () => {
        const response = await ajaxJsonRequest<ApiInitAutoTopUpCreditModel>({
            method: "GET",
            url: ROUTES.ApiRoutes.InitialTopUpCredit,
        });

        if (response.statusCode === 404) return;

        setAutoTopUpCredit(response?.data);
    };

    const agencyPaymentMethod = () =>
        props.model?.MethodsViewModel.PaymentMethods?.PaymentMethods?.find(
            (pm) => pm.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE,
        );

    const tabImgSrc = (method: PaymentMethod) => {
        const countrySpecificIconImg = method.CardIssuerCountryDependentIconUrls.find(
            (icon) => icon.CountryCode.toLowerCase() === payerData.CurrentCardIssuerCountry?.toLowerCase(),
        )?.Url;

        const bancoEstadoIconImg =
            [5, 6].includes(userContext.bancoEstado.category) && method.BancoEstado56IconUrl
                ? method.BancoEstado56IconUrl
                : [1, 2, 3, 4, 7].includes(userContext.bancoEstado.category) ||
                    ([5, 6].includes(userContext.bancoEstado.category) && !method.BancoEstado56IconUrl)
                  ? method.BancoEstadoDefaultIconUrl
                  : undefined;

        return bancoEstadoIconImg
            ? bancoEstadoIconImg
            : countrySpecificIconImg
              ? countrySpecificIconImg
              : method.DefaultIconUrl;
    };

    const showTodosumaInMoney = () => userContext.bancoEstado.todosumaPoints >= MINIMUM_TODOSUMA_POINTS;

    const isCountryBlacklisted = (method: PaymentMethod) =>
        method.CardIssuerCountryBlacklist.length > 0 &&
        method.CardIssuerCountryBlacklist.includes(payerData?.CurrentCardIssuerCountry);

    const isCountryNotOnWhitelist = (method: PaymentMethod) =>
        method.CardIssuerCountryWhitelist.length > 0 &&
        !method.CardIssuerCountryWhitelist.includes(payerData?.CurrentCardIssuerCountry);

    const isFeatureSwitchRestricted = (type: MercadoPagoApiNodeType) =>
        (!appContext.isFeatureSwitchActive("MercadoPagoCreditAggregator") && type === "Aggregator") ||
        (!appContext.isFeatureSwitchActive("MercadoPagoCreditGateway") && type === "Gateway");

    const isMethodAvailable = (method: PaymentMethod) =>
        !method.IsDisabled &&
        !isCountryBlacklisted(method) &&
        !isCountryNotOnWhitelist(method) &&
        !isFeatureSwitchRestricted(method.MercadoPagoApiNodeType);

    const numberOfSafeMethods = () =>
        props.model?.MethodsViewModel.PaymentMethods.PaymentMethods.filter(
            (method) => isMethodAvailable(method) && method.IsSafeForAntifraud,
        ).length;

    const isMethodDisplayed = (method: PaymentMethod) =>
        (!props.isAntifraudRestrictionOn || method.IsSafeForAntifraud || numberOfSafeMethods() === 0) &&
        isMethodAvailable(method);

    const getMethodByCode = (paymentMethodCode: string) =>
        props.model?.MethodsViewModel.PaymentMethods.PaymentMethods.find(
            (method) => method.PaymentMethodCode === paymentMethodCode,
        );

    const showBancoEstadoBenefits = () => [1, 2, 3, 4, 7].includes(userContext.bancoEstado.category);

    const validateFormMethodSelection = () => {
        if (!selectedMethod) setShowMethodSelectError(true);

        return Boolean(selectedMethod);
    };

    const orderedDisplayedMethods = () =>
        props.model?.MethodsViewModel.PaymentMethods.PaymentMethods.filter(
            (method) => method && isMethodDisplayed(getMethodByCode(method.PaymentMethodCode)) && method.DefaultIconUrl,
        ).sort(
            (a, b) =>
                PAYMENT_METHOD_TYPE_ORDER.indexOf(a.PaymentMethodType) -
                PAYMENT_METHOD_TYPE_ORDER.indexOf(b.PaymentMethodType),
        ) || [];

    const tabId = (method: PaymentMethod) =>
        `${
            method.Restrictions?.IsAvailableForBancoEstado && userContext.bancoEstado.category !== 0
                ? "be_payment_tab_"
                : "payment_tab_"
        }${method.PaymentMethodCode}`;

    const validate = async () => {
        let isValid = validateFormMethodSelection();
        isValid =
            isValid &&
            (selectedMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE || (await xmlPayment.validate())) &&
            (selectedMethod?.PaymentMethodCode !== AGENCY_PAYMENT_FOP_CODE || (await agencyForm.validate()));

        return isValid;
    };

    const reset = () => {
        setSelectedMethod(
            userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking
                ? agencyPaymentMethod()
                : undefined,
        );
        const tabIds = orderedDisplayedMethods().map(tabId);
        tabIds.forEach((tabId) => {
            const elem = document.getElementById(tabId) as HTMLInputElement;

            if (elem) {
                elem.checked = false;
            }
        });
    };

    // EVENT HANDLERS

    const handleAgencyTabClick = () => {
        setSelectedMethod(agencyPaymentMethod());
    };

    const handleFormSelect = (method: PaymentMethod) => {
        setSelectedMethod(method);

        if (
            [5, 6].includes(userContext.bancoEstado.category) &&
            userContext.bancoEstado.todosumaPoints >= MINIMUM_TODOSUMA_POINTS &&
            method.Restrictions.IsAvailableForBancoEstado &&
            method.ShowTodoSumaForBancoEstado &&
            !appContext.isFeatureSwitchActive("DisableTodoSuma")
        ) {
            todosuma56Modal.open();
        }
    };

    const handlePaymentMethodSelect = () => {
        inputCardIssuer.setShowCountrySelector(
            props.model?.MethodsViewModel.PaymentMethods.PaymentMethods.some(
                (method) => method.AllowedCards?.length > 0,
            ),
        );

        agencyForm.resetSelectedAgencyPaymentAmount();

        if (selectedMethod) {
            setShowMethodSelectError(false);
            window.setTimeout(() => ScrollHelper.scrollToCardData(cardDataElem.current), 200);
        }
    };

    const handleFraud = () => {
        if (props.isAntifraudRestrictionOn && getCookie(COOKIE_NAMES.AntifraudFailed) !== flowContext.bsid) {
            antifraudModal.open();
            triggers.payment.removeTermsAcceptance.publish({});
            setFifteenMinuteCookie(COOKIE_NAMES.AntifraudFailed, flowContext.bsid);
            reset();
        }
    };

    useEffect(() => {
        if (userContext?.userRole) init();
    }, [userContext?.userRole]);

    useEffect(handlePaymentMethodSelect, [selectedMethod?.PaymentMethodCode]);

    useEffect(reset, [payerData?.CurrentCardIssuerCountry]);

    useEffect(handleFraud, [props.isAntifraudRestrictionOn]);

    // TEMPLATES

    const errorTemplate = () =>
        showMethodSelectError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container text-left">
                              <div class="form-error-message">${i18next.t("Payment-MethodSelectionError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const bancoEstadoBenefitsTemplate = () => {
        const textToShow = !appContext.isFeatureSwitchActive("DisableTodoSuma")
            ? i18next.t(
                  "BE-PaymentFormInfo {{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}",
                  {
                      t1: "<span>",
                      t2: "</span>",
                  },
              )
            : i18next.t(
                  "BE-PaymentFormInfoShort {{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}{{-t1}}{{-t2}}",
                  {
                      t1: "<span>",
                      t2: "</span>",
                  },
              );
        return showBancoEstadoBenefits()
            ? html`
                  <div class="banco-estado-payment-form-info">
                      ${unsafeHTML(textToShow)}
                      ${!appContext.isFeatureSwitchActive("DisableTodoSuma")
                          ? html`<img src="/Images/BancoEstado/todosuma-puntos-logo-v2.svg" />`
                          : ""}
                  </div>
              `
            : "";
    };

    const editPaymentInfoTemplate = () =>
        selectedMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE
            ? html`
                  <div class="edit-amount-info-container">
                      ${i18next.t("Recuerda que puedes editar el monto que quieras usar con este medio de pago.")}
                  </div>
              `
            : "";

    const hiddenInputTemplate = (id: string, method: PaymentMethod) => html`
        <input
            id=${id}
            type="radio"
            name=${PAYMENT_TAB_ICON_NAME}
            data-test-id=${`payment-method-selector-input-${method.PaymentMethodCode}`}
            ?checked=${method.PaymentMethodCode === selectedMethod?.PaymentMethodCode}
            .checked=${method.PaymentMethodCode === selectedMethod?.PaymentMethodCode}
            @click=${() => handleFormSelect(method)}
        />
    `;

    const bancoEstadoBenefitOneTemplate = () => {
        const amount = formatNumber({ amount: userContext.bancoEstado.todosumaPoints, leadingSign: true });

        return showTodosumaInMoney() && !appContext.isFeatureSwitchActive("DisableTodoSuma")
            ? html`
                  <li>
                      ${unsafeHTML(
                          i18next.t("BE2-TodosumaInMoney {{-amount}}", {
                              amount: `<span class='emphasis'>${amount}</span>`,
                          }),
                      )}
                  </li>
              `
            : "";
    };

    const bancoEstadoBenefitTwoTemplate = () =>
        html` <li>
            ${unsafeHTML(
                i18next.t("BE2-FreeInstallments {{-start}}{{-end}}", {
                    start: "<span class='emphasis'>",
                    end: "</span>",
                }),
            )}
        </li>`;

    const bancoEstadoBenefitThreeTemplate = () =>
        !appContext.isFeatureSwitchActive("DisableTodoSuma")
            ? html` <li>
                  ${unsafeHTML(
                      i18next.t("BE2-GetMorePoints {{-start}}{{-end}}", {
                          start: "<span class='emphasis'>",
                          end: "</span>",
                      }),
                  )}
              </li>`
            : "";

    const cat56TodosumaInfoTemplate = (method: PaymentMethod) =>
        method.ShowTodoSumaForBancoEstado && [5, 6].includes(userContext.bancoEstado.category)
            ? html`
                  <div class="banco-estado-credit-card-user-info">
                      <ul>
                          ${bancoEstadoBenefitOneTemplate()} ${bancoEstadoBenefitTwoTemplate()}
                          ${bancoEstadoBenefitThreeTemplate()}
                      </ul>
                  </div>
              `
            : "";

    const bancoEstadoTabTemplate = (id: string, method: PaymentMethod) => {
        const bancoEstadoClassMap = classMap({
            "tab-banco-estado-payment-method": true,
            "push-down": [5, 6].includes(userContext.bancoEstado.category) && method.ShowTodoSumaForBancoEstado,
        });

        const imgClassMap = classMap({
            "banco-estado-tab-img": true,
            "be-credit-cards":
                [5, 6].includes(userContext.bancoEstado.category) && method.PaymentMethodType === "Credit",
        });

        return html`
            <li class=${bancoEstadoClassMap}>
                <label
                    for=${id}
                    data-test-id=${getTestId(T.PAYMENT.METHOD_SELECTOR_ICON_LABEL, { c: method.PaymentMethodCode })}
                >
                    <span class="be-payment-card-img">
                        <img
                            class=${imgClassMap}
                            src=${tabImgSrc(method)}
                            alt=${getLocalizedStringValue(method.Name, appContext.Culture)}
                            data-test-id=${getTestId(T.PAYMENT.METHOD_SELECTOR_ICON, { c: method.PaymentMethodCode })}
                        />
                    </span>
                    <span> ${method.BancoEstadoName || getLocalizedStringValue(method.Name, appContext.Culture)} </span>
                    ${cat56TodosumaInfoTemplate(method)}
                </label>
                <img class="payment-card-select-tick" src="/Images/BancoEstado/card-selector-tick.svg" />
            </li>
        `;
    };

    const warningTemplate = (method: PaymentMethod) => {
        const warningText = getLocalizedStringValue(method.WarningOnSelection, appContext.Culture);

        return warningText ? html` <div class="payment-method-warning">${warningText}</div> ` : "";
    };

    const nonBancoEstadoTabTemplate = (id: string, method: PaymentMethod) => html`
        <li>
            <label
                for=${id}
                data-test-id=${getTestId(T.PAYMENT.METHOD_SELECTOR_ICON_LABEL, { c: method.PaymentMethodCode })}
            >
                <img
                    class="payment-card-img"
                    src=${tabImgSrc(method)}
                    alt=${getLocalizedStringValue(method.Name, appContext.Culture)}
                    data-test-id=${getTestId(T.PAYMENT.METHOD_SELECTOR_ICON, { c: method.PaymentMethodCode })}
                />
                <span>${getLocalizedStringValue(method.Name, appContext.Culture)}</span>
                ${warningTemplate(method)}
            </label>
        </li>
    `;

    const mercadoWarningTemplate = () =>
        CODES_WITH_MERCADO_PAGO_WARNING.includes(selectedMethod?.PaymentMethodCode)
            ? html`
                  <div class="payment-mm-warning" data-test-id=${T.PAYMENT.MM_WARNING}>
                      ${i18next.t("MM-MmSelectedWarning")}
                  </div>
              `
            : "";

    const agencyInitialAmountRibbonTemplate = () => html`
        <div class="agency-payment-method-ribbon">
            <span class="js-icon-covid js-cv-dollar-sign"></span
            ><span class="font-extrabold">${autoTopUpCredit?.Amount}</span>
            <span>${i18next.t("abonados")}</span>
        </div>
    `;

    const agencyTabWithInitialAmountRibbonTemplate = (id: string, imgUrl: string) => html`
        <input id=${id} type="radio" name="pct" @click=${handleAgencyTabClick} />

        <li>
            <label
                class="agency-payment-tab"
                for=${id}
                data-test-id=${getTestId(T.PAYMENT.METHOD_SELECTOR_ICON_LABEL, {
                    c: AGENCY_PAYMENT_FOP_CODE,
                })}
            >
                <div class="agency-image-container payment-card-img">
                    <img
                        src=${imgUrl}
                        alt="agencia"
                        data-test-id=${getTestId(T.PAYMENT.METHOD_SELECTOR_ICON, {
                            c: AGENCY_PAYMENT_FOP_CODE,
                        })}
                    />
                    ${agencyInitialAmountRibbonTemplate()}
                </div>

                <span> ${props.model?.AgencyViewModel.OrgName} </span>
            </label>
        </li>
    `;

    const agencyTabWithoutInitialAmountRibbonTemplate = (id: string, imgUrl: string) => html`
        <input id=${id} type="radio" name="pct" @click=${handleAgencyTabClick} />

        <li>
            <label
                for=${id}
                data-test-id=${getTestId(T.PAYMENT.METHOD_SELECTOR_ICON_LABEL, {
                    c: AGENCY_PAYMENT_FOP_CODE,
                })}
            >
                <img
                    class="payment-card-img"
                    src=${imgUrl}
                    alt="agencia"
                    data-test-id=${getTestId(T.PAYMENT.METHOD_SELECTOR_ICON, { c: AGENCY_PAYMENT_FOP_CODE })}
                />
                <span> ${props.model?.AgencyViewModel.OrgName} </span>
            </label>
        </li>
    `;

    const agencyPaymentTabTemplate = () => {
        const id = `payment_tab_${AGENCY_PAYMENT_FOP_CODE}`;
        const imgUrl =
            appContext.Culture.toLowerCase() === USA_CULTURE_CODE.toLowerCase()
                ? "/Images/Icons/card-types/agency-en.png"
                : appContext.Culture.toLowerCase() === BRASILIAN_CULTURE_CODE.toLowerCase()
                  ? "/Images/Icons/card-types/agency-pt.png"
                  : "/Images/Icons/card-types/agency.png";

        const showTab =
            props.model?.AgencyViewModel.ShowAgencyPayment &&
            !userContext.peruCompra.isAdmin &&
            !userContext.peruCompra.isMember;

        if (!showTab) return html``;

        return autoTopUpCredit?.Amount && autoTopUpCredit.Amount > 0 && autoTopUpCredit.RemainingAmount > 0
            ? agencyTabWithInitialAmountRibbonTemplate(id, imgUrl)
            : agencyTabWithoutInitialAmountRibbonTemplate(id, imgUrl);
    };

    const agencyTemplate = () =>
        selectedMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE ? agencyForm.htmlTemplate() : "";

    const xmlPaymentTemplate = () =>
        selectedMethod?.PaymentMethodCode !== AGENCY_PAYMENT_FOP_CODE ? xmlPayment.htmlTemplate() : "";

    const paymentFormTemplate = () =>
        selectedMethod?.PaymentMethodCode ? html` ${agencyTemplate()} ${xmlPaymentTemplate()} ` : "";

    const cardTemplate = () =>
        props.model?.MethodsViewModel.PaymentMethods.PaymentMethods?.length > 0 ||
        props.model?.AgencyViewModel.ShowAgencyPayment
            ? html` ${inputCardIssuer.htmlTemplate()} ${paymentFormTemplate()} `
            : "";

    const iconsTemplate = () =>
        orderedDisplayedMethods().map((method) => {
            const id = tabId(method);
            const isForBancoEstado =
                method.Restrictions?.IsAvailableForBancoEstado && userContext.bancoEstado.category !== 0;

            return html`
                ${hiddenInputTemplate(id, method)}
                ${isForBancoEstado ? bancoEstadoTabTemplate(id, method) : nonBancoEstadoTabTemplate(id, method)}
            `;
        });

    const htmlTemplate = () =>
        !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking)
            ? html`
                  <div class="tabs ts-error-parent" ref=${ref(root)}>
                      ${bancoEstadoBenefitsTemplate()} ${errorTemplate()} ${editPaymentInfoTemplate()}
                      <nav>
                          <ul>
                              ${agencyPaymentTabTemplate()} ${iconsTemplate()} ${mercadoWarningTemplate()}
                          </ul>
                      </nav>

                      <div class="ts-error-parent" ref=${ref(cardDataElem)}>${cardTemplate()}</div>
                  </div>
                  ${antifraudModal.htmlTemplate()} ${todosuma56Modal.htmlTemplate()}
              `
            : paymentFormTemplate();

    return {
        selectedAgencyPaymentAmount: agencyForm.selectedAgencyPaymentAmount,
        htmlTemplate,
        validate,
    };
};
