import { TestIdDictionary as T } from "./../../testing-helpers/TestIdHelper";
import { ACTION_NAMES } from "./../../shared/commonConstants";
import { PROMO_CODE_REGEX, VOUCHER_REGEX } from "../../shared/commonConstants";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html, useState, useEffect as hauntedUseEffect } from "haunted";
import { hideLoader, showLoader } from "../../shared/common";
import { CLASS_NAMES } from "../../shared/classNames";
import { ROUTES } from "../../shared/apiRoutes";
import BookingFlowHandler from "../../shared/BookingFlowHandler";
import { BookingSteps } from "../../shared/BookingSteps";
import { JetSmartEvent } from "../../shared/eventbus/JetSmartEvent";
import { classMap } from "lit-html/directives/class-map";
import { ApiPromoCodeResult } from "../../component-models/ApiPromoCodeResult";
import { PromoCodeResult } from "../../component-models/PromoCodeResult";
import { ApiSidebarViewModel } from "../../component-models/sidebar/ApiSidebarViewModel";
import BookingData from "../../shared/BookingData";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-promo-code-box";

const openerId = "promoOpener";
const inputId = "promoCode";

export interface Props {
    model: ApiSidebarViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        model: host.model,
    };

    // Helpers

    const hidePromoCodeBox = () => {
        if (!props.model) return true;

        const wrongFlowType =
            (!flowContext.isBookingFlow && !flowContext.isFarelockRoundOne && !flowContext.isFarelockRoundTwo) ||
            flowContext.isFlightlessFlow ||
            props.model.HasPayments;
        const wrongFarelockStage = flowContext.isFarelockRoundTwo || props.model.IsFareLockPaidByVoucher;
        const wrongAction = [ACTION_NAMES.ITINERARY.toLowerCase()].indexOf(flowContext.action) > -1;

        return !props.model.IsPromotionEnabled || wrongFlowType || wrongFarelockStage || wrongAction;
    };

    const raisePromoCodeEvent = async (promoCode: string, status: "success" | "failed") => {
        bookingDataManager.handleBookingDataCallback(appContext.Culture, (bookingData: BookingData) => {
            window.eventBus.raiseEvent({
                name: JetSmartEvent.PromoCodeAdded,
                params: {
                    current_step: BookingFlowHandler.getCurrentStep(),
                    previous_step: BookingFlowHandler.getPreviousStep(),
                    id: "promocode",
                    event: "add",
                    status,
                    data: {
                        code: promoCode,
                        rules: {
                            currency: bookingData.PromotionDiscountCurrency,
                            value: bookingData.PromotionDiscount,
                        },
                        errors: [],
                    },
                },
            });
        });
    };

    // Event handlers

    const subscribeToPromoCodeHandling = () => {
        const handler = triggers.sidebar.promoCodeEntered.subscribe(handlePromoCodeAfterSelect);
        return () => handler.unsubscribe();
    };

    const clearInput = () => {
        const input = document.getElementById(inputId) as HTMLInputElement;
        if (input) {
            input.value = "";
        }
    };

    const subscribeToPageLoad = () => {
        if (!isValidated) return undefined;

        const handler = triggers.shared.pageLoad.subscribe(() => {
            setShowAjaxError(false);
            const elem = document.getElementById(openerId) as HTMLInputElement;

            if (elem?.checked) {
                elem.checked = false;

                clearInput();
            }
        });

        return () => handler.unsubscribe();
    };

    const handleSubmitPromoCode = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setShowAjaxError(false);
        setShowFormatError(false);
        setShowVoucherError(false);

        let isValid = true;
        setIsValidated(true);

        if (VOUCHER_REGEX.test(promoCode)) {
            setShowVoucherError(true);
            isValid = false;
        } else if (!PROMO_CODE_REGEX.test(promoCode)) {
            isValid = false;
            setShowFormatError(true);
        }

        if (promoCode && isValid) {
            triggers.sidebar.promoCodeEntered.publish(promoCode);
        }
    };

    const handlePromoCodeAfterSelect = async (value: string) => {
        if (
            [
                ACTION_NAMES.PASSENGERS,
                ACTION_NAMES.BAGGAGE,
                ACTION_NAMES.SEATMAP,
                ACTION_NAMES.EXTRAS,
                ACTION_NAMES.PAYMENT,
            ].indexOf(flowContext.action) === -1
        ) {
            return;
        }

        const loader = showLoader({ name: CLASS_NAMES.sidebarLoader, noPlane: true });
        const result = await sendPromoCode(value);

        if (result.modalWasShown) {
            raisePromoCodeEvent(value, "failed");
            hideLoader(loader);
            return;
        }

        if (result.isValid) {
            if (currentSpaSection === "Payment") {
                raisePromoCodeEvent(value, "success");
                window.location.reload();
                return;
            }

            triggers.sidebar.promoCodeAccepted.publish({});
            triggers.sidebar.bookingChanged.publish({});

            if (BookingFlowHandler.getCurrentStep() !== BookingSteps.FlightSelect) {
                raisePromoCodeEvent(value, "success");
            }

            bookingDataManager.fetchAndUpdate(ajaxJsonRequest);
        } else {
            raisePromoCodeEvent(value, "failed");
            if (result.invalidPromoCodeMessage !== "PromotionPartiallyApplied") setShowAjaxError(true);
        }

        if (result.invalidPromoCodeMessage) {
            triggers.flight.showInvalidPromoCodeModal.publish({ ...result, shouldReloadSidebar: true });
        }

        hideLoader(loader);
    };

    const sendPromoCode = async (promoCode: string): Promise<PromoCodeResult> => {
        try {
            const body = { PromotionCode: promoCode };
            const result = await ajaxRequest({
                url: ROUTES.ApplyPromotion,
                body,
            });

            if (result.statusCode === 406 && result.statusText.trim()) {
                const response: PromoCodeResult = {
                    isValid: false,
                    invalidPromoCodeMessage: result.statusText.trim(),
                    shouldReloadSidebar: false,
                };
                triggers.flight.showInvalidPromoCodeModal.publish(response);

                setShowAjaxError(false);
                setShowFormatError(false);
                setShowVoucherError(false);
                setPromoCode("");
                clearInput();

                return {
                    isValid: false,
                    invalidPromoCodeMessage: "",
                    modalWasShown: true,
                    shouldReloadSidebar: false,
                };
            }

            if (result.statusCode !== 200 && result.statusCode !== 204) {
                return {
                    isValid: false,
                    invalidPromoCodeMessage: "",
                    modalWasShown: false,
                    shouldReloadSidebar: false,
                };
            }

            const parsedContent = JSON.parse(result.data) as ApiPromoCodeResult;

            const mappedContent: PromoCodeResult = {
                isValid: parsedContent.isPromotionCodeValid,
                invalidPromoCodeMessage: parsedContent.invalidPromoCodeMessage,
                modalWasShown: false,
                shouldReloadSidebar: parsedContent.isPromotionCodeValid,
            };

            return mappedContent;
        } catch (e) {
            return {
                isValid: false,
                invalidPromoCodeMessage: "",
                modalWasShown: false,
                shouldReloadSidebar: false,
            };
        }
    };

    // Component

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingDataManager = useBookingDataManager();

    const { ajaxJsonRequest, ajaxRequest } = useAjax();
    const { triggers } = usePubSub();

    const [userContext] = useReduxState("userContext");
    const [currentSpaSection] = useReduxState("spa.activeSection");

    const [showAjaxError, setShowAjaxError] = useState<boolean>(false);
    const [showFormatError, setShowFormatError] = useState<boolean>(false);
    const [showVoucherError, setShowVoucherError] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [promoCode, setPromoCode] = useState<string>("");

    hauntedUseEffect(subscribeToPageLoad, [isValidated]);

    hauntedUseEffect(subscribeToPromoCodeHandling, [currentSpaSection, flowContext.action]);

    // Templates

    const promoCodeBoxTemplate = () => {
        if (userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking) {
            return promoCodeAcceptedTemplate();
        }

        if (hidePromoCodeBox()) {
            return html``;
        }

        if (props.model.PromoCodeAppliedByOrg) {
            return orgPromoCodeTemplate();
        }

        if (props.model.PromoCodeOverridden) {
            return promoCodeOverriddenTemplate();
        }

        if (props.model.IsInPromotion) {
            return promoCodeAcceptedTemplate();
        }

        return promoCodeInputFieldTemplate();
    };

    const orgPromoCodeTemplate = () => html`
        <div class="promo-code-header condensed">
            <i class="fas fa-check-circle promo-code-accepted-icon"></i>
            ${i18next.t("V2-OrgPromoCodeApplied")}
        </div>
    `;

    const promoCodeOverriddenTemplate = () => html`
        <div class="promo-code-header condensed taller">
            <i class="fas fa-check-circle promo-code-accepted-icon"></i>
            ${i18next.t("V2-OrgPromoCodeOverridesUserCode")}
        </div>
    `;

    const promoCodeAcceptedTemplate = () => html`
        <div class="promo-code-header">
            <i
                class="fas fa-check-circle promo-code-accepted-icon"
                data-test-id=${T.SIDEBAR.PROMO_CODE_ACCEPTED_ICON}
            ></i>
            ${i18next.t("V2-PromoCodeAccepted")}
        </div>
    `;

    const promoCodeInputFieldTemplate = () => {
        const inputClassMap = classMap({
            "mdl-textfield__input": true,
            "js-input": true,
            "invalid": showAjaxError,
        });

        return html`
            <div data-test-id=${T.SIDEBAR.PROMO_CODE_BOX}>
                <input id=${openerId} type="checkbox" data-test-id=${T.SIDEBAR.PROMO_CODE_OPENER_INPUT} />
                <label for=${openerId} class="promo-code-header" data-test-id=${T.SIDEBAR.PROMO_CODE_HEADER}>
                    <i class="js-icon js-promo-code-2 promo-code-icon notification-icon"></i>
                    ${i18next.t("V2-PromoCode")}
                    <span>${i18next.t("V2-PromoCode2")}</span>
                    <i data-test-id=${T.SIDEBAR.PROMO_CODE_ICON} class="js-icon js-chevron-right promo-open-icon"></i>
                </label>
                <div class="promo-code-content">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                        <input
                            @input=${(e: Event) => setPromoCode((e.target as HTMLInputElement).value)}
                            id=${inputId}
                            class=${inputClassMap}
                            type="text"
                            name="promoCode"
                            data-test-id=${T.SIDEBAR.PROMO_CODE_INPUT}
                        />
                        <label class="mdl-textfield__label" for=${inputId}>${i18next.t("V2-PromoCodeLabel")}</label>
                    </div>
                    <button
                        @click=${handleSubmitPromoCode}
                        class="rounded-primary-btn"
                        data-test-id=${T.SIDEBAR.PROMO_CODE_BUTTON}
                    >
                        ${i18next.t("ApplyPromoCode")}
                    </button>
                    ${ajaxErrorTemplate()} ${formatErrorTemplate()} ${voucherErrorTemplate()}
                </div>
            </div>
        `;
    };

    const ajaxErrorTemplate = () =>
        showAjaxError
            ? html`
                  <div class="error-message-container">
                      <div class="form-error-message ts-required-error-message">${i18next.t("Promocode-Invalid")}</div>
                  </div>
              `
            : "";

    const formatErrorTemplate = () =>
        showFormatError
            ? html`
                  <div class="error-message-container">
                      <div class="form-error-message ts-required-error-message">
                          ${i18next.t("V2-PromoCodeFormatInvalid")}
                      </div>
                  </div>
              `
            : "";

    const voucherErrorTemplate = () =>
        showVoucherError
            ? html`
                  <div class="error-message-container">
                      <div class="form-error-message ts-required-error-message">
                          ${i18next.t("V2-VoucherInPromoCodeError")}
                      </div>
                  </div>
              `
            : "";

    return promoCodeBoxTemplate();
};
