import { PromoCodeResult } from "../../../component-models/PromoCodeResult";
import { ROUTES } from "../../apiRoutes";
import { ScrollHelper } from "../../ScrollHelper";
import AjaxErrorResult from "../../AjaxErrorResult";
import DomCrawlingHelper from "../../DomCrawlingHelper";
import { CLASS_NAMES } from "../../classNames";
import AjaxErrorResultUnwrapped from "../../AjaxErrorResultUnwrapped";
import { commonDebug } from "../../../bootstrap";
import {
    ABORT_CONTROLLER_ABORT_ERROR_CODE,
    CULTURE,
    DC_MEMBERSHIP_STANDARD,
    DEFAULT_TRAVEL_PARTNER_INFO,
} from "../../commonConstants";
import { normalizeCulture } from "../../localeHelper";
import { hideLoader, showLoader } from "../../common";
import { LoadingQueueItem } from "./LoadingQueueItem";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import { useReduxState } from "../../redux/useReduxState";
import dayjs from "dayjs";
import { SearchInsuranceResult } from "../../../component-models/spa/SearchInsuranceResult";
import { mapToTravelPartnerInfo, mapToApiTravelPartnerInfo } from "../../../component-mappers/TravelPartnerMapper";
import { ApiTravelPartnerInfo } from "../../../component-models/CUG2b/ApiTravelPartnerInfo";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { ApiMercadoPagoInstallmentOption } from "../../../component-models/payment/ApiMercadoPagoInstallmentOption";
import { LOADER_CLASS_NAMES } from "../../LoaderClassNames";
import { useAppContext } from "../../../managers/useAppContext";
import { AjaxRequestParams } from "./AjaxRequestParams";
import { AjaxResponse } from "./AjaxResponse";
import { PromoCodeValidationResult } from "./PromoCodeValidationResult";
import { ajaxErrorMessageCreator } from "./ajaxErrorMessageCreator";
import { loadingQueueHandler } from "./loadingQueueHandler";
import i18next from "i18next";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { ApiUserBundleStatus } from "./ApiUserBundleStatus";

export const ANTI_FORGERY_TOKEN_PROPERTY_NAME = "__RequestVerificationToken";

// TODO Further refactor
export const useAjax = () => {
    const appContext = useAppContext();
    const cug2AppContext = useCug2AppContext();

    const { triggers } = usePubSub();
    const { createAjaxErrorMessage } = ajaxErrorMessageCreator();
    const { addToQueue, handleQueueItemFinished } = loadingQueueHandler();

    const [antiForgeryToken] = useReduxState("antiForgeryToken");

    // HELPERS

    const handleError = (data: {
        container: HTMLElement;
        errorObj: { code?: number; message?: string };
        queueItemId: number;
        request: AjaxRequestParams;
    }) => {
        if (data.errorObj.code === ABORT_CONTROLLER_ABORT_ERROR_CODE) return;

        const errorMessage =
            typeof data.errorObj.message === "string" ? data.errorObj.message : i18next.t("Ha ocurrido un error.");

        const callback =
            typeof data.request.onAllLoadingFinished === "function"
                ? () => data.request.onAllLoadingFinished(errorMessage)
                : undefined;

        // TODO: check if the following finished call makes sense
        handleQueueItemFinished(data.queueItemId, false, callback);
        handleAjaxRequestErrorMessage(data.container, data.errorObj);
    };

    const handleAjaxRequestErrorMessage = (
        container: HTMLElement,
        errorObj: { code?: number; message?: string },
    ): void => {
        const errorContainer = DomCrawlingHelper.getElemByClass(container, CLASS_NAMES.errorContainer);

        if (isJson(errorObj.message)) {
            addLocalizedAjaxErrorMessage(errorContainer, errorObj.message);
            return;
        }

        addAjaxErrorMessage({ container: errorContainer, messages: [errorObj.message] });
    };

    const addAjaxErrorMessage = (data: { container: HTMLElement; title?: string; messages?: string[] }): void => {
        if (!data.container) {
            commonDebug.error(data.messages?.join("; "));
            return;
        }

        const errorHtmlElement = createAjaxErrorMessage(data.title, data.messages);

        if (data.container.children?.length) {
            data.container.insertBefore(errorHtmlElement, data.container.children[0]);
        } else {
            data.container.appendChild(errorHtmlElement);
        }

        ScrollHelper.scrollToElementAndHideNav({
            element: data.container,
            yOffset: 60,
        });
    };

    const createFormBody = (data: AjaxRequestParams) => {
        const postBody = new FormData();

        if (data.body) {
            Object.keys(data.body).forEach((key) => {
                if (key.toLowerCase() === CULTURE) {
                    postBody.append(key, getNormalizedCulture(data, key));
                    return;
                }

                postBody.append(key, data.body[key]);
            });
        }

        if (data.file?.name && data.file?.content) {
            postBody.append(data.file.name, data.file.content);
        }

        return postBody;
    };

    const sanitizeParams = (data: AjaxRequestParams) => {
        const container = data.container || DomCrawlingHelper.getElemByClass(document.body, CLASS_NAMES.errorParent);
        const url = data.url || data.form.attributes.getNamedItem("action").value;
        const method = data.method || "POST";

        return { container, url, method };
    };

    // FIX ME casing problem of JSON serialization
    const addLocalizedAjaxErrorMessage = (container: HTMLElement, errorMessage: string) => {
        if (!errorMessage) return;

        const errorJson: AjaxErrorResult | AjaxErrorResultUnwrapped = JSON.parse(errorMessage);
        const errorContainer = (errorJson as AjaxErrorResult).ajaxErrorResult
            ? (errorJson as AjaxErrorResult).ajaxErrorResult
            : (errorJson as AjaxErrorResultUnwrapped);

        const errors = (errorContainer.Errors ? errorContainer.Errors : errorContainer.errors) || [];

        const messages = errors.reduce((allMessages, errorObj) => {
            if (errorObj.formattedErrors) {
                return errorObj.formattedErrors.length
                    ? allMessages.concat(
                          errorObj.formattedErrors.filter((e) => e.errorMessage).map((e) => e.errorMessage),
                      )
                    : allMessages;
            }

            return errorObj.FormattedErrors?.length
                ? allMessages.concat(errorObj.FormattedErrors.filter((e) => e.ErrorMessage).map((e) => e.ErrorMessage))
                : allMessages;
        }, []);

        addAjaxErrorMessage({ container, messages });
    };

    const getNormalizedCulture = (data: AjaxRequestParams, key: string): string =>
        data.isJsonData ? normalizeCulture(data.body[key]) : `${CULTURE}=${normalizeCulture(data.body[key])}`;

    const handleResponse = async (data: {
        container: HTMLElement;
        queueItem: LoadingQueueItem;
        request: AjaxRequestParams;
        response: Response;
    }): Promise<AjaxResponse<string>> => {
        const result = await data.response.text();

        const callback =
            typeof data.request.onAllLoadingFinished === "function"
                ? () => data.request.onAllLoadingFinished(result)
                : undefined;

        handleQueueItemFinished(data.queueItem.id, data.response.ok, callback);

        const shouldHandleResponseCode = typeof data.request.onResponseCode?.[data.response.status] === "function";

        if (shouldHandleResponseCode) data.request.onResponseCode[data.response.status](result);

        if (shouldHandleResponseCode || data.response.ok || data.request.forceReturnResultOnError) {
            return {
                data: result,
                statusCode: data.response.status,
                statusText: data.response.statusText,
                redirectionUrl: data.response.redirected ? data.response.url : "",
                isResponseOk: data.response.ok,
            };
        }

        handleAjaxRequestErrorMessage(data.container, {
            code: data.response.status,
            message: (result as any)?.message || data.response.statusText,
        });

        return undefined;
    };

    const prepareFetchOptions = (data: {
        abortSignal: AbortSignal;
        method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH";
        request: AjaxRequestParams;
    }) => {
        const fetchOptions: RequestInit = {
            credentials: "same-origin",
            method: data.method,
            signal: data.abortSignal,
        };

        if (data.request.isJsonData) {
            (fetchOptions as any).headers = {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json",
            };
        }

        if (data.method === "POST" || data.method === "DELETE" || data.method === "PUT") {
            fetchOptions.headers = {
                ...(fetchOptions.headers || {}),
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]:
                    antiForgeryToken || appContext?.AntiForgeryToken || cug2AppContext?.AntiForgeryToken,
            };

            fetchOptions.body = data.request.isJsonData
                ? JSON.stringify(data.request.body)
                : createFormBody(data.request);
        }

        if (data.request.noCors) fetchOptions.mode = "no-cors";

        return fetchOptions;
    };

    // EXPORTS

    const ajaxJsonRequest = async <T>(data: AjaxRequestParams): Promise<AjaxResponse<T>> => {
        const result = await ajaxRequest(data);

        try {
            return {
                ...result,
                data: JSON.parse(result.data) as T,
            };
        } catch (e) {
            return {
                data: undefined,
                statusCode: result?.statusCode,
                statusText: result?.statusText,
                redirectionUrl: result?.redirectionUrl,
                isResponseOk: result?.isResponseOk,
            };
        }
    };

    const ajaxRequest = async (request: AjaxRequestParams): Promise<AjaxResponse<string>> => {
        if (!request.form && !request.url) throw new Error("AJAX request invalid.");

        const { container, url, method } = sanitizeParams(request);

        const queueItem = addToQueue(url, request.nonCancellable);

        try {
            const fetchOptions = prepareFetchOptions({
                abortSignal: queueItem.abortController.signal,
                method,
                request,
            });

            const response = await fetch(url, fetchOptions);

            return handleResponse({ container, queueItem, request, response });
        } catch (e: any) {
            handleError({
                container,
                errorObj: { code: e.code, message: e.message },
                queueItemId: queueItem.id,
                request,
            });
        } finally {
            if (request.loader) hideLoader(request.loader);
        }

        return undefined;
    };

    const removeAjaxErrorMessage = (data: { container: HTMLElement }) => {
        if (!data.container) return;

        const error = DomCrawlingHelper.getElemByClass(data.container, CLASS_NAMES.ErrorWrapper);
        error?.remove();
    };

    const checkPromoCodeAvailable = async (
        paymentMethodCode: string,
        removePromo = true,
        suppressModal = false,
    ): Promise<boolean> => {
        try {
            const result = await ajaxJsonRequest<PromoCodeValidationResult>({
                nonCancellable: true,
                url: `${ROUTES.ApiRoutes.CheckPromoCode}?paymentMethodCode=${paymentMethodCode}&removePromo=${removePromo}`,
                method: "GET",
            });

            if (result?.statusCode !== 200) return true;

            if (!removePromo) return result.data.IsApplicable;

            if (suppressModal) {
                window.location.reload();
            } else {
                const mappedResult: PromoCodeResult = {
                    isValid: result.data.IsApplicable,
                    invalidPromoCodeMessage: result.data.Message,
                    shouldReloadSidebar: true,
                };

                triggers.flight.showInvalidPromoCodeModal.publish(mappedResult);
            }

            return result.data.IsApplicable;
        } catch (e) {
            commonDebug.error("Could not check promo code availability.");
            return true;
        }
    };

    const sendFormsToEmarsys = async (data: {
        email: string;
        firstName: string;
        isBancoEstado: boolean;
        isForDc: boolean;
        lastName: string;
        rut: string;
        selectedMembership: string;
    }): Promise<void> => {
        try {
            const emarsysFirstName = encodeURIComponent(data.firstName);
            const emarsysLastName = encodeURIComponent(data.lastName);
            const emarsysEmail = encodeURIComponent(data.email);
            const emarsysRut = data.rut ? encodeURIComponent(data.rut) : "";

            const membershipType = data.selectedMembership === DC_MEMBERSHIP_STANDARD ? "estándar" : "grupal";

            const memberShipStart = encodeURIComponent(dayjs().format("YYYY-MM-DD"));
            const memberShipEnd = encodeURIComponent(dayjs().add(1, "years").format("YYYY-MM-DD"));

            const emarsysUrl = data.isBancoEstado
                ? `https://linkscl.jetsmart.com/u/register.php?CID=814442181&f=1115&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y` +
                  `&inp_1=${emarsysFirstName}&inp_2=${emarsysLastName}&inp_3=${emarsysEmail}&inp_13751=${emarsysRut}` +
                  `&inp_16213=&inp_16170[]=0` +
                  `&inp_16311[]=1`
                : data.isForDc
                  ? `https://linkscl.jetsmart.com/u/register.php?CID=814442181&f=1113&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y` +
                    `&inp_1=${emarsysFirstName}&inp_2=${emarsysLastName}&inp_3=${emarsysEmail}&inp_16210=${membershipType}` +
                    `&inp_16211=${memberShipStart}&inp_16212=${memberShipEnd}&inp_16170[]=0&inp_16312[]=1`
                  : `https://linkscl.jetsmart.com/u/register.php?CID=814442181&f=1114&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y` +
                    `&inp_1=${emarsysFirstName}&inp_2=${emarsysLastName}&inp_3=${emarsysEmail}` +
                    `&inp_16170[]=0&inp_15808[]=1`;

            await ajaxRequest({
                method: "GET",
                noCors: true,
                url: emarsysUrl,
                onResponseCode: {
                    0: () => commonDebug.error("Register Emarsys submit failed"),
                },
            });
        } catch {
            commonDebug.error("Register Emarsys submit failed");
        }
    };

    const searchInsurance = async () => {
        const loader = showLoader({ name: CLASS_NAMES.itineraryBannersContainer });

        try {
            const result = await ajaxJsonRequest<SearchInsuranceResult>({
                loader,
                method: "GET",
                nonCancellable: true,
                url: ROUTES.InsuranceSearch,
            });

            const retVal =
                result?.statusCode === 200 || result?.statusCode === 204 ? result?.data || undefined : undefined;

            if (!retVal) throw new Error();

            return retVal;
        } catch (e) {
            commonDebug.error("Chubb error.");
            return undefined;
        }
    };

    const getTravelPartnerInfo = async (): Promise<TravelPartnerInfo> => {
        const result = await ajaxJsonRequest<ApiTravelPartnerInfo>({
            url: ROUTES.ApiRoutes.Cug2BTravelPartnerInfo,
            method: "GET",
        });

        const parsedResult = result?.statusCode === 200 ? result.data : DEFAULT_TRAVEL_PARTNER_INFO;

        return mapToTravelPartnerInfo(parsedResult);
    };

    const postTravelPartnerInfo = async (travelPartnerInfo: TravelPartnerInfo): Promise<TravelPartnerInfo> => {
        const newApiTravelPartnerInfo = mapToApiTravelPartnerInfo(travelPartnerInfo);
        const result = await ajaxJsonRequest<ApiTravelPartnerInfo>({
            url: ROUTES.ApiRoutes.Cug2BTravelPartnerInfo,
            body: { travelPartnerInfoJSON: JSON.stringify(newApiTravelPartnerInfo) },
        });

        const parsedResult = result?.statusCode === 200 ? result.data : DEFAULT_TRAVEL_PARTNER_INFO;

        return mapToTravelPartnerInfo(parsedResult);
    };

    const getMPApiResponse = async (cardNumber: string, amount: string, cardIssuerCountry: string) => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper });
        const getBin = (cardNumber: string) => cardNumber.substring(0, 6);
        const queryString = `binRange=${getBin(cardNumber)}&amount=${amount}&cardIssuerCountry=${cardIssuerCountry}`;
        const url = `${ROUTES.GetMercadoPagoInstallments}?${queryString}`;
        const result = await ajaxJsonRequest<ApiMercadoPagoInstallmentOption[]>({
            loader,
            method: "GET",
            url,
        });

        if (result?.statusCode === 200) {
            try {
                return result.data;
            } catch (e) {
                commonDebug.error("Could not parse MercadoPago installments.");
            }
        }

        return undefined;
    };

    const getBundleStatus = async (container: HTMLElement, loader: JsLoader): Promise<ApiUserBundleStatus> => {
        try {
            const result = await ajaxRequest({
                container,
                loader,
                method: "GET",
                url: ROUTES.DiscountBundleState,
            });

            if (result?.statusCode !== 200) return undefined;

            return JSON.parse(result.data) as ApiUserBundleStatus;
        } catch (e) {
            commonDebug.error(e);
        }

        return undefined;
    };

    const isJson = (str: string): boolean => {
        try {
            const o = JSON.parse(str);
            return o && typeof o === "object";
        } catch (e) {
            return false;
        }
    };

    return {
        ajaxJsonRequest,
        ajaxRequest,
        checkPromoCodeAvailable,
        getBundleStatus,
        getMPApiResponse,
        getTravelPartnerInfo,
        isJson,
        postTravelPartnerInfo,
        removeAjaxErrorMessage,
        searchInsurance,
        sendFormsToEmarsys,
    };
};
