import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { ApiCheckinPassengerViewModel } from "../../../component-models/checkin/ApiCheckinPassengerViewModel";
import { ApiCheckingInJourney } from "../../../component-models/checkin/ApiCheckingInJourney";
import i18next from "i18next";
import { CheckinPaxCheckboxChangeEvent } from "../../../component-models/checkin/CheckinEvents";
import { ROUTES } from "../../../shared/apiRoutes";
import { useBasicCheckbox } from "../../ui/basic-checkbox/useBasicCheckbox";

export const name = "ac-checkin-passenger";

export interface Props {
    isDeparted: boolean;
    isSelected: boolean;
    hasInvalidDocument: boolean;
    passenger: ApiCheckinPassengerViewModel;
    passengerJourney: ApiCheckingInJourney;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        isDeparted: host.isDeparted,
        isSelected: host.isSelected,
        hasInvalidDocument: host.hasInvalidDocument,
        passenger: host.passenger,
        passengerJourney: host.passengerJourney,
    };

    const isPaxRestricted = () =>
        !props.passengerJourney.IsCheckedIn &&
        (!props.passengerJourney.CanCheckIn || props.passengerJourney.HasRetrictedSSRs || props.hasInvalidDocument);

    const isPaxEnabled = () =>
        !props.passengerJourney.IsCheckedIn &&
        !props.isDeparted &&
        !props.passengerJourney.HasRetrictedSSRs &&
        !props.hasInvalidDocument;

    const paxName = () => `${props.passenger.Name.First} ${props.passenger.Name.Last}`;

    const boardingCardLink = () =>
        `${ROUTES.PageBoardingCards}?pn=${props.passenger.PassengerNumber}&sk=${props.passengerJourney.Journey.SellKey}`;

    const handleCheckboxChange = () => {
        host.dispatchEvent(new CheckinPaxCheckboxChangeEvent({ paxNumber: props.passenger.PassengerNumber }));
    };

    const checkboxLabelTemplate = (id: string) => html`
        <label for=${id} class="checkin-select-pax-label">
            <span class="checkin-select-pax-text">${paxName()}</span>
        </label>
    `;

    const selectPaxCheckbox = useBasicCheckbox({
        isChecked: props.isSelected,
        customWrapperClass: "checkin-select-pax-checkbox-wrapper",
        labelTemplate: checkboxLabelTemplate,
        onClick: handleCheckboxChange,
    });

    const enabledPassengerTemplate = () => (isPaxEnabled() ? html` ${selectPaxCheckbox.htmlTemplate()} ` : "");

    const checkedInPassengerTemplate = () =>
        props.passengerJourney.IsCheckedIn
            ? html`
                  <div class="chkbox-btn-wrapper non-checkbox">
                      <span class="checkin-note">${i18next.t("V2-Passengers-CheckedIn")}</span>
                      <span class="cb-title">${paxName()}</span>
                      <a class="checkin-note" href=${boardingCardLink()}>
                          <i class="js-icon js-print"></i>
                          ${i18next.t("V2-Passengers-Reprint")}
                      </a>
                  </div>
              `
            : "";

    const restrictedSsrPassengerTemplate = () =>
        isPaxRestricted()
            ? html`
                  <div class="chkbox-btn-wrapper non-checkbox">
                      <span class="checkin-note">${i18next.t("V2-Passengers-Restricted")}</span>
                      <span class="cb-title">${paxName()}</span>
                  </div>
              `
            : "";

    return html` ${enabledPassengerTemplate()} ${checkedInPassengerTemplate()} ${restrictedSsrPassengerTemplate()} `;
};
