import { GetBuildPageResult, SpaContent } from "../../../component-models/spa/SpaContent";
import i18next from "i18next";
import { html } from "lit-html";
import { ref } from "../../../directives/ref";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { useRef, useEffect as hauntedUseEffect } from "haunted";
import { mapToInfants, mapToNonInfants, mapToPassengersPageModel } from "../../../component-mappers/PassengerMapper";
import { ApiSpaPassengersViewModel } from "../../../component-models/spa/ApiSpaPassengersViewModel";
import { ROUTES } from "../../../shared/apiRoutes";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { useAppContext } from "../../../managers/useAppContext";
import { useFlowContext } from "../../../managers/useFlowContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { PassengersPageModel } from "../../../component-models/passengers/PassengersPageModel";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { useBasicCheckbox } from "../../ui/basic-checkbox/useBasicCheckbox";
import { SpaContainerViewModel } from "../../../component-models/spa/SpaContainerViewModel";
import { StaffBenefitDiscountItem } from "../../../component-models/staff-benefit/StaffBenefitDiscountItem";
import { useRunOnce } from "../../useRunOnce";
import { useFamilyMemberErrorModal } from "./useFamilyMemberErrorModal";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../../managers/useBookingContext";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useAddTravelPartnersToBookingModal } from "./useAddTravelPartnersToBookingModal";
import { SubmitAddTravelPartnerModalEvent } from "../../../component-models/passengers/PassengerEvents";
import BookingFlowHandler from "../../../shared/BookingFlowHandler";
import { BookingSteps } from "../../../shared/BookingSteps";
import { CLASS_NAMES } from "../../../shared/classNames";
import { getCoords, getRequestBodyFromObject, hideLoader, showLoader } from "../../../shared/common";
import { DEFAULT_DATE_FORMAT, FARE_LOCK_DUMMY_PAX_FIRST_NAME, PaxType } from "../../../shared/commonConstants";
import { usePassengersTealiumManager } from "../../../managers/Tealium/usePassengersTealiumManager";
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";
import { useBookingManager } from "../../../managers/useBookingManager";
import { raiseBookingFlowContinueEvent } from "../../../shared/eventbus/raiseBookingFlowContinueEvent";
import dayjs from "dayjs";
import { passengerFormFactory } from "./passengerFormFactory";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { FluentValidatorMethodsPartial, Validation } from "../../../validator/Validation";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";
import classNames from "classnames";
import { ApiNewPassenger, ApiNewPassengersModel } from "../../../component-models/passengers/ApiNewPassengersModel";
import { useDocumentCollectionHandler } from "../../shared/useDocumentCollectionHandler";
import { ApiPassengersUpdateRequest } from "../../../component-models/passengers/ApiPassengersUpdateRequest";
import { mapToApiPassengersUpdateRequest } from "../../../component-mappers/PassengerUpdateMapper";
import { mapToApiNewPassengersModel } from "../../../component-mappers/PassengerPostMapper";
import { PassengerFormVM } from "../../../component-models/passengers/PassengerFormVM";
import { ApiExtrasSpaSectionViewModel } from "../../../component-models/spa/ExtrasSpaSectionViewModel";
import { passengerHelpers } from "../../../component-helpers/passengers/passengerHelpers";
import { useFrequentFlyerNumberCollectionHandler } from "../../shared/useFrequentFlyerNumberCollectionHandler";

export interface NewPassengersPage extends SpaContent {
    passengersModel: PassengersPageModel;
}

export interface PassengersVM {
    passengers: PassengerFormVM[];
    selectedStaffDiscoundCode?: string;
}

export type PassengerFormFieldNames = keyof PassengersVM | `passengers.${string}.${keyof PassengerFormVM}`;

export interface PassengerUpdateEvent {
    key: keyof PassengerFormVM;
    passengerUniqueIndex: number;
    value: any;
}

export interface Props {
    hasFlightColombianStation: boolean;
    model: SpaContainerViewModel;
    onForward: () => void;
    spaModalStart: (submitCallback: () => Promise<void>) => void;
}

export type ValidationKey = `${keyof PassengersVM & string}.${number}`;

export const usePassengersPage = (props: Props): NewPassengersPage => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();
    const bookingContext = useBookingContext();

    const tealiumManager = usePassengersTealiumManager();
    const bookingManager = useBookingManager();

    const [activeSection] = useReduxState("spa.activeSection");
    const [isSidebarLoaded] = useReduxState("isSidebarLoaded");
    const [total] = useReduxState("booking.total");
    const [userContext] = useReduxState("userContext");
    const [_b, setPassengerNames] = useReduxState("booking.passengerNames");

    const { ajaxJsonRequest, getTravelPartnerInfo, removeAjaxErrorMessage } = useAjax();
    const { triggers } = usePubSub();
    const { getAssistanceOptionPaxNumber, getGenericPaxName, isJourneyInternationalToColombia, updatePaxArray } =
        passengerHelpers();

    const root = useRef<HTMLDivElement>(undefined);
    const formElement = useRef<HTMLFormElement>(undefined);
    const nameError = useRef<HTMLDivElement>(undefined);

    const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(0);
    const [adults, setAdults] = useState<PassengerFormVM[]>(undefined);
    const [children, setChildren] = useState<PassengerFormVM[]>(undefined);
    const [infants, setInfants] = useState<PassengerFormVM[]>(undefined);
    const [pageModel, setPageModel] = useState<PassengersPageModel>(undefined);
    const [validatedPaxKeys, setValidatedPaxKeys] = useState<Set<ValidationKey>>(new Set());
    const [isDiscountValidated, setIsDiscountValidated] = useState<boolean>(false);
    const [discountCode, setDiscountCode] = useState<string>(undefined);
    const [showNameUniquenessError, setShowNameUniquenessError] = useState<boolean>(undefined);
    const [addTravelPartnersModalOpen, setAddTravelPartnersModalOpen] = useState<boolean>(undefined);
    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [iAmTravelling, setIAmTravelling] = useState<boolean>(undefined);
    const [selectedFamilyMemberIds, setSelectedFamilyMemberIds] = useState<Map<number, string>>(new Map());
    const [isPassengerPageInitialized, setIsPassengerPageInitialized] = useState<boolean>(false);
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(undefined);
    const [needsDocumentsForInsurance, setNeedsDocumentsForInsurance] = useState<boolean>(undefined);
    const [originalFirstPassenger, setOriginalFirstPassenger] = useState<PassengerFormVM>(undefined);

    const runOnce = useRunOnce();

    const familyMemberErrorModal = useFamilyMemberErrorModal();

    const documentHandler = useDocumentCollectionHandler();
    const frequentFlyerNumberHandler = useFrequentFlyerNumberCollectionHandler();

    const filterApplicableDiscounts = (item: StaffBenefitDiscountItem) => {
        const nrOfSegmentsToUse =
            props.model.BaggageModel.Journeys.length *
            (bookingContext.adultsCount + bookingContext.childrenCount + bookingContext.infantsCount);

        return item.Usable && item.PassengerSegmentAvailable >= nrOfSegmentsToUse;
    };

    const discounts = useMemo(
        () => pageModel?.discounts?.AvailableDiscounts?.filter(filterApplicableDiscounts) || [],
        [pageModel],
    );

    const isMemberFirstPax = useMemo(
        () =>
            adults?.[0].firstName === pageModel?.memberFirstName && adults?.[0].lastName === pageModel?.memberLastName,
        [adults, pageModel],
    );

    const { getPassengerFormFields } = passengerFormFactory();

    const formFields = useMemo(
        () =>
            getPassengerFormFields({
                culture: appContext.Culture,
                flowType: flowContext.flowType,
                hasColombianStations: props.hasFlightColombianStation,
                isDc: bookingContext.isDiscountBooking || pageModel?.wantsDiscount,
                isInternationalFlight: pageModel?.isInternationalFlight,
                isPeruCompra:
                    bookingContext.isPeruCompraBooking ||
                    userContext?.peruCompra.isAdmin ||
                    userContext.peruCompra.isMember,
                isStaff: userContext?.isStaff,
                passengerIndicesWithAcaa: pageModel?.passengerIndicesWithACAA,
                needsDocumentsForInsurance,
            }),
        [
            appContext.Culture,
            bookingContext,
            flowContext.flowType,
            pageModel,
            props.hasFlightColombianStation,
            userContext?.userRole,
        ],
    );

    const passengersVm: PassengersVM = useMemo(
        () => ({
            passengers: [...(adults || []), ...(children || []), ...(infants || [])],
            selectedStaffDiscoundCode: discountCode,
        }),
        [adults, children, infants, discountCode],
    );

    const paxNeedsDefaultCountry = (pax: ApiNewPassenger) => {
        if (pax.Name.FirstName === FARE_LOCK_DUMMY_PAX_FIRST_NAME) return false;

        if (pax.Type === "ADT" && props.hasFlightColombianStation) return true;

        if (
            (pax.Type === "ADT" || pax.Type === "CHD") &&
            (bookingContext.isPeruCompraBooking || userContext?.peruCompra.isAdmin || userContext.peruCompra.isMember)
        ) {
            return true;
        }

        return false;
    };

    const defaultCountry = (model: ApiNewPassengersModel, type: PaxType) =>
        formFields.some(
            (field) =>
                field.key === "country" && model.Passengers.some((p) => p.Type === type && paxNeedsDefaultCountry(p)),
        )
            ? appContext.Country
            : undefined;

    const passengerValidator = useFluentValidator<keyof PassengersVM | PassengerFormFieldNames, PassengersVM>({
        vm: passengersVm,
        validated: validatedPaxKeys,
        validations: [
            Validation.ruleFor("passengers", (model: PassengersVM) => model.passengers).fulfilsValidations(
                formFields.flatMap((f) => f.validators),
                (vm: PassengerFormVM) => vm.uniqueIndex,
            ),
        ],
    });

    const discountValidator = useFluentValidator<keyof PassengersVM | PassengerFormFieldNames, PassengersVM>({
        vm: passengersVm,
        validated: isDiscountValidated,
        validations: [
            Validation.ruleFor("selectedStaffDiscoundCode", (model: PassengersVM) => model.selectedStaffDiscoundCode)
                .when(() => userContext?.isStaff)
                .isRequired(i18next.t("Debes seleccionar un descuento."), "field"),
        ],
    });

    const passengerValidatorPartial: FluentValidatorMethodsPartial = {
        getMessage: (field: string) =>
            passengerValidator.getMessage(field as PassengerFormFieldNames | keyof PassengersVM),
        isFieldValid: (field: string) =>
            passengerValidator.isFieldValid(field as PassengerFormFieldNames | keyof PassengersVM),
    };

    const discountValidatorPartial: FluentValidatorMethodsPartial = {
        getMessage: (field: string) => discountValidator.getMessage(field as keyof PassengersVM),
        isFieldValid: (field: string) => discountValidator.isFieldValid(field as keyof PassengersVM),
    };

    const formErrors = useErrorMessage({ errorMessage: passengerValidator.getFormMessages() });

    const isSubmitDisabled = useMemo(
        () =>
            isSubmitBtnDisabled ||
            activeSection !== "Passengers" ||
            (!flowContext.isFarelockRoundOne &&
                (Object.keys(passengerValidator.messages).length ||
                    validatedPaxKeys.size !== passengersVm.passengers.length)),
        [isSubmitBtnDisabled, activeSection, passengerValidator.messages, validatedPaxKeys.size],
    );

    const init = () => {
        if (!userContext.userRole || !adults?.length) return undefined;

        BookingFlowHandler.storeCurrentStep(BookingSteps.Passengers);

        const startWithSecondPax =
            flowContext.isFarelockRoundTwo && adults.length + children.length + infants.length > 1;

        if (startWithSecondPax) setOpenAccordionIndex(1);

        if (shouldShowFamilyMemberErrorModal()) familyMemberErrorModal.open();

        setIsPassengerPageInitialized(true);

        const handler = triggers.passengers.employeeDiscountSelected.subscribe(setDiscountCode);

        return () => handler.unsubscribe();
    };

    const allowedFamilyMembers = () =>
        pageModel?.family?.FamilyMembers?.filter(
            (member) =>
                (iAmTravelling || member.CanTravelWithoutUser) &&
                !Array.from(selectedFamilyMemberIds.values()).includes(member.GUID),
        ) ?? [];

    const shouldShowFamilyMemberErrorModal = () =>
        userContext.isStaff && passengersVm.passengers.length > 1 && pageModel?.family.FamilyMembers.length === 0;

    const showFrequentTravellers = () =>
        userContext.cug.isMember &&
        !(userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking) &&
        !flowContext.isFarelockRoundOne;

    const onlyFamilyMembersWhoCantTravelAlone = () =>
        pageModel?.family?.FamilyMembers?.every((m) => !m.CanTravelWithoutUser);

    const iAmTravellingLabelTemplate = (id: string) => html`
        <label for=${id}>
            <span class="cb-title">${i18next.t("I am travelling")}</span>
        </label>
    `;

    const iAmNotTravellingLabelTemplate = (id: string) => html`
        <label for=${id}>
            <span class="cb-title">${i18next.t("I am not travelling")}</span>
        </label>
    `;

    const hasTravelPartners = () => travelPartnerInfo?.Partners?.length > 0;

    const fullResetFamilyMembers = (iAmTravelling: boolean) => {
        const newAdults = adults.map((a) =>
            a.passengerNumber === 0 && (iAmTravelling || onlyFamilyMembersWhoCantTravelAlone())
                ? originalFirstPassenger
                : {
                      ...a,
                      firstName: "",
                      lastName: "",
                      dateOfBirth: undefined,
                      documentNumber: "",
                  },
        );

        setAdults(newAdults);
        setChildren(children.map((c) => ({ ...c, FirstName: "", LastName: "", DateOfBirth: undefined })));
        setInfants(infants.map((i) => ({ ...i, FirstName: "", LastName: "", DateOfBirth: undefined })));
        documentHandler.resetTravelDocuments(onlyFamilyMembersWhoCantTravelAlone());

        setSelectedFamilyMemberIds(new Map());
    };

    const isFirstPaxProvided = () =>
        flowContext.isFarelockRoundTwo && Boolean(adults[0].firstName) && Boolean(adults[0].lastName);

    const getName = (a: PassengerFormVM, isInfant: boolean, ignoreCase = false) => {
        const name =
            a.firstName || a.lastName
                ? `${a.firstName?.trim()} ${a.lastName?.trim()}`
                : getGenericPaxName(a.passengerNumber + (isInfant ? adults.length + children.length : 0), isInfant);

        return ignoreCase ? name.toLowerCase() : name;
    };

    const updatePassengerNames = () => {
        if (!adults?.length) return;

        setPassengerNames([
            ...(adults || []).map((adult) => getName(adult, false)),
            ...(children || []).map((child) => getName(child, false)),
            ...(infants || []).map((infant) => getName(infant, true)),
        ]);
    };

    const scrollToNameUniquenessError = () => {
        if (!showNameUniquenessError) return;

        ScrollHelper.scrollToElementAndHideNav({ element: nameError.current });
    };

    const isDummyFareLockPax = (name: string) =>
        name && name.toLowerCase().startsWith(FARE_LOCK_DUMMY_PAX_FIRST_NAME.toLowerCase());

    const areAllFullNamesUnique = () =>
        !passengersVm.passengers.some((pax1) => {
            const isPax1Infant = pax1.type === "INF";
            const pax1Name = getName(pax1, isPax1Infant, true);

            return passengersVm.passengers.some((pax2) => {
                const isPax2Infant = pax2.type === "INF";
                const pax2Name = getName(pax2, isPax2Infant, true);

                return (
                    !isDummyFareLockPax(pax1Name) &&
                    pax1.passengerNumber !== pax2.passengerNumber &&
                    pax1Name === pax2Name &&
                    isPax1Infant === isPax2Infant
                );
            });
        });

    const isPaxValidated = (pax: PassengerFormVM, forced?: boolean) =>
        forced || validatedPaxKeys.has(`passengers.${pax.uniqueIndex}`);

    const isPaxInvalid = (pax: PassengerFormVM, forced?: boolean) =>
        isPaxValidated(pax, forced) &&
        (Object.keys(passengerValidator.messages).some((key) => key.startsWith(`passengers.${pax.uniqueIndex}.`)) ||
            !documentHandler.isDocumentNumberUnique(pax.documentNumber) ||
            !frequentFlyerNumberHandler.isFrequentFlyerNumberUnique(pax.frequentFlyerNumber));

    const postForm = async () =>
        flowContext.isBookingFlow || flowContext.isFarelockRoundOne || flowContext.isFarelockRoundTwo
            ? postFormDuringBooking()
            : postFormPostBooking();

    const postFormPostBooking = async (): Promise<boolean> => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper, container: root.current });
        removeAjaxErrorMessage({ container: root.current });

        const body: ApiPassengersUpdateRequest = mapToApiPassengersUpdateRequest({
            culture: appContext.Culture,
            defaultCountry: appContext.Country,
            departureStationCode: bookingContext.originStationCode,
            documents: [...documentHandler.travelDocuments, ...frequentFlyerNumberHandler.frequentFlyerNumbers],
            passengers: passengersVm.passengers,
        });

        const passengerUpdateResult = await ajaxJsonRequest({
            isJsonData: true,
            body,
            container: root.current,
            loader,
            method: "POST",
            url: ROUTES.ApiRoutes.PostUpdatePassengers,
            forceReturnResultOnError: true,
        });

        switch (passengerUpdateResult.statusCode) {
            case 200:
            case 204:
                return true;
            default:
                return false;
        }
    };

    const postFormDuringBooking = async (): Promise<boolean> => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper, container: root.current });
        removeAjaxErrorMessage({ container: root.current });

        const body: ApiNewPassengersModel = mapToApiNewPassengersModel({
            culture: appContext.Culture,
            defaultCountry: appContext.Country,
            departureStationCode: bookingContext.originStationCode,
            discountCode,
            documents: [...documentHandler.travelDocuments, ...frequentFlyerNumberHandler.frequentFlyerNumbers],
            isFarelockRoundOne: flowContext.isFarelockRoundOne,
            passengers: passengersVm.passengers,
        });

        return Boolean(bookingManager.postPassengers(getRequestBodyFromObject(body), root.current, loader));
    };

    const handleSubmitPassenger = (pax: PassengerFormVM) => {
        const newItem: ValidationKey = `passengers.${pax.uniqueIndex}`;
        setValidatedPaxKeys(new Set([...validatedPaxKeys, newItem]));

        if (isPaxInvalid(pax, true)) return;

        setOpenAccordionIndex(
            openAccordionIndex < adults.length + children.length + infants.length - 1 ? openAccordionIndex + 1 : -1,
        );
    };

    const handleFormSubmitSuccess = () => {
        if (userContext.isStaff) triggers.sidebar.bookingChangedWithForceReload.publish({});
        raiseBookingFlowContinueEvent(true);
        tealiumManager.logContinueClicked();
        props.onForward();
    };

    const handleFormSubmitFail = () => {
        setIsSubmitBtnDisabled(false);
        raiseBookingFlowContinueEvent(false);
    };

    const handleInvalidForm = () => {
        if (!areAllFullNamesUnique()) return;

        window.setTimeout(() => {
            const firstError = root.current.querySelector(
                `.${CLASS_NAMES.invalid}, .${CLASS_NAMES.error}`,
            ) as HTMLElement;
            if (firstError) {
                const topOfElement = getCoords(firstError).top - 140;
                window.scroll({ top: topOfElement, behavior: "smooth" });
            }
        }, 200);
    };

    const handleFormSubmit = async () => {
        setIsSubmitBtnDisabled(true);
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper, container: root.current });
        const result = await postForm();

        if (result) {
            handleFormSubmitSuccess();
        } else {
            handleFormSubmitFail();
        }

        hideLoader(loader);
    };

    const handleSubmitClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const passengersValidationSet = new Set(
            passengersVm.passengers.map((p): ValidationKey => `passengers.${p.uniqueIndex}`),
        );

        setValidatedPaxKeys(passengersValidationSet);
        setIsDiscountValidated(true);

        let arePassengersValid = await passengerValidator.validate();
        const isDiscountValid = await discountValidator.validate();

        if (!areAllFullNamesUnique()) {
            setShowNameUniquenessError(true);
            arePassengersValid = false;
        } else {
            setShowNameUniquenessError(false);
        }

        if (!documentHandler.areAllDocumentNumbersUnique()) arePassengersValid = false;
        if (!frequentFlyerNumberHandler.areAllFrequentFlyerNumbersUnique()) arePassengersValid = false;

        if (arePassengersValid && isDiscountValid) {
            if (typeof props.spaModalStart === "function") {
                props.spaModalStart(handleFormSubmit);
            } else {
                handleFormSubmit();
            }
        } else {
            handleInvalidForm();
        }
    };

    const iAmTravellingCheckbox = useBasicCheckbox({
        isChecked: isMemberFirstPax || iAmTravelling,
        customWrapperClass: "travelling-person-checkbox-wrapper",
        labelTemplate: iAmTravellingLabelTemplate,
        onClick: () => handleIAmTravelling(),
    });

    const iAmNotTravellingCheckbox = useBasicCheckbox({
        isChecked: !iAmTravelling && !isMemberFirstPax,
        customWrapperClass: ["travelling-person-checkbox-wrapper", "ml-4", "md:ml-8"],
        labelTemplate: iAmNotTravellingLabelTemplate,
        onClick: () => handleIAmNotTravelling(),
    });

    const handleIAmTravelling = () => {
        fullResetFamilyMembers(true);
        setIAmTravelling(true);
        triggers.passengers.resetSpecialAssistance.publish({});
        setValidatedPaxKeys(new Set());
    };

    const handleIAmNotTravelling = () => {
        fullResetFamilyMembers(false);
        setIAmTravelling(false);
        triggers.passengers.resetSpecialAssistance.publish({});
        setValidatedPaxKeys(new Set());
    };

    const handleAccordionClick = (e: MouseEvent, paxIndex: number) => {
        e.preventDefault();
        e.stopPropagation();

        setOpenAccordionIndex(paxIndex === openAccordionIndex ? -1 : paxIndex);
    };

    const handleAddTravelPartners = (e: SubmitAddTravelPartnerModalEvent) => {
        const newAdults = adults.map((a, index): PassengerFormVM => {
            const partner = e.detail.partners[index];

            return a.passengerNumber === (isFirstPaxProvided() ? index + 1 : index) && partner
                ? {
                      ...a,
                      firstName: partner.FirstName,
                      lastName: partner.LastName,
                      dateOfBirth: partner.DOB ? dayjs(partner.DOB) : undefined,
                      documentType: partner.DocumentType === "DNI" ? "DNI" : partner.DocumentType === "PAS" ? "P" : "N",
                      documentNumber: partner.DocumentId,
                      country: partner.Nationality,
                  }
                : a;
        });

        setAdults(newAdults);
        setAddTravelPartnersModalOpen(false);
        documentHandler.updateTravelDocument(newAdults);
    };

    const handleFamilyMemberSelect = (pax: PassengerFormVM, familyMemberGuid: string) => {
        const newSelectedFamilyMembers = new Map(selectedFamilyMemberIds);
        newSelectedFamilyMembers.set(pax.uniqueIndex, familyMemberGuid);
        setSelectedFamilyMemberIds(newSelectedFamilyMembers);

        const selectedFamilyMember = pageModel.family.FamilyMembers.find((m) => m.GUID === familyMemberGuid);

        switch (pax.type) {
            case "ADT":
                const newAdult: PassengerFormVM = {
                    ...pax,
                    firstName: selectedFamilyMember.FirstName,
                    lastName: selectedFamilyMember.LastName,
                    dateOfBirth: selectedFamilyMember.BirthDate
                        ? dayjs(selectedFamilyMember.BirthDate, DEFAULT_DATE_FORMAT)
                        : undefined,
                    documentNumber: selectedFamilyMember.Id,
                    documentType:
                        selectedFamilyMember.DocType === "DNI"
                            ? "DNI"
                            : selectedFamilyMember.DocType === "Passport"
                              ? "P"
                              : "N",
                };

                const newAdults = adults.map(
                    (adult): PassengerFormVM => (adult.passengerNumber === pax.passengerNumber ? newAdult : adult),
                );
                setAdults(newAdults);
                documentHandler.updateTravelDocument(newAdult);
                break;
            case "CHD":
                const newChild: PassengerFormVM = {
                    ...pax,
                    firstName: selectedFamilyMember.FirstName,
                    lastName: selectedFamilyMember.LastName,
                    dateOfBirth: selectedFamilyMember.BirthDate
                        ? dayjs(selectedFamilyMember.BirthDate, DEFAULT_DATE_FORMAT)
                        : undefined,
                    documentNumber: selectedFamilyMember.Id,
                    documentType:
                        selectedFamilyMember.DocType === "DNI"
                            ? "DNI"
                            : selectedFamilyMember.DocType === "Passport"
                              ? "P"
                              : "N",
                };
                const newChildren = children.map(
                    (child): PassengerFormVM => (child.passengerNumber === pax.passengerNumber ? newChild : child),
                );
                setChildren(newChildren);
                documentHandler.updateTravelDocument(newChild);
                break;
            case "INF":
                const newInfant: PassengerFormVM = {
                    ...pax,
                    firstName: selectedFamilyMember.FirstName,
                    lastName: selectedFamilyMember.LastName,
                    dateOfBirth: selectedFamilyMember.BirthDate
                        ? dayjs(selectedFamilyMember.BirthDate, DEFAULT_DATE_FORMAT)
                        : undefined,
                    documentNumber: selectedFamilyMember.Id,
                    documentType:
                        selectedFamilyMember.DocType === "DNI"
                            ? "DNI"
                            : selectedFamilyMember.DocType === "Passport"
                              ? "P"
                              : "N",
                };
                const newInfants = infants.map(
                    (i): PassengerFormVM => (i.attachedPassengerNumber === pax.attachedPassengerNumber ? newInfant : i),
                );
                setInfants(newInfants);
                documentHandler.updateTravelDocument(newInfant);
                break;
        }
    };

    const handleAdultChange = (e: PassengerUpdateEvent) =>
        setAdults((adults) => updatePaxArray(adults, e, documentHandler.travelDocuments));

    const handleChildChange = (e: PassengerUpdateEvent) =>
        setChildren((children) => updatePaxArray(children, e, documentHandler.travelDocuments));

    const handleInfantChange = (e: PassengerUpdateEvent) =>
        setInfants((infants) => updatePaxArray(infants, e, documentHandler.travelDocuments));

    useEffect(() => {
        iAmNotTravellingCheckbox.update();
        iAmTravellingCheckbox.update();
    }, [iAmTravelling, isMemberFirstPax]);

    useEffect(scrollToNameUniquenessError, [showNameUniquenessError]);

    useEffect(updatePassengerNames, [adults, children, infants]);

    useEffect(() => {
        if (activeSection === "Passengers" && isSidebarLoaded && total) {
            setIsSubmitBtnDisabled(false);
            runOnce.run(() => tealiumManager.logPassengersPageLoad(props.model.FlatBookingData));
        }

        if (activeSection !== "Passengers") runOnce.reset();
    }, [activeSection, total, isSidebarLoaded]);

    hauntedUseEffect(init, [userContext?.userRole, adults?.length]);

    const employeeDiscountSelectorTemplate = () =>
        pageModel?.useEmployeeDiscount
            ? html`
                  <ac-employee-discount-selector
                      .appliedCode=${discountCode}
                      .setAppliedCode=${setDiscountCode}
                      .discounts=${discounts}
                      .validator=${discountValidatorPartial}
                  ></ac-employee-discount-selector>
              `
            : "";

    const staffMemberTravellingTemplate = () =>
        userContext.isStaff && adults?.length > 0 && !onlyFamilyMembersWhoCantTravelAlone() && discounts
            ? html`
                  <div class="inner-box staff-passenger-header">
                      <h3 class="passenger-title">${i18next.t("Are you travelling?")}</h3>
                      <div class="flex items-center">
                          ${iAmTravellingCheckbox.htmlTemplate()} ${iAmNotTravellingCheckbox.htmlTemplate()}
                      </div>
                  </div>
              `
            : "";

    const nonInfantTemplate = (pax: PassengerFormVM, i: number) => {
        return html`
            <ac-passenger
                .arrivalDate=${pageModel.arrivalDate}
                .assistanceOptions=${pageModel?.assistanceOptions.filter(
                    (o) => getAssistanceOptionPaxNumber(o) === pax.passengerNumber,
                ) || []}
                .departureDate=${pageModel.departureDate}
                .familyMembers=${allowedFamilyMembers()}
                .formFields=${formFields}
                .iAmTravelling=${iAmTravelling}
                .isDocNumberUnique=${documentHandler.isDocumentNumberUnique(pax.documentNumber)}
                .isFrequentFlyerNumberUnique=${frequentFlyerNumberHandler.isFrequentFlyerNumberUnique(
                    pax.frequentFlyerNumber,
                )}
                .isOpen=${pax.passengerNumber === openAccordionIndex || isPaxInvalid(pax)}
                .isPassengerPageInitialized=${isPassengerPageInitialized}
                .isPaxCheckedIn=${pageModel.checkedInPaxIndexes.includes(pax.passengerNumber)}
                .oxyOptions=${pageModel?.oxyOptions.filter(
                    (o) => getAssistanceOptionPaxNumber(o) === pax.passengerNumber,
                ) || []}
                .paxData=${pax}
                .paxIndexByType=${i}
                .uniqueIndex=${pax.passengerNumber}
                .validator=${passengerValidatorPartial}
                .isValid=${isPaxValidated(pax) && !isPaxInvalid(pax)}
                .handleAccordionClick=${handleAccordionClick}
                .handleChange=${pax.type === "ADT" ? handleAdultChange : handleChildChange}
                .handleFamilyMemberSelect=${handleFamilyMemberSelect}
                .handleSubmitPassenger=${() => handleSubmitPassenger(pax)}
                .onDocumentBlur=${() => documentHandler.updateTravelDocument(pax)}
                .onFrequentFlyerBlur=${() => frequentFlyerNumberHandler.updateFrequentFlyerNumber(pax)}
            ></ac-passenger>
        `;
    };

    const infantTemplate = (pax: PassengerFormVM, i: number) => {
        return html`
            <ac-passenger
                .arrivalDate=${pageModel.arrivalDate}
                .assistanceOptions=${[]}
                .departureDate=${pageModel.departureDate}
                .familyMembers=${allowedFamilyMembers()}
                .formFields=${formFields}
                .iAmTravelling=${iAmTravelling}
                .isDocNumberUnique=${documentHandler.isDocumentNumberUnique(pax.documentNumber)}
                .isFrequentFlyerNumberUnique=${frequentFlyerNumberHandler.isFrequentFlyerNumberUnique(
                    pax.frequentFlyerNumber,
                )}
                .isOpen=${pax.uniqueIndex === openAccordionIndex || isPaxInvalid(pax)}
                .isPassengerPageInitialized=${isPassengerPageInitialized}
                .isPaxCheckedIn=${pageModel.checkedInPaxIndexes.includes(pax.attachedPassengerNumber)}
                .oxyOptions=${[]}
                .paxData=${pax}
                .paxIndexByType=${i}
                .uniqueIndex=${pax.uniqueIndex}
                .validator=${passengerValidatorPartial}
                .isValid=${isPaxValidated(pax) && !isPaxInvalid(pax)}
                .handleAccordionClick=${handleAccordionClick}
                .handleChange=${handleInfantChange}
                .handleFamilyMemberSelect=${handleFamilyMemberSelect}
                .handleSubmitPassenger=${() => handleSubmitPassenger(pax)}
                .onDocumentBlur=${() => documentHandler.updateTravelDocument(pax)}
                .onFrequentFlyerBlur=${() => frequentFlyerNumberHandler.updateFrequentFlyerNumber(pax)}
            ></ac-passenger>
        `;
    };

    const passengerAccordionsTemplate = () => html`
        ${adults?.map(nonInfantTemplate)} ${children?.map(nonInfantTemplate)} ${infants?.map(infantTemplate)}
    `;

    const travelPartnersButtonTemplate = () =>
        showFrequentTravellers()
            ? html`
                  <div class="cug2b-add-travel-partners-btn-container">
                      <div
                          class=${classNames("cug2b-add-travel-partners-btn", { disabled: !hasTravelPartners() })}
                          @click=${hasTravelPartners() ? () => setAddTravelPartnersModalOpen(true) : null}
                      >
                          <i class="js-icon-cug js-cug-man-and-star"></i>
                          <span>${i18next.t("Pasajeros frecuentes")}</span>
                          <i class="js-icon js-circle-chevron-right"></i>
                      </div>
                  </div>
              `
            : "";

    const headerTemplate = () => html`
        <header class="flex-col justify-between sm:flex-row" data-test-id=${T.PASSENGERS.DETAILS_PAGE_HEADER}>
            <div class="mb-2 flex items-center md:mb-4 lg:mb-8">
                <span class="js-circle-user js-icon title-icon"></span>
                <div class="title">
                    <h2 class="main-title" data-test-id=${T.PASSENGERS.DETAILS_PAGE_HEADER_MAIN_TITLE}>
                        ${i18next.t("V2-PassengerDetailsTitle")}
                    </h2>
                    <div class="subtitle" data-test-id=${T.PASSENGERS.DETAILS_PAGE_HEADER_SUBTITLE}>
                        ${i18next.t("V2-PassengerDetailsInfo")}
                    </div>
                </div>
            </div>
            ${travelPartnersButtonTemplate()}
        </header>
    `;

    const nameErrorTemplate = () =>
        showNameUniquenessError
            ? html`
                  <div
                      ref=${ref(nameError)}
                      class="error-wrapper condensed"
                      data-test-id=${T.PASSENGERS.DUPLICATE_NAME_ERROR}
                  >
                      <h4 class="alert-heading">${i18next.t("ErrorOccured")}</h4>
                      ${i18next.t("DuplicatePassengerName")}
                  </div>
              `
            : "";

    const fareLockInfoTemplate = () =>
        flowContext.isFarelockRoundTwo
            ? html`
                  <section
                      class="booking-wrapper fare-lock-pax-info-emphasis"
                      data-test-id=${T.PASSENGERS.PAYING_FARELOCK_INFO}
                  >
                      <header>
                          <div class="title">
                              <h2 class="main-title">${i18next.t("V2-FareLockPaxInfoTitle")}</h2>
                              <div class="subtitle condensed">${i18next.t("V2-FareLockPaxInfo")}</div>
                          </div>
                      </header>
                  </section>
              `
            : "";

    const addTravelPartnersModalHeaderTemplate = () => html`
        <div class="cug2b-travel-partners-to-booking-modal-header">
            <i class="js-icon-cug js-cug-man-and-star"></i>
            <div>
                ${unsafeHTML(
                    i18next.t("{{-start}}Tus pasajeros {{-break}} frecuentes{{-end}}", {
                        start: "<h1>",
                        break: "</h1><h2>",
                        end: "</h2>",
                    }),
                )}
            </div>
        </div>
    `;

    const addTravelPartnersModalContentTemplate = () => html`
        <ac-add-travel-partners-to-booking
            .adultPaxCount=${adults ? (isFirstPaxProvided() ? adults.length - 1 : adults.length) : 0}
            .travelPartnerInfo=${travelPartnerInfo}
            .onClose=${() => setAddTravelPartnersModalOpen(false)}
            @submit=${handleAddTravelPartners}
        ></ac-add-travel-partners-to-booking>
    `;

    const travelPartnersModal = useAddTravelPartnersToBookingModal({
        isOpen: addTravelPartnersModalOpen,
        modalContentTemplate: addTravelPartnersModalContentTemplate(),
        modalHeaderTemplate: addTravelPartnersModalHeaderTemplate(),
        onClose: () => setAddTravelPartnersModalOpen(false),
    });

    const buttonsTemplate = () => html`
        <button
            class=${classNames("rounded-primary-btn booking", {
                disabled: isSubmitDisabled || activeSection !== "Passengers",
            })}
            data-test-id=${T.PASSENGERS.SUBMIT_BUTTON}
            @click=${handleSubmitClick}
        >
            ${flowContext.isFarelockRoundOne ? i18next.t("Pagar ahora") : i18next.t("Continuar")}
        </button>
    `;

    const discountFormErrorTemplate = () =>
        isDiscountValidated && Object.keys(discountValidator.messages).length
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("V2-PleaseFillAllFields")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    // EXPORTS

    const build = async (): Promise<GetBuildPageResult> => {
        const extrasModel = await ajaxJsonRequest<ApiExtrasSpaSectionViewModel>({
            method: "GET",
            url: ROUTES.AjaxExtrasModel,
            nonCancellable: true,
        });

        setNeedsDocumentsForInsurance(
            extrasModel.data.ExtrasModel.HasInsuranceFeeInBooking || extrasModel.data.ExtrasModel.HasUnpaidInsuranceFee,
        );

        const spaSectionResult = await ajaxJsonRequest<ApiSpaPassengersViewModel>({
            url: ROUTES.AjaxPassengersModel,
            method: "GET",
        });

        if (spaSectionResult.redirectionUrl) {
            return { type: "redirect", redirectionUrl: spaSectionResult.redirectionUrl };
        }

        const model = mapToPassengersPageModel(spaSectionResult.data);

        setPageModel(model);

        const passengersResult = await ajaxJsonRequest<ApiNewPassengersModel>({
            url: ROUTES.ApiRoutes.GetAllPassengers,
            method: "GET",
        });

        if (passengersResult.redirectionUrl) {
            return { type: "redirect", redirectionUrl: passengersResult.redirectionUrl };
        }

        documentHandler.init(passengersResult.data.Passengers);
        frequentFlyerNumberHandler.init(passengersResult.data.Passengers);

        const adults = mapToNonInfants({
            checkedInPassengerIndices: model.checkedInPaxIndexes,
            culture: appContext.Culture,
            defaultCountry: defaultCountry(passengersResult.data, "ADT"),
            defaultPhonePrefix: appContext.PhonePrefixes.find((prefix) => prefix.CountryCode === appContext.Country)
                ?.Code,
            isBancoEstado: bookingContext.isBancoEstadoBooking || userContext.bancoEstado.category !== 0,
            isBookingFlow: flowContext.isBookingFlow,
            isDc: bookingContext.isDiscountBooking || model.wantsDiscount || userContext?.dc.hasMembership,
            isFarelockRoundTwo: flowContext.isFarelockRoundTwo,
            isInternationalFlightToColombia: isJourneyInternationalToColombia(bookingContext),
            isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
            passengersModel: passengersResult.data,
            filterType: "ADT",
            userContext,
        });

        setAdults(adults);
        setChildren(
            mapToNonInfants({
                checkedInPassengerIndices: model.checkedInPaxIndexes,
                culture: appContext.Culture,
                defaultCountry: defaultCountry(passengersResult.data, "CHD"),
                defaultPhonePrefix: appContext.PhonePrefixes.find((prefix) => prefix.CountryCode === appContext.Country)
                    ?.Code,
                isBancoEstado: bookingContext.isBancoEstadoBooking || userContext.bancoEstado.category !== 0,
                isBookingFlow: flowContext.isBookingFlow,
                isDc: bookingContext.isDiscountBooking || model.wantsDiscount || userContext?.dc.hasMembership,
                isFarelockRoundTwo: flowContext.isFarelockRoundTwo,
                isInternationalFlightToColombia: isJourneyInternationalToColombia(bookingContext),
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                passengersModel: passengersResult.data,
                filterType: "CHD",
                userContext,
            }),
        );
        setInfants(
            mapToInfants({
                checkedInPassengerIndices: model.checkedInPaxIndexes,
                culture: appContext.Culture,
                defaultCountry: defaultCountry(passengersResult.data, "INF"),
                infantsCount: bookingContext.infantsCount,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                passengersModel: passengersResult.data,
                userContext,
                flowContext,
            }),
        );

        setDiscountCode(passengersResult.data.Passengers.find((p) => p.PaxDiscountCode)?.PaxDiscountCode);

        setIAmTravelling(adults[0].firstName === model.memberFirstName && adults[0].lastName === model.memberLastName);

        setOriginalFirstPassenger(adults[0]);

        if (userContext.cug.isMember) {
            const result = await getTravelPartnerInfo();
            setTravelPartnerInfo(result);
        }

        window.setTimeout(() => {
            iAmNotTravellingCheckbox.update();
            iAmTravellingCheckbox.update();
        }, 250);

        return { type: "success" };
    };

    const htmlTemplate = () => html`
        <section ref=${ref(root)} class="booking-wrapper ts-error-container">
            ${fareLockInfoTemplate()} ${employeeDiscountSelectorTemplate()} ${nameErrorTemplate()} ${headerTemplate()}
            <div ref=${ref(formElement)} class="inner-deep-box flex flex-col gap-6">
                ${staffMemberTravellingTemplate()} ${passengerAccordionsTemplate()}
            </div>
            ${formErrors.htmlTemplate()}
        </section>
        ${discountFormErrorTemplate()}
        <div class="flex w-full justify-end">${buttonsTemplate()}</div>
        ${travelPartnersModal.htmlTemplate()} ${familyMemberErrorModal.htmlTemplate()}
    `;

    return { build, htmlTemplate, passengersModel: pageModel };
};
