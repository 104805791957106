import { useCompactDatepicker } from "./../ui/useCompactDatepicker/useCompactDatepicker";
import {
    ARGENTINA_COUNTRY_CODE,
    CHILE_COUNTRY_CODE,
    COLOMBIA_COUNTRY_CODE,
    DEFAULT_DATE_FORMAT,
    URUGUAY_COUNTRY_CODE,
} from "./../../shared/commonConstants";
import { useEffect, useMemo } from "./../../shared/haunted/CustomHooks";
import i18next from "i18next";
import {
    Currency,
    getTaxnumberCodeByCountry,
    handleCugLoader,
    sanitizeRutFieldValue,
    SelectOption,
} from "./../../shared/common";
import { html, useRef } from "haunted";
import { HauntedFunc } from "./../../shared/haunted/HooksHelpers";
import { CommonRegion } from "../../shared/common";
import { ChangeEvent } from "../../dc-components/dc-checkbox";
import { useState } from "../../shared/haunted/CustomHooks";
import { validateRut } from "../../shared/form-validation";
import { sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { classMap } from "lit-html/directives/class-map";
import dayjs, { Dayjs } from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useFluentValidator } from "../../validator/FluentValidator";
import { Validation } from "../../validator/Validation";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import { useAvailabilityValidator } from "../../validator/useAvailabilityValidator";
import { ROUTES } from "../../shared/apiRoutes";
import { ref } from "../../directives/ref";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
dayjs.extend(CustomParseFormat);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);

export const name = "ac-cug-register-company";

const rootClassName = "ac-cug-register-company-root";

export interface Properties {
    currencies: { [countryCode: string]: Currency[] };
    regions: CommonRegion[];
    vm: OrganizationVM;
    goBack: () => void;
    goForward: () => void;
    setVm: (newState: OrganizationVM) => void;
}

export type OrganizationType = "" | "AGENCY" | "COMPANY";

export interface OrganizationVM {
    address: string;
    city: string;
    countryCode: string;
    currencyCode: string;
    email: string;
    file: any;
    giro: string;
    name: string;
    region: string;
    rntExpirationDate: Dayjs;
    taxNumber: string;
    type: OrganizationType;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        currencies: host.currencies,
        regions: host.regions,
        vm: host.vm,
        goBack: host.goBack,
        goForward: host.goForward,
        setVm: host.setVm,
    };

    const init = () => {
        if (window.location.href.indexOf("agency=1") > -1) props.setVm({ ...props.vm, type: "AGENCY" });
        if (window.location.href.indexOf("company=1") > -1) props.setVm({ ...props.vm, type: "COMPANY" });
    };

    const getCurrenciesForCountry = (countryCode: string) => {
        return props.currencies[countryCode] ? props.currencies[countryCode] : props.currencies.Default;
    };

    const initialCurrencies = () => getCurrenciesForCountry(props.vm.countryCode);

    const getRegionsForCountry = (countryCode: string) =>
        props.regions.filter((region) => region.CountryCode === countryCode).map((region) => region.RegionName);

    const initialRegions = () => getRegionsForCountry(props.vm.countryCode);

    const validateCompanyName = async (name: string) => {
        if (companyName.current?.contains(document.activeElement)) return true;

        const loaderName = `loadData-${Date.now()}`;
        handleCugLoader(document.body.querySelector(`.${rootClassName}`) as HTMLDivElement, loaderName);
        const result = await companyNameValidator.validate(name);
        handleCugLoader(document.body.querySelector(`.${rootClassName}`) as HTMLDivElement, loaderName);
        return result;
    };

    const sanitizeRut = (e: KeyboardEvent) => {
        const field = e.target as HTMLInputElement;
        switch (props.vm.countryCode) {
            case CHILE_COUNTRY_CODE:
                return sanitizeRutFieldValue(field);
            case URUGUAY_COUNTRY_CODE:
                const value = field.value;
                field.value = value.substring(0, 12);
                setShowUruguayRutError(value.length > 12);
                return value.substring(0, 12);
        }

        return sanitizeInputFieldValue(e, "travel-document-id");
    };

    // EVENT HANDLERS

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        await validator.validateAndDispatch();
    };

    const handleCountryChange = (value: string) => {
        props.setVm({
            ...props.vm,
            countryCode: value,
            taxNumber: "",
            city: "",
            region: "",
            address: "",
        });
        setShowUruguayRutError(false);
        setRegionsForCountry(getRegionsForCountry(value));
        setCurrenciesForCountry(getCurrenciesForCountry(value));
    };

    const handleBillingConsumerSelect = () => setIsDummyCheckboxChecked(false);

    const handleBillingCompanySelect = () => setIsDummyCheckboxChecked(true);

    const handleAgencySelect = (e: ChangeEvent) => props.setVm({ ...props.vm, type: e.detail.checked ? "AGENCY" : "" });

    const handleCompanySelect = (e: ChangeEvent) =>
        props.setVm({ ...props.vm, type: e.detail.checked ? "COMPANY" : "" });

    const handleNameInput = (value: string) => props.setVm({ ...props.vm, name: value });

    const handleEmailInput = (value: string) => props.setVm({ ...props.vm, email: value });

    const handleRutInput = (value: string) => props.setVm({ ...props.vm, taxNumber: value });

    const handleGiroInput = (value: string) => props.setVm({ ...props.vm, giro: value });

    const handleCityInput = (value: string) => props.setVm({ ...props.vm, city: value });

    const handleAddressInput = (value: string) => props.setVm({ ...props.vm, address: value });

    const handleRegionInput = (value: string) => props.setVm({ ...props.vm, region: value });

    const handleCurrencySelect = (value: string) => props.setVm({ ...props.vm, currencyCode: value });

    const handleNewExpirationDate = (newDate: Dayjs) =>
        props.setVm({ ...props.vm, rntExpirationDate: newDate ? dayjs(newDate) : undefined });

    const handleFileChange = (e: Event) => {
        setIsDragAndDropHighlighted(false);

        const value = (e.target as HTMLInputElement).files[0];
        props.setVm({ ...props.vm, file: value });
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragAndDropHighlighted(false);

        const value = e.dataTransfer.files[0];
        props.setVm({ ...props.vm, file: value });
    };

    const handleDragenter = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragAndDropHighlighted(true);
    };

    const handleDragleave = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragAndDropHighlighted(false);
    };

    // DEVNOTE This is necessary
    const handleDragover = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClick = () => {
        const input = document.querySelector("input[type=file]") as HTMLInputElement;
        input.click();
    };

    // COMPONENT

    const cug2AppContext = useCug2AppContext();

    const companyName = useRef<HTMLInputElement>(null);

    const [currenciesForCountry, setCurrenciesForCountry] = useState<Currency[]>(initialCurrencies());
    const [isDragAndDropHighlighted, setIsDragAndDropHighlighted] = useState<boolean>(undefined);
    const [isValidated, setIsValidated] = useState<boolean>(undefined);
    const [regionsForCountry, setRegionsForCountry] = useState<string[]>(initialRegions());
    const [showUruguayRutError, setShowUruguayRutError] = useState<boolean>(undefined);
    const [isDummyCheckboxChecked, setIsDummyCheckboxChecked] = useState<boolean>(false);

    const rntTooltipTemplate = () =>
        html`<div>
            <img src="/Images/Cug22/rnt-tooltip.png" />
            <span class="mt-2">${i18next.t("rnt-verification-message")}</span>
        </div> `;

    const expiryDate = useCompactDatepicker({
        firstYearIsDefault: true,
        minDate: dayjs(),
        maxDate: dayjs().add(15, "year"),
        label: i18next.t("Fecha de expiración RNT"),
        value: props.vm.rntExpirationDate ? dayjs(props.vm.rntExpirationDate, DEFAULT_DATE_FORMAT) : undefined,
        onChange: handleNewExpirationDate,
    });

    const companyNameValidator = useAvailabilityValidator<{}, { IsOrganizationExist: boolean }>({
        method: "GET",
        responseKey: "IsOrganizationExist",
        url: `${ROUTES.ApiRoutes.CheckOrganizationName}?organizationName=${encodeURIComponent(props.vm.name)}`,
    });

    const validator = useFluentValidator<keyof OrganizationVM, OrganizationVM>({
        vm: props.vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("file", (vm: OrganizationVM) => vm.file)
                .when((vm: OrganizationVM) => vm.countryCode === COLOMBIA_COUNTRY_CODE && vm.type === "AGENCY")
                .isRequired(i18next.t("El documento adjunto no cumple con el formato solicitado (PDF)"), "field")
                .fulfils(
                    (file: any) => Promise.resolve(file && file.type === "application/pdf"),
                    i18next.t("El documento adjunto no cumple con el formato solicitado (PDF)"),
                )
                .fulfils(
                    (file: any) => Promise.resolve(file && file.size <= 1048576),
                    i18next.t(
                        "El archivo adjunto pesa más de lo permitido (peso máximo 1MB), por favor intenta nuevamente.",
                    ),
                ),
            Validation.ruleFor("rntExpirationDate", (vm: OrganizationVM) => vm.rntExpirationDate)
                .when((vm: OrganizationVM) => vm.countryCode === COLOMBIA_COUNTRY_CODE && vm.type === "AGENCY")
                .isRequired(),
            Validation.ruleFor("type", (vm: OrganizationVM) => vm.type).isRequired(),
            Validation.ruleFor("countryCode", (vm: OrganizationVM) => vm.countryCode).isRequired(),
            Validation.ruleFor("name", (vm: OrganizationVM) => vm.name)
                .isRequired()
                .max(32)
                .fulfils((value) => validateCompanyName(value), i18next.t("Esta empresa ya está registrada.")),
            Validation.ruleFor("taxNumber", (vm: OrganizationVM) => vm.taxNumber)
                .isRequired()
                .max(52),
            Validation.ruleFor("taxNumber", (vm: OrganizationVM) => vm.taxNumber)
                .when((vm: OrganizationVM) => vm.countryCode === CHILE_COUNTRY_CODE)
                .fulfils(
                    (value) => Promise.resolve(validateRut(value)),
                    i18next.t(
                        "Rut inválido, por favor verifica que el rut es correcto y que el formato sea válido. (Ej: 76472472-0)",
                    ),
                ),
            Validation.ruleFor("giro", (vm: OrganizationVM) => vm.giro)
                .isRequired()
                .max(64),
            Validation.ruleFor("city", (vm: OrganizationVM) => vm.city)
                .isRequired()
                .max(32),
            Validation.ruleFor("region", (vm: OrganizationVM) => vm.region)
                .isRequired()
                .max(2048),
            Validation.ruleFor("address", (vm: OrganizationVM) => vm.address)
                .isRequired()
                .max(52),
            Validation.ruleFor("currencyCode", (vm: OrganizationVM) => vm.currencyCode).isRequired(),
            Validation.ruleFor("email", (vm: OrganizationVM) => vm.email)
                .isRequired()
                .max(266)
                .isEmail(),
        ],
        dispatcher: () => {
            props.goForward();
        },
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const countryOptions = useMemo(
        () => [
            { Value: "", Text: "", IsSelected: !props.vm.countryCode },
            ...cug2AppContext.Countries.map(
                (c): SelectOption => ({
                    IsSelected: c.Value === props.vm.countryCode,
                    Text: c.Text,
                    Value: c.Value,
                }),
            ),
        ],
        [cug2AppContext.Countries, props.vm.countryCode],
    );

    const regionOptions = useMemo(
        () => [
            { Value: "", Text: "", IsSelected: !props.vm.region },
            ...regionsForCountry.map(
                (region): SelectOption => ({
                    IsSelected: region === props.vm.region,
                    Text: region,
                    Value: region,
                }),
            ),
        ],
        [regionsForCountry, props.vm.region],
    );

    const currencyOptions = useMemo(
        () =>
            currenciesForCountry
                ? [
                      { Value: "", Text: "", IsSelected: !props.vm.currencyCode },
                      ...currenciesForCountry.map(
                          (currency): SelectOption => ({
                              IsSelected: currency.CurrencyCode === props.vm.currencyCode,
                              Text: currency.Description,
                              Value: currency.CurrencyCode,
                          }),
                      ),
                  ]
                : [],
        [currenciesForCountry, props.vm.currencyCode],
    );

    useEffect(init, []);

    // TEMPLATES

    const emailTemplate = () => html`
        <div class="group relative mt-[5px] w-full sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("email")}
                .isInvalid=${!validator.isValid("email")}
                .label=${i18next.t("Correo electrónico activación / facturación")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "e-mail")}
                .testId=${T.CUG2_REGISTER.INVOICE_EMAIL}
                .value=${props.vm.email}
                .onInput=${(email: string) => handleEmailInput(email)}
            ></ac-input>

            <div class="email-tooltip group-focus-within:block">
                <div class="arrow-box">
                    ${i18next.t(
                        "Ingresa el correo electrónico donde quieres recibir las facturas emitidas. Además, recibirás aquí el Email avisando la activación de la empresa.",
                    )}
                </div>
            </div>
        </div>
    `;

    const typeTemplate = () => html`
        <div class="flex flex-col sm:w-3/4 sm:flex-row sm:items-center sm:justify-between xl:w-1/2">
            <span class="cug2b-register-checkbox-title">${i18next.t("Mi organización es")}</span>
            <div class="my-2 flex items-center whitespace-nowrap sm:my-0">
                <dc-checkbox
                    .checked=${props.vm.type === "AGENCY"}
                    .dataTestId=${T.CUG2_REGISTER.AGENCY_CHECKBOX}
                    .label=${i18next.t("Agencia de viajes")}
                    @change=${handleAgencySelect}
                ></dc-checkbox>
            </div>
            <div class="my-2 flex items-center whitespace-nowrap sm:my-0">
                <dc-checkbox
                    .label=${i18next.t("Empresas")}
                    .checked=${props.vm.type === "COMPANY"}
                    .dataTestId=${T.CUG2_REGISTER.COMPANY_CHECKBOX}
                    @change=${handleCompanySelect}
                ></dc-checkbox>
            </div>
        </div>
        ${selectionErrorTemplate()}
    `;

    const selectionErrorTemplate = () =>
        !validator.isValid("type")
            ? html`
                  <div class="error-message-container">
                      <span>${i18next.t("Por favor seleccione una opción")}</span>
                  </div>
              `
            : "";

    const nameTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/3" ref=${ref(companyName)}>
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("name")}
                .isInvalid=${!validator.isValid("name")}
                .label=${i18next.t("Razón Social")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "no-special-character")}
                .testId=${T.CUG2_REGISTER.BUSINESS_NAME}
                .value=${props.vm.name}
                .onInput=${(name: string) => handleNameInput(name)}
            ></ac-input>
        </div>
    `;

    const countryTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/3">
            <ac-select
                .errorMessage=${validator.getMessage("countryCode")}
                .isInvalid=${!validator.isValid("countryCode")}
                .label=${i18next.t("País")}
                .options=${countryOptions}
                .testId=${T.CUG2_REGISTER.COUNTRY}
                .onSelect=${(value: string) => handleCountryChange(value)}
            ></ac-select>
        </div>
    `;

    const taxNumberTemplate = () => html`
        <div class="mt-[5px] w-full sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("taxNumber")}
                .isInvalid=${!validator.isValid("taxNumber")}
                .label=${getTaxnumberCodeByCountry(props.vm.countryCode)}
                .sanitizer=${(e: KeyboardEvent) => sanitizeRut(e)}
                .testId=${T.CUG2_REGISTER.TAX_NUMBER}
                .value=${props.vm.taxNumber}
                .onInput=${(value: string) => handleRutInput(value)}
            ></ac-input>
            ${uruguayRutErrorTemplate()}
        </div>
    `;

    const uruguayRutErrorTemplate = () =>
        showUruguayRutError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">
                                  ${i18next.t("La longitud máxima es de 12 caracteres.")}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const giroTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/2">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("giro")}
                .isInvalid=${!validator.isValid("giro")}
                .maxLength=${64}
                .label=${i18next.t("Giro / Giro Comercial / Rubro")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "alphanumeric")}
                .testId=${T.CUG2_REGISTER.BUSINESS_TYPE}
                .value=${props.vm.giro}
                .onInput=${(value: string) => handleGiroInput(value)}
            ></ac-input>
        </div>
    `;

    const cityTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("city")}
                .isInvalid=${!validator.isValid("city")}
                .label=${i18next.t("Ciudad")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "city")}
                .testId=${T.CUG2_REGISTER.CITY}
                .value=${props.vm.city}
                .onInput=${(value: string) => handleCityInput(value)}
            ></ac-input>
        </div>
    `;

    const textFieldForRegionTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("region")}
                .isInvalid=${!validator.isValid("region")}
                .label=${i18next.t("Comuna / Distrito / Provincia")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "city")}
                .testId=${T.CUG2_REGISTER.REGION}
                .value=${props.vm.region}
                .onInput=${(value: string) => handleRegionInput(value)}
            ></ac-input>
        </div>
    `;

    const dropdownForRegionTemplate = () => html`
        <div class="mt-[5px] w-full sm:mr-4 sm:w-1/3">
            <ac-select
                .errorMessage=${validator.getMessage("region")}
                .isInvalid=${!validator.isValid("region")}
                .label=${i18next.t("Comuna / Distrito / Provincia")}
                .options=${regionOptions}
                .testId=${T.CUG2_REGISTER.REGION}
                .onSelect=${(value: string) => handleRegionInput(value)}
            ></ac-select>
        </div>
    `;

    const regionTemplate = () =>
        regionsForCountry.length === 0 ? textFieldForRegionTemplate() : dropdownForRegionTemplate();

    const addressTemplate = () => html`
        <div class="mt-[5px] w-full sm:w-1/3">
            <ac-input
                .autoComplete=${"cc-exp"}
                .errorMessage=${validator.getMessage("address")}
                .isInvalid=${!validator.isValid("address")}
                .label=${i18next.t("Dirección")}
                .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "address")}
                .testId=${T.CUG2_REGISTER.ADDRESS}
                .value=${props.vm.address}
                .onInput=${(value: string) => handleAddressInput(value)}
            ></ac-input>
        </div>
    `;

    const currencyTemplate = () => html`
        <div class="relative mt-[5px] w-full sm:mr-4 sm:w-[calc(33.3333%-0.5rem)]">
            <ac-select
                .errorMessage=${validator.getMessage("currencyCode")}
                .isInvalid=${!validator.isValid("currencyCode")}
                .label=${i18next.t("Moneda")}
                .options=${currencyOptions}
                .testId=${T.CUG2_REGISTER.CURRENCY}
                .onSelect=${(value: string) => handleCurrencySelect(value)}
            ></ac-select>
            <div class="cug-info-icon cug-register">
                <ac-tooltip
                    .icon=${"?"}
                    .tooltip=${i18next.t("Moneda en la que será creada la cuenta de prepago.")}
                ></ac-tooltip>
            </div>
        </div>
    `;

    const billingTemplate = () =>
        props.vm.countryCode === ARGENTINA_COUNTRY_CODE
            ? html`
                  <div class="mb-0 md:mb-4">
                      <div
                          class="flex flex-col sm:w-full sm:flex-row sm:items-center sm:justify-between lg:w-3/4 xl:w-1/2"
                      >
                          <span class="cug2b-register-checkbox-title">${i18next.t("Facturación")}</span>
                          <div class="my-2 flex items-center whitespace-nowrap sm:my-0">
                              <dc-checkbox
                                  .label=${i18next.t("Consumidor final")}
                                  .checked=${!isDummyCheckboxChecked}
                                  @change=${handleBillingConsumerSelect}
                              ></dc-checkbox>
                          </div>
                          <div class="my-2 flex items-center whitespace-nowrap sm:my-0">
                              <dc-checkbox
                                  .label=${i18next.t("Constancia de crédito fiscal")}
                                  .checked=${isDummyCheckboxChecked}
                                  @change=${handleBillingCompanySelect}
                              ></dc-checkbox>
                              <div class="ml-4 cursor-pointer text-brand-secondary">
                                  <ac-tooltip
                                      .icon=${"?"}
                                      .tooltip=${i18next.t(
                                          "Jetsmart Airlines S.A. se encuentra exceptuada de cumplir con el Régimen de Facturación establecido en la RG 1415, dado que estamos comprendidos en el Anexo I, inciso e) de la mencionada Resolución. Si Usted adquirió, un pasaje internacional o pasaje de cabotaje con origen y/o destino Tierra del Fuego, el ticket o el recibo remitido por mail, es documento válido, asimilable a una factura. Si Usted adquirió un pasaje de cabotaje, el cual se encuentra gravado por IVA, deberá solicitar la “Constancia de Crédito Fiscal”, según lo establecido en el Anexo IV, punto B), inciso 4., de la RG 1415. Deberá solicitar una constancia por cada documento emitido completando el siguiente formulario. La Constancia le será enviada a su dirección de correo electrónico al final del mes en curso. Por favor ingrese los datos sin utilizar acentos.",
                                      )}
                                  ></ac-tooltip>
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const buttonsTemplate = () => html`
        <div class="cug2b-register-btn-container">
            <div class="cug2b-register-back-btn" @click=${props.goBack}>
                <i class="js-icon js-circle-chevron-right"></i>
                <span>${i18next.t("Volver")}</span>
            </div>
            <button class="rounded-primary-btn" data-test-id=${T.CUG2_REGISTER.CONTINUE_BUTTON} @click=${handleSubmit}>
                ${i18next.t("Continuar")}
            </button>
        </div>
    `;

    const headerTemplate = () => html`
        <div class="cug2b-register-header" data-test-id=${T.CUG2_REGISTER.HEADER}>
            <i class="js-icon-cug js-cug-man-and-plane"></i>
            <div>
                <h1>
                    ${props.vm.type === "AGENCY" ? i18next.t("Datos de la agencia") : i18next.t("Datos de la empresa")}
                </h1>
                <h2>${i18next.t("Estos datos se utilizarán para la emisión de facturas.")}</h2>
            </div>
        </div>
    `;

    const rntExpiryTemplate = () =>
        props.vm.countryCode === COLOMBIA_COUNTRY_CODE && props.vm.type === "AGENCY"
            ? html`
                  <div class="relative w-full sm:mr-4 sm:w-[calc(33.3333%-0.5rem)]">
                      ${expiryDate.htmlTemplate()}
                      <div class="absolute right-2 -mt-px vertical-center">
                          <ac-tooltip
                              .icon=${"?"}
                              .tooltipCustomClass=${"rnt-tooltip"}
                              .tooltip=${rntTooltipTemplate()}
                          ></ac-tooltip>
                      </div>
                  </div>
              `
            : "";

    const fileErrorTemplate = () =>
        validator.getMessage("file")
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message ts-rut-error-message">${validator.getMessage("file")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const fileUploadTemplate = () =>
        props.vm.countryCode === COLOMBIA_COUNTRY_CODE && props.vm.type === "AGENCY"
            ? html`
                  <div
                      class=${classMap({
                          "drag-file": true,
                          "highlighted": isDragAndDropHighlighted,
                          "has-file": props.vm?.file,
                      })}
                      @click=${handleClick}
                      @dragenter=${handleDragenter}
                      @dragleave=${handleDragleave}
                      @dragover=${handleDragover}
                      @drop=${handleDrop}
                  >
                      <div>${props.vm?.file?.name}</div>
                      <input type="file" @change=${handleFileChange} />
                      <div class="drag-file-label">
                          ${i18next.t(
                              "Adjunta aquí el certificado RNT, recuerda que deber ser un archivo .PDF y no puede pesar más de 1MB.",
                          )}
                      </div>
                  </div>
                  ${fileErrorTemplate()}
              `
            : "";

    return html`
        <div class=${rootClassName} data-test-id=${T.CUG2_REGISTER.CONTAINER}>
            ${headerTemplate()}
            <div class="cug2b-register-form" data-test-id=${T.CUG2_REGISTER.FORM}>
                <form>
                    <div class="mb-0 md:mb-4">${typeTemplate()}</div>
                    <div class="mb-0 sm:flex md:mb-4">
                        ${nameTemplate()} ${countryTemplate()} ${taxNumberTemplate()}
                    </div>
                    <div class="mb-0 sm:flex md:mb-4">${giroTemplate()} ${emailTemplate()}</div>
                    <div class="mb-0 sm:flex md:mb-4">${cityTemplate()} ${regionTemplate()} ${addressTemplate()}</div>
                    <div class="mb-0 sm:flex md:mb-4">${currencyTemplate()} ${rntExpiryTemplate()}</div>
                    ${billingTemplate()} ${fileUploadTemplate()}
                </form>
                <div>${formErrors.htmlTemplate()}</div>
                ${buttonsTemplate()}
            </div>
        </div>
    `;
};
