import { ApiVoucherWithAmount } from "../../component-models/payment/ApiVoucherWithAmount";
import i18next from "i18next";
import { hideLoader, maskCurrenciesForDisplay, showLoader } from "../../shared/common";
import { useMemo, useState } from "../../shared/haunted/CustomHooks";
import { html, useEffect, useRef } from "haunted";
import { ref } from "../../directives/ref";
import { useVoucherForWebAnonymous } from "./useVoucherForWebAnonymous";
import { useGiftcard } from "./useGiftcard";
import {
    PERUVIAN_SOL_CODE,
    VOUCHER_AVAILABLE,
    VOUCHER_EXPIRED,
    VOUCHER_INVALID,
    VOUCHER_PAYMENT_METHOD_CODE,
    VOUCHER_PAYMENT_METHOD_TYPE,
    VOUCHER_USED,
} from "../../shared/commonConstants";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import { ROUTES } from "../../shared/apiRoutes";
import { useVoucherForMembers } from "./useVoucherForMembers";
import { ApiVoucherResult } from "../../component-models/payment/VoucherResult";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { PaymentMode } from "../../component-models/payment/PaymentMode";
import { mapToVoucherResult } from "../../component-mappers/PaymentMappers";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { usePaymentCancelsPromoCodeModal } from "./usePaymentCancelsPromoCodeModal";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    model: PaymentPageViewModel;
    paymentMode: PaymentMode;
    getVoucherInfo: (result: ApiVoucherResult) => Promise<boolean>;
    payWithGiftcardThatCoversBalance: () => void;
    payWithVoucherLessThanBalance: (voucherResult: ApiVoucherResult) => void;
    payWithVoucherThatCoversBalance: () => void;
}

export const useVoucher = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { ajaxRequest, ajaxJsonRequest, checkPromoCodeAvailable } = useAjax();

    const [voucherResult, setVoucherResult] = useReduxState("payment.voucherResult");
    const [isWaitingForVoucherResult, setIsWaitingForVoucherResult] = useState(false);
    const [isWaitingForGiftcardResult, setIsWaitingForGiftcardResult] = useState(false);
    const [_, setSelectedMethod] = useReduxState("payment.paymentMethod");

    const [userContext] = useReduxState("userContext");
    const [currency] = useReduxState("booking.currency");

    const root = useRef<HTMLDivElement>(undefined);

    const [showTypeError, setShowTypeError] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [showGiftCardSelector, setShowGiftcardSelector] = useState<boolean>(undefined);

    const voucherForWebAnonymous = useVoucherForWebAnonymous({
        isButtonDisabled,
        model: props.model,
        handleGiftcardVsPromoCodeModal: () => handleGiftcardVsPromoCodeModal(),
        handleKeydown: (e) => handleKeydown(e),
        handleVoucherClick: (code) => handleVoucherClick(code),
    });

    const voucherForMembers = useVoucherForMembers({
        isButtonDisabled,
        model: props.model,
        handleKeydown: (e) => handleKeydown(e),
        handleVoucherClick: (code) => handleVoucherClick(code),
        setShowGiftcardSelector,
    });

    const giftcard = useGiftcard({
        model: props.model,
        handleGiftcardClick: (e, voucher) => handleGiftcardClick(e, voucher),
        nonPromoCodeCallback: () => nonPromoCodeCallback(),
        setShowGiftcardSelector,
    });

    const blockPromoCodeModal = usePaymentCancelsPromoCodeModal({
        body: i18next.t(
            "It’s not possible to use your promotional code and a gift card for the same booking. Please select the option you want to use:",
        ),
        nonPromoCodeBtnText: i18next.t("Gift Card"),
        promoCodeBtnText: i18next.t("Promo code"),
        nonPromoCodeCallback: () => nonPromoCodeCallback(),
        promoCodeCallback: voucherForWebAnonymous.onPromoCodeSelection,
    });

    const isPeruCompra = useMemo(
        () => userContext.peruCompra.isAdmin || userContext.peruCompra.isMember || bookingContext.isPeruCompraBooking,
        [userContext, bookingContext],
    );

    const scrollToVoucherMessage = () => {
        if (showInvalidVoucherMessage()) ScrollHelper.scrollToElementAndHideNav({ element: root.current });
    };

    const canUsePromoCode = async () => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.Generic, container: root.current?.parentElement });
        const canUse = await checkPromoCodeAvailable(VOUCHER_PAYMENT_METHOD_CODE, false, true);
        hideLoader(loader);

        return canUse;
    };

    const handleGiftcardVsPromoCodeModal = async () => {
        const canUse = await canUsePromoCode();

        if (canUse) return;

        blockPromoCodeModal.open();
    };

    const checkAgainstFarelock = async (code: string) => {
        if (!flowContext.isFarelockRoundOne) return true;

        const canVoucherBeUsed = await checkVoucherForFarelock(code);

        if (!canVoucherBeUsed) {
            setShowTypeError(true);
            setVoucherResult(undefined);
        }

        return canVoucherBeUsed;
    };

    const getVoucherData = async (code: string) => {
        try {
            const result = await ajaxJsonRequest<ApiVoucherResult>({
                container: root.current.parentElement,
                method: "GET",
                url: `${ROUTES.SubmitVoucher}?voucherCode=${code}`,
                onResponseCode: {
                    400: handleInvalidVoucher,
                    500: handleInvalidVoucher,
                },
            });

            return result.data;
        } catch (e) {
            return undefined;
        }
    };

    const nonPromoCodeCallback = async () => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.Generic, container: root.current?.parentElement });
        checkPromoCodeAvailable(VOUCHER_PAYMENT_METHOD_CODE, true, true);
        hideLoader(loader);
    };

    const checkVoucherForFarelock = async (code: string) => {
        try {
            const response = await ajaxJsonRequest<{ canBeUsed: boolean }>({
                container: root.current.parentElement,
                method: "GET",
                url: `${ROUTES.VoucherAvailability}?voucherCode=${code}`,
                onResponseCode: {
                    500: handleInvalidVoucher,
                },
            });

            return response.data.canBeUsed;
        } catch (e) {
            return undefined;
        }
    };

    const handleInvalidVoucher = () => {
        setVoucherResult({
            accountNumber: "",
            paymentMethodCode: "",
            paymentMethodType: VOUCHER_PAYMENT_METHOD_TYPE,
            quotedAmount: "",
            unformattedQuotedAmount: 0,
            voucherAmount: "",
            voucherStatus: VOUCHER_INVALID,
            voucherUnformattedAmount: 0,
        });

        setIsButtonDisabled(false);
    };

    const showInvalidVoucherMessage = () =>
        !showTypeError &&
        (voucherResult?.voucherStatus === VOUCHER_INVALID || voucherResult?.voucherStatus === VOUCHER_EXPIRED);

    const showVoucher = () =>
        !flowContext.isDcStandaloneFlow &&
        props.paymentMode !== "cat1234CreditCoversBalance" &&
        props.paymentMode !== "cat56CreditCoversBalance" &&
        props.paymentMode !== "none" &&
        props.model.MethodsViewModel.ShowVoucherPayment &&
        !props.model.BookingViewModel.IsFundingCug &&
        !isPeruCompra &&
        !flowContext.isGiftcardPurchaseFlow;

    const handleGiftcardClick = async (e: MouseEvent, giftcard: ApiVoucherWithAmount) => {
        e.preventDefault();
        e.stopPropagation();

        if (!giftcard.Voucher.VoucherCode || !giftcard.VoucherAmount.FormattedRedeemableAmount) {
            handleInvalidVoucher();
            return;
        }

        setSelectedMethod(undefined);

        const isAvailable = await checkAgainstFarelock(giftcard.Voucher.VoucherCode);

        if (!isAvailable) return;

        const voucherData = await getVoucherData(giftcard.Voucher.VoucherCode);

        if ((voucherData?.unformattedQuotedAmount ?? 0) <= 0) {
            showLoader({ name: LOADER_CLASS_NAMES.Generic, container: root.current?.parentElement });
            window.location.reload();
            return;
        }

        const paymentIntentResult = await props.getVoucherInfo(voucherData);

        if (!paymentIntentResult) return;

        const result = mapToVoucherResult(giftcard);
        setVoucherResult(result);

        setIsWaitingForGiftcardResult(true);
    };

    const handleGiftcardUpdate = () => {
        if (
            Number(voucherResult.voucherUnformattedAmount) < Number(props.model.VoucherAndGiftcardViewModel.BalanceDue)
        ) {
            props.payWithVoucherLessThanBalance(voucherResult);
        } else {
            props.payWithGiftcardThatCoversBalance();
        }
    };

    const handleVoucherClick = async (voucherCode: string) => {
        setShowTypeError(false);

        if (!voucherCode) return;

        setIsButtonDisabled(true);

        await ajaxRequest({ url: ROUTES.DeselectWorldpay });
        await ajaxRequest({ url: ROUTES.WaiveUnpaidInterestFees, body: { commit: "false" } });
        setSelectedMethod(undefined);

        const isAvailable = await checkAgainstFarelock(voucherCode);

        if (!isAvailable) {
            setIsButtonDisabled(false);
            return;
        }

        const voucherData = await getVoucherData(voucherCode);
        setVoucherResult(voucherData);

        const paymentIntentResult = await props.getVoucherInfo(voucherData);

        if (!paymentIntentResult) {
            setIsButtonDisabled(false);
            return;
        }

        setIsWaitingForVoucherResult(true);
    };

    const handleVoucherUpdate = () => {
        if (!voucherResult || voucherResult.voucherStatus !== VOUCHER_AVAILABLE) {
            handleInvalidVoucher();
            setIsButtonDisabled(false);
            setIsWaitingForVoucherResult(false);
            return;
        }

        if (voucherResult.unformattedQuotedAmount === 0) {
            showLoader({ name: LOADER_CLASS_NAMES.Generic, container: root.current?.parentElement });
            window.location.reload();
            return;
        }

        if (
            Number(voucherResult.voucherUnformattedAmount) < Number(props.model.VoucherAndGiftcardViewModel.BalanceDue)
        ) {
            props.payWithVoucherLessThanBalance(voucherResult);
        } else {
            props.payWithVoucherThatCoversBalance();
        }
    };

    useEffect(() => {
        if (isWaitingForGiftcardResult && voucherResult) {
            handleGiftcardUpdate();
            setIsWaitingForGiftcardResult(false);
        }

        if (isWaitingForVoucherResult && voucherResult) {
            handleVoucherUpdate();
            setIsWaitingForVoucherResult(false);
        }
    }, [isWaitingForVoucherResult, isWaitingForGiftcardResult, voucherResult]);

    const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();

            handleVoucherClick(e.target instanceof HTMLInputElement ? e.target.value : "");

            return false;
        }

        return true;
    };

    useEffect(scrollToVoucherMessage, [voucherResult, showTypeError]);

    useEffect(() => {
        if (!props.model || showGiftCardSelector !== undefined) return;
        setShowGiftcardSelector(
            props.model.VoucherAndGiftcardViewModel.UserHasGiftcards &&
                props.model.VoucherAndGiftcardViewModel.ShowGiftCards,
        );
    }, [props.model]);

    const infoTemplate = () =>
        props.model.VoucherAndGiftcardViewModel.ShowVoucherInfo
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="full-width-information">
                              <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                              <span data-test-id="gift-card-info"> ${i18next.t("V2-Payment-GiftCardInfo01")} </span>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const voucherAmountTemplate = () =>
        voucherResult?.voucherStatus === VOUCHER_AVAILABLE
            ? html`
                  <div>
                      ${i18next.t("Payment-VoucherAmount")}
                      ${currency === PERUVIAN_SOL_CODE
                          ? voucherResult.voucherUnformattedAmount
                          : voucherResult.voucherAmount}
                      ${maskCurrenciesForDisplay(currency)}
                  </div>
              `
            : "";

    const voucherUsedTemplate = () =>
        voucherResult?.voucherStatus === VOUCHER_USED
            ? html` <div>${i18next.t("Payment-VoucherAlreadyUsed")}</div> `
            : "";

    const voucherInvalidTemplate = () =>
        showInvalidVoucherMessage() ? html` <div>${i18next.t("VoucherNotValid")}</div> ` : "";

    const voucherDataTemplate = () =>
        !showTypeError && voucherResult
            ? html`
                  ${!props.model.VoucherAndGiftcardViewModel.ShowGiftCards || !showGiftCardSelector
                      ? html`
                            <div class="row">
                                <div class="col-xs-1">
                                    <div class="full-width-information voucher-result">
                                        ${voucherAmountTemplate()} ${voucherUsedTemplate()} ${voucherInvalidTemplate()}
                                    </div>
                                </div>
                            </div>
                        `
                      : ""}
              `
            : "";

    const typeErrorTemplate = () =>
        showTypeError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="full-width-information voucher-result">${i18next.t("V2-InvalidVoucherType")}</div>
                      </div>
                  </div>
              `
            : "";

    const formTemplate = () => html`
        <form id="payment_form_VO">
            <div class="inner-deep-box">
                ${props.model.VoucherAndGiftcardViewModel.ShowGiftCards && showGiftCardSelector
                    ? giftcard.htmlTemplate()
                    : props.model.VoucherAndGiftcardViewModel.ShowGiftCards
                      ? voucherForMembers.htmlTemplate()
                      : voucherForWebAnonymous.htmlTemplate()}
                ${voucherDataTemplate()} ${typeErrorTemplate()}
            </div>
        </form>
    `;

    const successfulLastGiftcardPaymentTemplate = () =>
        props.model.VoucherAndGiftcardViewModel.IsLastPaymentGiftCardVoucher
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="giftcard-last-payment-message">
                              &#10004; ${i18next.t("Gift-LastGiftcardPaymentSuccess")}
                          </div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () =>
        showVoucher()
            ? html`
                  <div id=${props.model.VoucherAndGiftcardViewModel.FormId} ref=${ref(root)}>
                      ${infoTemplate()} ${props.model.VoucherAndGiftcardViewModel.ShowVoucherForm ? formTemplate() : ""}
                      ${successfulLastGiftcardPaymentTemplate()}
                  </div>
                  ${blockPromoCodeModal.htmlTemplate()}
              `
            : "";

    return { htmlTemplate };
};
