import { useEffect as hauntedUseEffect } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useCountdown } from "./useCountdown";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useReduxState } from "../../shared/redux/useReduxState";
import { getAntiForgeryTokenFromHtml } from "../../shared/common";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME } from "../../shared/customHooks/useAjax/useAjax";
import { useState } from "../../shared/haunted/CustomHooks";

export const name = "ac-session-modal";

const MINUTES_TILL_SESSION_END = 30;
const MINUTES_TO_SHOW_MESSAGE = 1;

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "url", "home-url"];

export interface Attributes {
    "anti-forgery-token": string;
    "home-url": string;
    "url": string;
}

export interface Props {
    antiForgeryToken: string;
    homeUrl: string;
    url: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        homeUrl: host.homeUrl,
        url: host.url,
    };

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
        sessionCountdown.resetCountdown();

        const handler = triggers.shared.resetSessionTimer.subscribe(() => {
            setIsOpen(false);
            sessionCountdown.resetCountdown();
        });

        return () => handler.unsubscribe();
    };

    const handleContinueButtonClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(false);
        sessionCountdown.resetCountdown();
        await refreshSession();
    };

    const handleRestartButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(false);
        window.location.href = props.homeUrl;
    };

    const refreshSession = async () => {
        try {
            const baseUrl = props.url;
            const fetchOptions: RequestInit = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: antiForgeryToken,
                },
                credentials: "same-origin",
                body: "{}",
            };
            const fetchParameters = {
                options: fetchOptions,
                url: baseUrl,
            };
            const result = await fetch(fetchParameters.url, fetchParameters.options);
            if (result.status !== 200 && result.status !== 204) {
                location.reload();
            }
        } catch (e) {
            location.reload();
        }
    };

    hauntedUseEffect(init, []);

    const { triggers } = usePubSub();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const sessionCountdown = useCountdown({ minutes: MINUTES_TILL_SESSION_END, seconds: 0, interval: 1000 });
    const [antiForgeryToken, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    hauntedUseEffect(() => {
        if (
            !isOpen &&
            sessionCountdown.remainingMinutes === MINUTES_TO_SHOW_MESSAGE &&
            sessionCountdown.remainingSeconds === 0
        ) {
            setIsOpen(true);
        }
    }, [sessionCountdown.remainingMinutes, sessionCountdown.remainingSeconds]);

    //TEMPLATES

    const sessionWarningMessageTemplate = () =>
        !sessionCountdown.isCountdownFinished ? html` <div>${i18next.t("Session-Warning2")}</div> ` : "";

    const sessionRefreshButtonTemplate = () =>
        !sessionCountdown.isCountdownFinished
            ? html`
                  <a class="rounded-primary-btn" @click=${handleContinueButtonClick}>
                      ${i18next.t("Session-RefreshButton")}
                  </a>
              `
            : "";

    const sessionExpiredMessageTemplate = () =>
        sessionCountdown.isCountdownFinished ? html` <div>${i18next.t("Session-Expired")}</div> ` : "";

    const startOverButtonTemplate = () =>
        sessionCountdown.isCountdownFinished
            ? html`
                  <a class="rounded-primary-btn" @click=${handleRestartButtonClick}>
                      ${i18next.t("Session-StartOverButton")}
                  </a>
              `
            : "";

    const timerDisplayTemplate = () => {
        const remainingMinutes = sessionCountdown.remainingMinutes.toString().padStart(2, "0");
        const remainingSeconds = sessionCountdown.remainingSeconds.toString().padStart(2, "0");
        const remainingTimeString = `(${remainingMinutes}:${remainingSeconds})`;
        return html` <span id="timeoutDisplayMinutes">${remainingTimeString}</span> `;
    };

    return isOpen
        ? html`
              <div class="modal">
                  <div class="modal-content booking-modal-content">
                      <div class="modal-header session-warning">
                          <i class="fas fa-exclamation-circle notification-icon"></i>
                          ${i18next.t("Session-Warning")} ${timerDisplayTemplate()}
                      </div>
                      <div class="modal-body">
                          ${sessionWarningMessageTemplate()} ${sessionExpiredMessageTemplate()}

                          <div class="modal-button-container button-center">
                              ${sessionRefreshButtonTemplate()} ${startOverButtonTemplate()}
                          </div>
                      </div>
                  </div>
              </div>
          `
        : html``;
};
