import classNames from "classnames";
import { TemplateResult, html } from "lit-html";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import PerfectScrollbar from "perfect-scrollbar";
import { ref } from "../../../directives/ref";
import { isMobile } from "../../../shared/common";

export interface Props {
    dataTestId?: string;
    innerRef?: { current: HTMLElement };
    isPadded: boolean;
    isSmall?: boolean;
    isVisible: boolean;
    length: number;
    template: TemplateResult | string;
}

export const useCompactDatePickerList = (props: Props) => {
    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);

    const initScrollbar = () => {
        if (scrollbar) scrollbar.destroy();

        if (!props.innerRef.current) return;

        setScrollbar(
            new PerfectScrollbar(props.innerRef.current, {
                suppressScrollX: true,
                swipeEasing: true,
                wheelPropagation: false,
                wheelSpeed: 1,
            }),
        );
    };

    useEffect(() => {
        if (!isMobile()) initScrollbar();
    }, [props.isVisible]);

    const paddingItemTemplate = () => html`
        <li
            class=${classNames(
                "pointer-events-nonecursor-pointer rounded-md border border-solid border-transparent py-2",
                {
                    "mx-2": !props.isSmall,
                    "mx-1": props.isSmall,
                },
            )}
        >
            &nbsp;
        </li>
    `;

    const paddingTemplate = () => {
        if (!props.isPadded && props.length > 6) return "";

        const paddingLength = props.length <= 6 ? (props.isPadded ? 6 : 5) - props.length : 3;

        return [...Array(paddingLength)].map(paddingItemTemplate);
    };

    const htmlTemplate = () => html`
        <div
            ref=${ref(props.innerRef)}
            class=${classNames({ "overflow-y-scroll": isMobile() }, "relative h-64")}
            data-test-id=${props.dataTestId || ""}
        >
            <ul class="relative">
                ${paddingTemplate()} ${props.template} ${paddingTemplate()}
            </ul>
        </div>
    `;

    return { htmlTemplate };
};
