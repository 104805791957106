import i18next from "i18next";
import { html } from "lit-html";
import { ROUTES } from "../../shared/apiRoutes";
import { getAntiforgerySegment, getParsedProperty, getUrlVars, toBoolean } from "../../shared/common";
import { PERU_COMPRA_PARTIAL_STRING, URL_VARS } from "../../shared/commonConstants";
import { useState } from "../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { ApiPasswordReset } from "./ApiPasswordReset";
import { useFluentValidator } from "../../validator/FluentValidator";
import { Validation } from "../../validator/Validation";
import { useErrorMessage } from "../ui/error-message/useErrorMessage";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { validatePassword } from "../../validator/validation-helper";
import { createFormHtmlElement } from "../../component-helpers/FormHelper";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME } from "../../shared/customHooks/useAjax/useAjax";

export const name = "ac-password-reset";

export const observedAttributes: (keyof Attributes)[] = ["model", "is-rut-valid", "anti-forgery-token"];

export interface Attributes {
    "model": string;
    "is-rut-valid": string;
    "anti-forgery-token": string;
}

export interface Props {
    model: ApiPasswordReset;
    isRutValid: boolean;
    antiForgeryToken: string;
}

interface PasswordResetVM {
    oldPassword: string;
    password: string;
    passwordConfirmation: string;
}

const DEFAULT_VM: PasswordResetVM = {
    oldPassword: "",
    password: "",
    passwordConfirmation: "",
};

type FieldNames = keyof PasswordResetVM;

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        model: getParsedProperty<ApiPasswordReset>(host.model),
        isRutValid: toBoolean(host.isRutValid),
        antiForgeryToken: host.antiForgeryToken,
    };

    const submit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        const isValid = await validator.validate();

        if (isValid) {
            const formToPost = createFormHtmlElement(ROUTES.ResetPasswordView, {
                ...vm,
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
                agentId: props.model.AgentId,
                username: props.model.Username,
            });

            formToPost.submit();
        }
    };

    const isBancoEstado = () => {
        const uriBancoEstadoParameter = getUrlVars()[URL_VARS.PASSWORD_RESET_BANCO_ESTADO];
        return uriBancoEstadoParameter ? toBoolean(uriBancoEstadoParameter[0]) : false;
    };

    const isCug2Member = () => {
        const uriCug2Parameter = getUrlVars()[URL_VARS.PASSWORD_RESET_CUG];
        return uriCug2Parameter ? toBoolean(uriCug2Parameter[0]) : false;
    };

    const showPcraLabels = () =>
        window.location.href.toLowerCase().indexOf(PERU_COMPRA_PARTIAL_STRING.toLowerCase()) > -1;

    const [vm, setVm] = useState<PasswordResetVM>(DEFAULT_VM);
    const [isValidated, setIsValidated] = useState<boolean>(false);

    const validator = useFluentValidator<FieldNames, PasswordResetVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("oldPassword", (vm: PasswordResetVM) => vm.oldPassword).isRequired(),
            Validation.ruleFor("password", (vm: PasswordResetVM) => vm.password)
                .isRequired()
                .fulfils(
                    async (password: string) => validatePassword(password),
                    i18next.t(
                        "La contraseña ingresada debe tener entre 8 y 16 caracteres incluyendo letras números. No debe contener puntos (.) , comas(,) o tildes(~).",
                    ),
                ),
            Validation.ruleFor("passwordConfirmation", (vm: PasswordResetVM) => vm.passwordConfirmation)
                .isRequired()
                .fulfils(
                    async (confirmPassword: string) => confirmPassword === vm.password,
                    i18next.t("El campo de confirmación de contraseña debe coincidir con el campo de contraseña."),
                ),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const headerTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <header>
                    <h2 class="main-title">${i18next.t("ResetPassword-Title")}</h2>
                </header>
            </div>
        </div>
    `;

    const formTemplate = () => html`
        ${getAntiforgerySegment(props.antiForgeryToken)} ${usernameRowTemplate()} ${oldPasswordTemplate()}
        ${newPasswordTemplate()} ${confirmPasswordTemplate()} ${registerButtonTemplate()} ${formErrors.htmlTemplate()}
    `;

    const labelTemplate = () => {
        if (props.isRutValid || isBancoEstado()) {
            return html` ${i18next.t("ResetPassword-RUT")}`;
        }

        if (showPcraLabels()) {
            return html` ${i18next.t("DNI")}`;
        }

        return isCug2Member()
            ? html` ${i18next.t("Email o Nombre de Usuario")}`
            : html` ${i18next.t("ResetPassword-Email")}`;
    };

    const passwordResetInstructionsTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <p>${i18next.t("ResetPassword-Instructions")}</p>
                <div>${i18next.t("Register-PasswordPolicy")}</div>
            </div>
        </div>
    `;

    const usernameRowTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <ac-input
                        .label=${labelTemplate()}
                        .testId=${T.PASSWORD_RESET.USERNAME_INPUT_FIELD}
                        .value=${props.model.Username ?? ""}
                        .isReadonly=${true}
                    ></ac-input>
                </div>
            </div>
        </div>
    `;

    const oldPasswordTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <ac-input
                        .errorMessage=${validator.getMessage("oldPassword")}
                        .isInvalid=${!validator.isValid("oldPassword")}
                        .label=${i18next.t("ResetPassword-OldPasswordLabel")}
                        .testId=${T.PASSWORD_RESET.OLD_PASSWORD_INPUT_FIELD}
                        .type=${"password"}
                        .value=${vm.oldPassword ?? ""}
                        .onInput=${(value: string) => setVm({ ...vm, oldPassword: value })}
                    ></ac-input>
                </div>
            </div>
        </div>
    `;

    const newPasswordTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <ac-input
                        .errorMessage=${validator.getMessage("password")}
                        .isInvalid=${!validator.isValid("password")}
                        .label=${i18next.t("ResetPassword-PasswordLabel")}
                        .testId=${T.PASSWORD_RESET.PASSWORD_INPUT_FIELD}
                        .type=${"password"}
                        .value=${vm.password ?? ""}
                        .onInput=${(value: string) => setVm({ ...vm, password: value })}
                    ></ac-input>
                </div>
            </div>
        </div>
    `;

    const confirmPasswordTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                    <ac-input
                        .errorMessage=${validator.getMessage("passwordConfirmation")}
                        .isInvalid=${!validator.isValid("passwordConfirmation")}
                        .label=${i18next.t("ResetPassword-PasswordConfirmLabel")}
                        .testId=${T.PASSWORD_RESET.PASSWORD_CONFIRMATION_INPUT_FIELD}
                        .type=${"password"}
                        .value=${vm.passwordConfirmation ?? ""}
                        .onInput=${(value: string) => setVm({ ...vm, passwordConfirmation: value })}
                    ></ac-input>
                </div>
            </div>
        </div>
    `;

    const registerButtonTemplate = () => html`
        <div class="register-button-container">
            <button @click=${submit} class="rounded-primary-btn" data-test-id=${T.PASSWORD_RESET.SUBMIT_BUTTON}>
                ${i18next.t("ResetPassword-ButtonLabel")}
            </button>
        </div>
    `;

    return html`
        <div>
            <section class="booking-wrapper no-breadcrumb ts-error-container">
                ${headerTemplate()} ${passwordResetInstructionsTemplate()} ${formTemplate()}
            </section>
        </div>
    `;
};
