import classNames from "classnames";
import { html, TemplateResult } from "lit-html";
import { spaSectionOrderFactory } from "../spa/spaSectionOrderFactory";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { SpaSectionType } from "../spa/spa-container";
import { useMemo } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { ACTION_NAMES, CONTROLLER_NAMES } from "../../shared/commonConstants";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { isMobile } from "../../shared/common";

interface Step {
    emptyIcon?: TemplateResult;
    filledIcon?: TemplateResult;
    forceReloadPage?: boolean;
    isInMobileGroup?: boolean;
    label?: string;
    mobileLabel?: string;
    uri?: string;
}

export const useBreadcrumbs = () => {
    const flowContext = useFlowContext();
    const [userContext] = useReduxState("userContext");

    const { getSpaSectionOrder } = spaSectionOrderFactory();
    const { triggers } = usePubSub();

    const filledTickIcon = html`<i class="js-new js-n-step-tick"></i>`;

    const steps: Map<SpaSectionType | "Flight", Step> = new Map([
        [
            "Flight",
            {
                emptyIcon: html`<i class="js-new js-n-step-plane"></i>`,
                filledIcon: html`<i class="js-new js-n-step-plane-filled"></i>`,
                forceReloadPage: true,
                label: i18next.t("V2-FlightLabel"),
                uri: "V2/Flight",
            },
        ],
        [
            "Baggage",
            {
                emptyIcon: html`<i class="js-new js-n-step-baggage"></i>`,
                filledIcon: html`<i class="js-new js-n-step-baggage-filled"></i>`,
                isInMobileGroup: true,
                label: i18next.t("V2-Baggage"),
                mobileLabel: i18next.t("Extras"),
            },
        ],
        [
            "Seatmap",
            {
                emptyIcon: html`<i class="js-new js-n-step-seat"></i>`,
                filledIcon: html`<i class="js-new js-n-step-seat-filled"></i>`,
                isInMobileGroup: true,
                label: i18next.t("V2-SeatmapLabel"),
                mobileLabel: i18next.t("Extras"),
            },
        ],
        [
            "Extras",
            {
                emptyIcon: html`<i class="js-new js-n-step-star"></i>`,
                filledIcon: html`<i class="js-new js-n-step-star-filled"></i>`,
                isInMobileGroup: true,
                label: i18next.t("Extras"),
                mobileLabel: i18next.t("Extras"),
            },
        ],
        [
            "Passengers",
            {
                emptyIcon: html`<i class="js-new js-n-step-user"></i>`,
                filledIcon: html`<i class="js-new js-n-step-user-filled"></i>`,
                label: i18next.t("Pasajeros"),
            },
        ],
        [
            "Payment",
            {
                emptyIcon: html`<i class="js-new js-n-step-money"></i>`,
                filledIcon: html`<i class="js-new js-n-step-money-filled"></i>`,
                label: i18next.t("Pago"),
            },
        ],
        ["Review", {}],
    ]);

    const stepsInOrder = useMemo((): Step[] => {
        const order = getSpaSectionOrder({
            flowType: flowContext.flowType,
            isStaff: userContext?.isStaff,
        });

        const mappedOrder = order.map((section) => {
            const step = steps.get(section.section.type);
            const uri = step?.uri || section.section.uri;
            return { ...step, uri };
        });

        return flowContext.isBookingFlow ? [steps.get("Flight"), ...mappedOrder] : mappedOrder;
    }, [flowContext.flowType, userContext?.isStaff]);

    const isActive = (step: Step) => step.uri && window.location.href.toLowerCase().includes(step.uri.toLowerCase());

    const isPast = (step: Step) => {
        if (!step.uri) return false;

        const thisIndex = stepsInOrder.findIndex((i) => i.uri === step.uri);
        const activeIndex = stepsInOrder.findIndex((i) => isActive(i));

        return thisIndex < activeIndex;
    };

    const isVisible = () =>
        !flowContext.isDcStandaloneFlow &&
        !flowContext.isGiftcardPurchaseFlow &&
        !flowContext.isFlightlessPurchaseFlow &&
        !flowContext.isCugFundingFlow &&
        flowContext.controller !== CONTROLLER_NAMES.CHECKIN &&
        [
            ACTION_NAMES.FLIGHT,
            ACTION_NAMES.BAGGAGE,
            ACTION_NAMES.SEATMAP,
            ACTION_NAMES.EXTRAS,
            ACTION_NAMES.PASSENGERS,
            ACTION_NAMES.REVIEW,
            ACTION_NAMES.PAYMENT,
        ].includes(flowContext.action);

    const handleClick = (step: Step) => {
        if (!isPast(step)) return;

        if (step.forceReloadPage) {
            window.location.href = `/${step.uri}`;
            return;
        }

        triggers.spa.navigateToSection.publish(step.uri);
    };

    const coverRightsideLineTemplate = (finalStep: boolean) => html`
        <div
            class=${classNames("absolute left-[30px] top-[15px] z-0 h-[2px] w-[1555px]", {
                "bg-[#c1c1c1]": !finalStep,
                "bg-[#f7f7f7]": finalStep,
            })}
        ></div>
    `;

    const finalStepTemplate = () => html`
        <div
            class="hidden-xs relative flex h-[30px] w-[30px] items-center justify-center rounded-full bg-[#f7f7f7] text-[30px] text-[#c1c1c1]"
        >
            ${coverRightsideLineTemplate(true)}
            <i class="js-new js-n-step-tick-empty"></i>
        </div>
    `;

    const labelTemplate = (step: Step, isHighlighted: boolean) => html`
        <div
            class=${classNames("whitespace-nowrap font-body text-[16px] font-bold", {
                "text-[#c1c1c1]": !isHighlighted,
                "text-brand-tertiary": isHighlighted,
            })}
        >
            <span class="hidden-xs">${step.label}</span>
            <span class="sm:hidden">${step.mobileLabel || step.label}</span>
        </div>
    `;

    const stepNumberTemplate = (index: number, isHighlighted: boolean) => html`
        <div
            class=${classNames("hidden-xs whitespace-nowrap font-body text-[12px]", {
                "text-[#c1c1c1]": !isHighlighted,
                "text-brand-tertiary": isHighlighted,
            })}
        >
            ${i18next.t("Paso")} ${index + 1}
        </div>
    `;

    const iconTemplate = (step: Step) => html`
        <div
            class=${classNames({ "cursor-pointer hover:text-brand-secondary": isPast(step) })}
            @click=${() => handleClick(step)}
        >
            ${isActive(step) ? step.filledIcon : isPast(step) ? filledTickIcon : step.emptyIcon}
        </div>
    `;

    const stepTemplate = (step: Step, index: number) => {
        const isHighlighted = isActive(step) || isPast(step);
        const mobileGroupIsVisible = stepsInOrder.some((s) => s.isInMobileGroup && isActive(s));
        const isFirstInMobileGroup = stepsInOrder.findIndex((s) => s.isInMobileGroup) === index;
        const isLastStep = stepsInOrder.filter((s) => s.label).length - 1 === index;

        const tempClassNames = classNames(
            "relative flex h-[30px] w-[30px] items-center justify-center rounded-full bg-[#f7f7f7] text-[30px] ",
            {
                "text-[#c1c1c1]": !isHighlighted,
                "text-brand-tertiary": isHighlighted,
                "hidden-xs":
                    ((!isActive(step) && mobileGroupIsVisible) || (!mobileGroupIsVisible && !isFirstInMobileGroup)) &&
                    step.isInMobileGroup,
            },
        );

        return html`
            <div class=${tempClassNames}>
                ${!isHighlighted ? coverRightsideLineTemplate(isMobile() && isLastStep) : ""} ${iconTemplate(step)}
                <div class="absolute left-0 top-8 flex flex-col text-left sm:left-10 sm:top-6">
                    ${stepNumberTemplate(index, isHighlighted)} ${labelTemplate(step, isHighlighted)}
                </div>
            </div>
        `;
    };

    const stepsTemplate = () => [
        ...stepsInOrder.filter((step) => step.uri && step.label).map(stepTemplate),
        finalStepTemplate(),
    ];

    // DEVNOTE We display a full width active blue line, and the inactive icons will cover their right side lines with
    // inactive grey lines. This way we don't have to calculate the active line width.
    const activeLineTemplate = () => html`
        <div
            class=${classNames(
                "absolute left-4 right-4 top-[25px] z-0 h-[2px] bg-brand-tertiary",
                "md:left-[345px] md:right-[25px]",
                "xl:right-[45px]",
            )}
        ></div>
    `;

    const htmlTemplate = () =>
        isVisible()
            ? html`
                  <div
                      class=${classNames(
                          "relative h-16 overflow-hidden bg-[#f7f7f7] px-[10px] pt-[10px]",
                          "sm:h-20 sm:max-w-[1545px]",
                          "md:pl-[345px] md:pr-[25px]",
                          "xl:pr-[45px]",
                      )}
                  >
                      ${activeLineTemplate()}
                      <div class="relative z-10 flex w-full items-center justify-between">${stepsTemplate()}</div>
                  </div>
              `
            : "";

    return { htmlTemplate };
};
