import { PassengerUpdateEvent } from "./../../spa/passengers/usePassengersPage";
import { ROUTES } from "../../../shared/apiRoutes";
import { html } from "lit-html";
import {
    getAntiforgerySegment,
    getParsedProperty,
    getPaxLabel,
    getRequestBodyFromObject,
    hideLoader,
    showLoader,
} from "../../../shared/common";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useRef, useState } from "haunted";
import i18next from "i18next";
import { ref } from "../../../directives/ref";
import { useEmergencyContact } from "./useEmergencyContact";
import { useSecurity } from "./useSecurity";
import { useEffect, useMemo } from "../../../shared/haunted/CustomHooks";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";
import { ApiAdditionalInfoViewModel } from "../../../component-models/checkin/ApiAdditionalInfoViewModel";
import { useBookingManager } from "../../../managers/useBookingManager";
import { useCheckinTealiumManager } from "../../../managers/Tealium/useCheckinTealiumManager";
import { useBookingDataManager } from "../../../managers/useBookingDataManager";
import { useOxyModalContinue } from "./useOxyModalContinue";
import { useOxyModalFail } from "./useOxyModalFail";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import classNames from "classnames";
import { ApiNewPassenger, ApiNewPassengersModel } from "../../../component-models/passengers/ApiNewPassengersModel";
import { mapToNonInfants, mapToInfants } from "../../../component-mappers/PassengerMapper";
import { PassengerFormFieldNames, PassengersVM } from "../../spa/passengers/usePassengersPage";
import { useBookingContext } from "../../../managers/useBookingContext";
import { passengerFormFactory } from "../../spa/passengers/passengerFormFactory";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { FluentValidatorMethodsPartial, Validation } from "../../../validator/Validation";
import { DEFAULT_DATE_FORMAT, PaxType } from "../../../shared/commonConstants";
import { useAppContext } from "../../../managers/useAppContext";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { useDocumentCollectionHandler } from "../../shared/useDocumentCollectionHandler";
import { ApiPassengersUpdateRequest } from "../../../component-models/passengers/ApiPassengersUpdateRequest";
import { useFlowContext } from "../../../managers/useFlowContext";
import { ApiCheckinViewModel } from "../../../component-models/checkin/ApiCheckinViewModel";
import { mapToApiPassengersUpdateRequest } from "../../../component-mappers/PassengerUpdateMapper";
import { PassengerFormVM } from "../../../component-models/passengers/PassengerFormVM";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { passengerHelpers } from "../../../component-helpers/passengers/passengerHelpers";
import { useFrequentFlyerNumberCollectionHandler } from "../../shared/useFrequentFlyerNumberCollectionHandler";

export const name = "ac-checkin-additional-info-page";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "model"];

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    model: ApiAdditionalInfoViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        model: getParsedProperty<ApiAdditionalInfoViewModel>(host.model),
    };

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { ajaxRequest, ajaxJsonRequest, removeAjaxErrorMessage } = useAjax();
    const { getAssistanceOptionPaxNumber, isJourneyInternationalToColombia, updatePaxArray } = passengerHelpers();

    const bookingManager = useBookingManager();
    const tealiumManager = useCheckinTealiumManager();
    const bookingDataManager = useBookingDataManager();

    const [userContext] = useReduxState("userContext");

    const root = useRef<HTMLFormElement>(null);
    const paxRoot = useRef<HTMLDivElement>(null);

    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [adults, setAdults] = useState<PassengerFormVM[]>(undefined);
    const [children, setChildren] = useState<PassengerFormVM[]>(undefined);
    const [infants, setInfants] = useState<PassengerFormVM[]>(undefined);

    const passengersVm: PassengersVM = useMemo(
        () => ({ passengers: adults ? [...adults, ...children, ...infants] : [] }),
        [adults, children, infants],
    );

    const emergencyContact = useEmergencyContact({
        hasFlightColombianStation: props.model.HasFlightColombianStation,
        isValidated,
    });

    const security = useSecurity({
        externalSecurityInfoUrl: props.model.ExternalSecurityInfoUrl,
        isValidated,
        isColombianFlight: props.model.OriginCountry === "CO" || props.model.DestinationCountry === "CO",
        showArgentinaText: props.model.ShowArgentinaText,
    });

    const oxyModalContinue = useOxyModalContinue({
        onContinue: () => {
            showLoader({ container: root.current });
            postForm();
        },
        onCancel: () => {
            showLoader({ container: root.current });
            window.location.href = ROUTES.BookingReset;
        },
    });

    const oxyModalFail = useOxyModalFail();

    const { getCheckinPassengerFormFields: getCheckinPassengerValidators } = passengerFormFactory();

    const formFields = useMemo(
        () =>
            getCheckinPassengerValidators({
                culture: appContext.Culture,
                departureDate: dayjs(props.model.JourneyVm.Journey.Segments[0].STD, DEFAULT_DATE_FORMAT),
                destinationCountry: bookingContext.destinationCountryCode,
                isFirstBancoEstadoPassengerRut: Boolean(props.model.PaxVm.FirstBancoEstadoPassengerRut),
                isInternationalFlight: bookingContext.originCountryCode !== bookingContext.destinationCountryCode,
                originCountry: bookingContext.originCountryCode,
                passengerIndicesWithAcaa: props.model.PaxVm.PassengerIndicesWithACAA,
                isStaff: userContext?.isStaff,
            }),
        [userContext, appContext, bookingContext, props.model],
    );

    const paxNeedsDefaultCountry = (pax: ApiNewPassenger) => {
        if (pax.Type === "INF") return true;
        if (bookingContext.originCountryCode !== bookingContext.destinationCountryCode) return true;

        return false;
    };

    const defaultCountry = (model: ApiNewPassengersModel, type: PaxType) =>
        formFields.some(
            (field) =>
                field.key === "country" && model.Passengers.some((p) => p.Type === type && paxNeedsDefaultCountry(p)),
        )
            ? appContext.Country
            : undefined;

    const validator = useFluentValidator<PassengerFormFieldNames, PassengersVM>({
        vm: passengersVm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("passengers", (model: PassengersVM) => model.passengers).fulfilsValidations(
                formFields.flatMap((f) => f.validators),
                (vm: PassengerFormVM) => vm.uniqueIndex,
            ),
        ],
    });

    const passengerValidator: FluentValidatorMethodsPartial = {
        getMessage: (field: string) => validator.getMessage(field as PassengerFormFieldNames),
        isFieldValid: (field: string) => validator.isFieldValid(field as PassengerFormFieldNames),
    };

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const documentHandler = useDocumentCollectionHandler();
    const frequentFlyerNumberHandler = useFrequentFlyerNumberCollectionHandler();

    const isPaxCheckingIn = (checkinVm: ApiCheckinViewModel, passengerNumber: number): boolean => {
        return checkinVm.PassengerJourneyBags.some(
            (pjb) => pjb.PassengerNumber === passengerNumber && pjb.JourneyBags?.length > 0,
        );
    };

    const init = async () => {
        const loader = showLoader({});
        const passengersResult = await ajaxJsonRequest<ApiNewPassengersModel>({
            url: ROUTES.ApiRoutes.GetAllPassengers,
            method: "GET",
        });

        documentHandler.init(passengersResult.data.Passengers);
        frequentFlyerNumberHandler.init(passengersResult.data.Passengers);

        setAdults(
            mapToNonInfants({
                checkedInPassengerIndices: [],
                culture: appContext.Culture,
                defaultCountry: defaultCountry(passengersResult.data, "ADT"),
                defaultPhonePrefix: appContext.PhonePrefixes.find((prefix) => prefix.CountryCode === appContext.Country)
                    ?.Code,
                isBancoEstado: bookingContext.isBancoEstadoBooking || userContext?.bancoEstado.category !== 0,
                isBookingFlow: flowContext.isBookingFlow,
                isDc: false,
                isFarelockRoundTwo: false,
                isInternationalFlightToColombia: isJourneyInternationalToColombia(bookingContext),
                passengersModel: passengersResult.data,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                filterType: "ADT",
                userContext,
            }).filter((pax) => isPaxCheckingIn(props.model.CheckinVm, pax.passengerNumber)),
        );
        setChildren(
            mapToNonInfants({
                checkedInPassengerIndices: [],
                culture: appContext.Culture,
                defaultCountry: defaultCountry(passengersResult.data, "CHD"),
                defaultPhonePrefix: appContext.PhonePrefixes.find((prefix) => prefix.CountryCode === appContext.Country)
                    ?.Code,
                isBancoEstado: bookingContext.isBancoEstadoBooking || userContext?.bancoEstado.category !== 0,
                isBookingFlow: flowContext.isBookingFlow,
                isDc: false,
                isFarelockRoundTwo: false,
                isInternationalFlightToColombia: isJourneyInternationalToColombia(bookingContext),
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                passengersModel: passengersResult.data,
                filterType: "CHD",
                userContext,
            }).filter((pax) => isPaxCheckingIn(props.model.CheckinVm, pax.passengerNumber)),
        );
        setInfants(
            mapToInfants({
                checkedInPassengerIndices: [],
                culture: appContext.Culture,
                defaultCountry: defaultCountry(passengersResult.data, "INF"),
                infantsCount: bookingContext.infantsCount,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                passengersModel: passengersResult.data,
                userContext,
                flowContext,
            }).filter((pax) => isPaxCheckingIn(props.model.CheckinVm, pax.attachedPassengerNumber)),
        );

        hideLoader(loader);
    };

    const postForm = async (): Promise<void> => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper, container: root.current });
        removeAjaxErrorMessage({ container: root.current });

        const passengerPostBody: ApiPassengersUpdateRequest = mapToApiPassengersUpdateRequest({
            culture: appContext.Culture,
            defaultCountry: appContext.Country,
            departureStationCode: bookingContext.originStationCode,
            documents: [...documentHandler.travelDocuments, ...frequentFlyerNumberHandler.frequentFlyerNumbers],
            passengers: passengersVm.passengers,
        });

        const passengerUpdateResult = await ajaxJsonRequest({
            isJsonData: true,
            body: passengerPostBody,
            container: root.current,
            loader,
            method: "POST",
            url: ROUTES.ApiRoutes.PostUpdatePassengers,
            forceReturnResultOnError: true,
        });

        if (passengerUpdateResult.statusCode === 403) {
            window.location.href = "/V2Checkin/Passengers";
            return;
        }

        await bookingManager.postEmergencyContact(getRequestBodyFromObject(emergencyContact.vm), root.current, loader);

        const redirect = await ajaxRequest({ url: "/V2Checkin/AdditionalInfo" });

        window.location.href = redirect.redirectionUrl;
    };

    const handleSubmitClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        tealiumManager.logCheckinAdditionalInfoContinue();

        const errors: string[] = [];

        let isValid = await validator.validate();

        if (!isValid) {
            errors.push("[checkin passenger document not valid]");
        }

        const isEmergencyContactValid = await emergencyContact.validate();

        if (!isEmergencyContactValid) {
            isValid = false;
            errors.push("[emergency contact not valid]");
        }

        if (!documentHandler.areAllDocumentNumbersUnique()) {
            isValid = false;
            errors.push("[checkin passenger document not unique]");
        }

        if (!frequentFlyerNumberHandler.areAllFrequentFlyerNumbersUnique()) {
            isValid = false;
            errors.push("[checkin passenger frequent flyer number not unique]");
        }

        if (!security.isTermsCheckboxChecked) {
            isValid = false;
            errors.push("[terms are not accepted]");
        }

        if (isValid) {
            handleOxyOptionCheck();
        } else if (errors.length > 0) {
            tealiumManager.logValidationError(errors);
        }

        if (!isValid) {
            window.setTimeout(() => ScrollHelper.scrollToFirstError(), 100);
        }
    };

    const handleOxyOptionCheck = async () => {
        const oxyOptions = props.model.OxyOptions;

        const hasOxy = (passenger: PassengerFormVM) =>
            oxyOptions.some(
                (o) =>
                    getAssistanceOptionPaxNumber(o) === passenger.passengerNumber && (o.IsSold || o.IsSoldPreviously),
            );

        const noOneHasOxy = passengersVm.passengers.every((p) => !hasOxy(p));
        const allHaveOxy = passengersVm.passengers.every((p) => hasOxy(p));

        if (noOneHasOxy) {
            showLoader({ container: root.current });
            postForm();
            return;
        }

        const loader = showLoader({ name: LOADER_CLASS_NAMES.Generic });

        await bookingManager.postEliminateOxy(loader);

        if (allHaveOxy) {
            oxyModalFail.open();
        } else {
            oxyModalContinue.open();
        }
    };

    const handleAdultChange = (e: PassengerUpdateEvent) =>
        setAdults((adults) => updatePaxArray(adults, e, documentHandler.travelDocuments));

    const handleChildChange = (e: PassengerUpdateEvent) =>
        setChildren((children) => updatePaxArray(children, e, documentHandler.travelDocuments));

    const handleInfantChange = (e: PassengerUpdateEvent) =>
        setInfants((infants) => updatePaxArray(infants, e, documentHandler.travelDocuments));

    useEffect(() => bookingDataManager.fetchAndUpdate(ajaxJsonRequest), []);

    useEffect(() => {
        if (userContext?.userRole) init();
    }, [userContext?.userRole]);

    const buttonsTemplate = () => html`
        <div class="checkin-btn-container">
            <a href=${ROUTES.PassengersCheckin} class="rounded-secondary-btn" data-test-id="checkin-return-button">
                ${i18next.t("V2-ReturnLabel")}
            </a>
            <button
                @click=${handleSubmitClick}
                class="rounded-primary-btn booking"
                data-test-id="checkin-continue-button"
            >
                ${i18next.t("V2-Continue")}
            </button>
        </div>
    `;

    const headerTemplate = () => html`
        <header>
            <span class="js-circle-user js-icon title-icon"></span>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.CHECKIN.ADDITIONAL_INFO_TITLE}>
                    ${i18next.t("V2-Checkin-PassengerDetails")}
                </h2>
                <div class="subtitle" data-test-id=${T.CHECKIN.ADDITIONAL_INFO_SUBTITLE}>
                    ${i18next.t("V2-Checkin-PassengerDetailsInfo")}
                </div>
            </div>
        </header>
    `;

    const passengersTemplate = () => html`
        <div class="inner-box ts-passenger-details">
            <div class="inner-deep-box padded checkin-pax-select ts-error-parent">
                ${passengersVm?.passengers.map(passengerTemplate)}
            </div>
        </div>
    `;

    const passengerTemplate = (passenger: PassengerFormVM) => {
        return html`
            <div class="checkin-pax-container ts-error-parent">
                <div class="row">
                    <div class="col-xs-1 mb-2">
                        <span class="checkin-pax-name"> ${passenger.firstName} ${passenger.lastName} </span>
                        <span class="checkin-pax-type"> - ${getPaxLabel(passenger.type)} </span>
                    </div>
                </div>

                <ac-checkin-passenger-document
                    .arrivalDate=${dayjs(
                        props.model.JourneyVm.Journey.Segments[props.model.JourneyVm.Journey.Segments.length - 1].STA,
                    )}
                    .departureDate=${dayjs(props.model.JourneyVm.Journey.Segments[0].STD)}
                    .formFields=${formFields}
                    .isDocNumberUnique=${documentHandler.isDocumentNumberUnique}
                    .isFrequentFlyerNumberUnique=${frequentFlyerNumberHandler.isFrequentFlyerNumberUnique(
                        passenger.frequentFlyerNumber,
                    )}
                    .model=${props.model}
                    .passenger=${passenger}
                    .rootElem=${paxRoot.current}
                    .validator=${passengerValidator}
                    .handleChange=${passenger.type === "ADT"
                        ? handleAdultChange
                        : passenger.type === "CHD"
                          ? handleChildChange
                          : handleInfantChange}
                    .onDocumentBlur=${documentHandler.updateTravelDocument(passenger)}
                    .onFrequentFlyerBlur=${() => frequentFlyerNumberHandler.updateFrequentFlyerNumber(passenger)}
                ></ac-checkin-passenger-document>
            </div>
        `;
    };

    return html`
        <form ref=${ref(root)} class=${classNames("ac-checkin-additional-info-page", LOADER_CLASS_NAMES.Generic)}>
            ${getAntiforgerySegment(props.antiForgeryToken)}
            <div
                ref=${ref(paxRoot)}
                id="mainContentWrapper"
                class="checkin-passengers"
                data-test-id=${T.CHECKIN.ADDITIONAL_INFO_CONTAINER}
            >
                <section class="booking-wrapper push-down ts-error-container">
                    ${headerTemplate()} ${passengersTemplate()}
                </section>
            </div>
            ${formErrors.htmlTemplate()} ${emergencyContact.htmlTemplate()} ${security.htmlTemplate()}
        </form>

        ${buttonsTemplate()} ${oxyModalContinue.htmlTemplate()} ${oxyModalFail.htmlTemplate()}
    `;
};
